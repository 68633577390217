export class APIcalls {
    public static getApi = {
        Portfolio : 'portfolio',
        Employee : 'employee',
        Gallery : 'gallery'
    }
    public static mailApi = {
        Career : 'mailsendcareer',
        Contact : 'mailsendcontact',
        payment : 'payment'
    }
}