import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Observable } from 'rxjs';
import { SnackComponent } from '../models/SnakBar/snackbar';
import { environment } from './../../environments/environment';
function _window() : any {
  return window;
}
@Injectable({
  providedIn: 'root'
})
export class APIService {
  get nativeWindow() : any {
    return _window();
 }
  BaseURL = environment.BaseUrl

  constructor( private http : HttpClient, private snackBar: MatSnackBar ) { }

  private spinner = new BehaviorSubject<boolean>(false);
  activeSpinner = this.spinner.asObservable();

  private spinnervalue = new BehaviorSubject<number>(0);
  activeSpinnerVal = this.spinnervalue.asObservable();

  private snackmsg = new BehaviorSubject<string>('');
  snackmsgobserv = this.snackmsg.asObservable();

  getData( url : string ): Observable<any> {
    return this.http.get( this.BaseURL + url );
  }
  postData( url : string, Data : object ): Observable<object> {
    return this.http.post( this.BaseURL + url , Data );
  }
  postDataRazor( id : string, Data: any ): Observable<any> {
    return this.http.post( "https://api.razorpay.com/v1/payments"+id+"/capture" , Data );
  }

  changeActiveSpinner( active : boolean ){
    this.spinner.next( active );
  }
  changeSpinnerValue( progress : number ){
    this.spinnervalue.next( progress );
  }
  openSnackBar(message: string, duration : number) {
    this.snackmsg.next( message );
    this.snackBar.openFromComponent( SnackComponent, {
        duration: duration * 1000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
  }
}
