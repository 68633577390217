  <!-- ------------------Page Heading Start------------- -->

  <div class="page_heading">
    <div class="container">
        <!-- <h2>HYBRID WEB DEVELOPMENT</h2> -->
        <h2>{{titles}}</h2>
    </div>
</div>
<app-banner [BannerData]="bannerArray"></app-banner>
<!-- ------------------Page Heading End------------- -->

<!-- ------------------Services web development Start-------------->
<!-- <section class="why-choos-lg pad-tb deep-dark">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="common-heading text-l">
            <h2 class="mb20">BEST <span class="text-second text-bold">Hybrid  Web App Agency</span> Among The Leading Companies in India</h2>
            <p>The potential for substantial growth and success with a well thought out, designed, </p>
            <div class="itm-media-object mt40 tilt-3d">
              <div class="media">
                <div class="img-ab- base" data-tilt="" data-tilt-max="20" data-tilt-speed="1000"><img loading="lazy" src="assets/images/computers.png" alt="icon" class="layer"></div>
                <div class="media-body">
                  <h2>ADVANTAGES :-</h2>
                  <h4>Attractive UX/UI</h4>
                  <p>Our designers create a Hybrid app that is aesthetically appealing and interactive.</p>
                </div>
              </div>
              <div class="media mt40">
                <div class="img-ab- base" data-tilt="" data-tilt-max="20" data-tilt-speed="1000"><img loading="lazy" src="assets/images/worker.png" alt="icon" class="layer"></div>
                <div class="media-body">
                  <h4>Fast Speed</h4>
                  <p>The Hybrid application that we create loads faster and provides seamless functionality. They are efficient without the support of network communication.</p>
                </div>
              </div>
              <div class="media mt40">
                <div class="img-ab- base" data-tilt="" data-tilt-max="20" data-tilt-speed="1000"> <img loading="lazy" src="assets/images/deal.png" alt="icon" class="layer"></div>
                <div class="media-body">
                  <h4>User Experience</h4>
                  <p>Our experts develop a hybrid application with an optimum user experience guarantee.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div data-tilt="" data-tilt-max="5" data-tilt-speed="1000" class="single-image bg-shape-dez wow fadeIn" data-wow-duration="2s" style="visibility: visible; animation-duration: 2s; animation-name: fadeIn; will-change: transform; transform: perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1);"><img loading="lazy" src="assets/images/hybrid-app.png" alt="image" class="img-fluid"></div>
        </div>
      </div>
    </div>
  </section> -->

  <!-- ------------------Services web development End---------------->

 <!-- new code nikhil -->

  <!-- -------------------------our-business Section Start----------------- -->
  <section class="our-business">
    <div class="row  our-business-heading mt-0">
      <div class="col-sm-12">
        <h2>Hybrid App development</h2>
        <span> BEST Hybrid App development Agency Among The Leading Companies in India
          </span>
      </div>
    </div>
    <div class="row content-one mt-5">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="./assets/img/our-business-3.png" alt="our-business-3">
        <div class="s-no">
          <span>01</span>
        </div>
      </div>
      <div class="col-sm-6 googleRanking">
        <div class="float">
          <h3>Proven Hybrid App Development Mastery </h3>
          <p>
            Navigating Innovation and Excellence within our portfolio lies a narrative of triumphs, a testament to our mastery of the complex art of hybrid app development. Each entry is a chapter, each app a symphony of harmonious fusion between native and web technologies. Our expertise shines as we bridge the gap between platforms, cultivating results that resonate with innovation and excellence.</p>
        </div>
      </div>
    </div>
    <div class="row content-one mt-0">
      <div class="col-sm-6 localseoservice" >
        <div class="float-l">
          <h3>Collaborative Innovation</h3>
          <p class="one-p">
            Shaping Your Vision into Hybrid Realities In the realm of hybrid app development, your vision illuminates our path. Collaboration isn't a concept; it's the heartbeat of our approach. We walk hand in hand, your insights converging seamlessly with our technical proficiency. Your vision is our North Star, guiding us as we meticulously craft hybrid apps that not only align with your objectives but embrace them wholeheartedly.</p>
        </div>
      </div>
      <div class="col-sm-6 center-line-l">
        <img loading="lazy" src="./assets/img/our-business-2.png" alt="our-business-2">
        <div class="s-no-l">
          <span>02</span>
        </div>
      </div>
    </div>
    <div class="row content-one mt-0">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="./assets/img/our-business-22.png" alt="our-business-3">
        <div class="s-no">
          <span>03</span>
        </div>
      </div>
      <div class="col-sm-6 googlePenalty">
        <div class="float">
          <h3>Timely Excellence</h3>
          <p>
            Crafted, Tested, Launched - A Symphony of Efficiency Time is an instrument, and we conduct it with precision. In the realm of hybrid app development, time is not just a restriction; it's a masterpiece waiting to be composed. Our development process is produced with punctuality as its anthem. We honor your time as sacred, and our streamlined workflow is the conductor. Our hybrid app development process harmonizes elements with the precision of a seasoned orchestra, producing an app that displays both speed and excellence.
          </p>
        </div>
      </div>
    </div>
  
  
  
  
  
  
  
  
  
  
  
  
    <div class="row content-one mt-0">
      <div class="col-sm-6 localseoservice" >
        <div class="float-l">
          <h3>Continued Growth</h3>
          <p class="one-p"> In the symphony of hybrid app development, the launch is just the first note—a warm-up to the grand composition of sustained growth. The launch is merely an overture; the real work is sustained growth. Our commitment is an ongoing work, not a singular note. Your hybrid app is no static creation; it's a living entity in the ever-evolving digital landscape. We stand as its guardians, offering unwavering support that enables its responsiveness, functionality, and adaptability. As trends evolve and technologies metamorphose, your hybrid app flourishes, ever-prepared to embrace the shifting currents of change.</p>
        </div>
      </div>
      <div class="col-sm-6 center-line-l">
        <img loading="lazy" src="./assets/img/our-business-21.png" alt="our-business-2" class="imgflip" >
        <div class="s-no-l">
          <span>04</span>
        </div>
      </div>
    </div>
  
  


    <!-- <div class="row content-one mt-0">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="./assets/img/our-business-3.png" alt="our-business-3">
        <div class="s-no">
          <span>05</span>
        </div>
      </div>
      <div class="col-sm-6 googlePenalty">
        <div class="float">
          <h3>User-Centric Design</h3>
          <p>We believe that a successful app starts with a user-centric design. Our team of skilled UI/UX designers focuses on creating intuitive, visually appealing, and easy-to-navigate interfaces for your Android app. By incorporating the latest design trends, we ensure that your app provides a seamless and engaging user experience. Our designers work closely with you to capture your brand's essence and create a stunning app design that resonates with your target audience</p>
        </div>
      </div>
    </div> -->
  
    
  </section>







  <!-- -------------------------our-business-mobile Section start----------------- -->
  <section class="our-business-mobile">
    <div class="row  our-business-heading mt-0">
      <div class="col-sm-12">
        <h2>Hybrid App development</h2>
        <span> BEST Hybrid App development Agency Among The Leading Companies in India</span>
      </div>
    </div>
    <div class="row content-one mt-5">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="./assets/img/our-business-3.png" alt="our-business">
        <div class="s-no">
          <span>01</span>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="container float">

          <h3>Proven Hybrid App Development Mastery</h3>
          <p>Navigating Innovation and Excellence within our portfolio lies a narrative of triumphs, a testament to our mastery of the complex art of hybrid app development. Each entry is a chapter, each app a symphony of harmonious fusion between native and web technologies. Our expertise shines as we bridge the gap between platforms, cultivating results that resonate with innovation and excellence.
 
            </p>
        </div>
      </div>
    </div>
    <div class="row content-one mt-0">
      <div class="col-sm-6 center-line-l">
        <img loading="lazy" src="./assets/img/our-business-2.png" alt="our-business-2">
        <div class="s-no-l">
          <span>02</span>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="container float">
          <h3> Collaborative Innovation</h3>
          <p class="one-p">
           
            Shaping Your Vision into Hybrid Realities In the realm of hybrid app development, your vision illuminates our path. Collaboration isn't a concept; it's the heartbeat of our approach. We walk hand in hand, your insights converging seamlessly with our technical proficiency. Your vision is our North Star, guiding us as we meticulously craft hybrid apps that not only align with your objectives but embrace them wholeheartedly.</p>
        </div>
      </div>
    </div>
    <div class="row content-one mt-0">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="./assets/img/our-business-22.png" alt="our-business-3">
        <div class="s-no">
          <span>03</span>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="container float mb-3">
          <h3>Timely Excellence</h3>
          <p>
            Crafted, Tested, Launched - A Symphony of Efficiency Time is an instrument, and we conduct it with precision. In the realm of hybrid app development, time is not just a restriction; it's a masterpiece waiting to be composed. Our development process is produced with punctuality as its anthem. We honor your time as sacred, and our streamlined workflow is the conductor. Our hybrid app development process harmonizes elements with the precision of a seasoned orchestra, producing an app that displays both speed and excellence.


          </p>
        </div>
      </div>
    </div>


    <div class="row content-one mt-0">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="./assets/img/our-business-21.png" alt="our-business-3" class="imgflip" >
        <div class="s-no">
          <span>03</span>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="container float mb-3">
          <h3> Continued Growth</h3>
          <p>In the symphony of hybrid app development, the launch is just the first note—a warm-up to the grand composition of sustained growth. The launch is merely an overture; the real work is sustained growth. Our commitment is an ongoing work, not a singular note. Your hybrid app is no static creation; it's a living entity in the ever-evolving digital landscape. We stand as its guardians, offering unwavering support that enables its responsiveness, functionality, and adaptability. As trends evolve and technologies metamorphose, your hybrid app flourishes, ever-prepared to embrace the shifting currents of change.</p>
        </div>
      </div>
    </div>
  </section>
  <!-- -------------------------our-business-mobile Section end----------------- -->

  <!-- <section class="our-business">
    <div class="row  our-business-heading mt-0">
      <div class="col-sm-12">
        <span> Our Hybrid App Development Process
          </span>
      </div>
    </div>
    <div class="row content-one mt-5">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="./assets/img/our-business-3.png" alt="our-business-3">
        <div class="s-no">
          <span>01</span>
        </div>
      </div>
      <div class="col-sm-6 googleRanking">
        <div class="float">
          <h3>Requirement Analysis</h3>
          <p>Our skilled team collaborates with you to understand your business goals and project requirements thoroughly. This helps us outline a clear roadmap for the development process</p>
        </div>
      </div>
    </div>
    <div class="row content-one mt-0">
      <div class="col-sm-6 localseoservice" >
        <div class="float-l">
          <h3>UI/UX Design</h3>
          <p class="one-p">We prioritize creating an intuitive and visually appealing user interface to enhance user experience and maximize engagement</p>
        </div>
      </div>
      <div class="col-sm-6 center-line-l">
        <img loading="lazy" src="./assets/img/our-business-2.png" alt="our-business-2">
        <div class="s-no-l">
          <span>02</span>
        </div>
      </div>
    </div>
    <div class="row content-one mt-0">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="./assets/img/our-business-3.png" alt="our-business-3">
        <div class="s-no">
          <span>03</span>
        </div>
      </div>
      <div class="col-sm-6 googlePenalty">
        <div class="float">
          <h3>Development</h3>
          <p>Our developers use cutting-edge technologies like React Native, Flutter, or Xamarin to build a robust hybrid app that performs flawlessly across various platforms.
          </p>
        </div>
      </div>
    </div>
  
  
  
  
  
  
  
  
  
  
  
  
    <div class="row content-one mt-0">
      <div class="col-sm-6 localseoservice" >
        <div class="float-l">
          <h3>Testing</h3>
          <p class="one-p"> Rigorous testing is an integral part of our development process. We conduct comprehensive testing to ensure the app's functionality, performance, and security meet the highest standards.</p>
        </div>
      </div>
      <div class="col-sm-6 center-line-l">
        <img loading="lazy" src="./assets/img/our-business-2.png" alt="our-business-2">
        <div class="s-no-l">
          <span>04</span>
        </div>
      </div>
    </div>
  
  
<div class="row content-one mt-0">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="./assets/img/our-business-3.png" alt="our-business-3">
        <div class="s-no">
          <span>05</span>
        </div>
      </div>
      <div class="col-sm-6 googlePenalty">
        <div class="float">
          <h3>Deployment & Support</h3>
          <p>Once the app is ready, we assist you in deploying it to the respective app stores and provide ongoing maintenance and support to keep your app up-to-date and bug-free.</p>
        </div>
      </div>
    </div> -->
  
    
  <!-- </section> --> 




  <!-- advantages -->

  
  <!-- <section class="our-business">
    <div class="row  our-business-heading mt-0">
      <div class="col-sm-12">
        <span> Advantages of Working with Resiliencesoft
          </span>
      </div>
    </div>
    <div class="row content-one mt-5">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="./assets/img/our-business-3.png" alt="our-business-3">
        <div class="s-no">
          <span>01</span>
        </div>
      </div>
      <div class="col-sm-6 googleRanking">
        <div class="float">
          <h3>Expert Team</h3>
          <p>Our team of skilled developers has extensive experience in creating top-notch hybrid apps that align perfectly with your business objectives.</p>
        </div>
      </div>
    </div>
    <div class="row content-one mt-0">
      <div class="col-sm-6 localseoservice" >
        <div class="float-l">
          <h3>Customization</h3>
          <p class="one-p">We tailor each app according to your unique requirements, ensuring that it meets your specific needs and brand guidelines.t</p>
        </div>
      </div>
      <div class="col-sm-6 center-line-l">
        <img loading="lazy" src="./assets/img/our-business-2.png" alt="our-business-2">
        <div class="s-no-l">
          <span>02</span>
        </div>
      </div>
    </div>
    <div class="row content-one mt-0">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="./assets/img/our-business-3.png" alt="our-business-3">
        <div class="s-no">
          <span>03</span>
        </div>
      </div>
      <div class="col-sm-6 googlePenalty">
        <div class="float">
          <h3>Timely Delivery</h3>
          <p>We take pride in our ability to deliver projects on time without compromising on quality.
          </p>
        </div>
      </div>
    </div>
  
  
  
  
  
  
  
  
  
  
  
  
    <div class="row content-one mt-0">
      <div class="col-sm-6 localseoservice" >
        <div class="float-l">
          <h3>Transparent Communication</h3>
          <p class="one-p">Throughout the development process, we maintain clear and open communication to keep you updated about the project's progress.</p>
        </div>
      </div>
      <div class="col-sm-6 center-line-l">
        <img loading="lazy" src="./assets/img/our-business-2.png" alt="our-business-2">
        <div class="s-no-l">
          <span>04</span>
        </div>
      </div>
    </div>
  
  
    <div class="row content-one mt-0">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="./assets/img/our-business-3.png" alt="our-business-3">
        <div class="s-no">
          <span>05</span>
        </div>
      </div>
      <div class="col-sm-6 googlePenalty">
        <div class="float">
          <h3>Scalability</h3>
          <p>Our hybrid apps are designed with scalability in mind, ensuring that they can grow with your business and adapt to changing needs.</p>
        </div>
      </div>
    </div>
  
    
  </section> -->