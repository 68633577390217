import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { capatelize } from 'src/app/SEO_Constant/routes.constant';

@Component({
  selector: 'app-native-app-development',
  templateUrl: './native-app-development.component.html',
  styleUrls: ['./native-app-development.component.css']
})
export class NativeAppDevelopmentComponent implements OnInit {
  titles:any;
  bannerArray:any = {
    "bannerTXT" : [
      {
        title: 'Native app development involves building apps for particular operating systems.',
      },
      {
        title: 'Native application able to process data efficiently and work with no delays.',
      },
      {
        title: 'Native app can easily access device features like camera, location, contacts etc',
      },
      {
        title: 'Native app development offers various advantages to make business ideas profitable.',
      },
    ],
    "bannerImg" : {
      "banner": "assets/img/Native_App.png",
     "backgroundImg": "assets/img/banner-background.jpg"
    }
  }

  constructor( private title : Title, private router:Router ) {
    this.titles = router.url.split('/')[router.url.split('/').length-1].replace(/-/g,' ')
   }

  ngOnInit(): void {
    this.title.setTitle(capatelize(this.titles) +' | Resiliencesoft')
  }

}
