<!-- ------------------Page Heading Start------------- -->

<div class="page_heading">
  <div class="container">
    <!-- <h2>Search Engine Optimization</h2> -->
    <h2>{{titles}}</h2>
  </div>
</div>
<app-banner [BannerData]="bannerArray"></app-banner>

<!-- ------------------Page Heading End------------- -->

<!-- ------------------Services web development Start-------------->
<!-- <section class="why-choos-lg pad-tb deep-dark">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="common-heading text-l">
          <h1 class="mb20">BEST <span class="text-second text-bold">Search Engine Optimization Agency</span> Among The
            Leading Companies in India</h1>
          <p>SEO Services That Grow Traffic and Increase Revenue If you’re looking for serious business growth, SEO, or
            search engine optimization is the strategy for you. Why does it work? Simple! It targets the users that are
            most likely to convert on your website, and takes them there.
            A custom SEO campaign targets on-page and off-page SEO which includes things like keyword research and
            content implementation, to help your most valuable audience find you online.
            Not only that, but our team of SEO experts will analyze the results of your business’s SEO strategy to
            ensure that your campaign is performing at its best.
          </p>
        </div>
      </div>
      <div class="col-lg-6">
        <div data-tilt="" data-tilt-max="5" data-tilt-speed="1000" class="single-image bg-shape-dez wow fadeIn"
          data-wow-duration="2s"
          style="visibility: visible; animation-duration: 2s; animation-name: fadeIn; will-change: transform; transform: perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1);">
          <img loading="lazy" src="assets/images/seo_next.png" alt="image" class="img-fluid"></div>
      </div>
    </div>
  </div>
</section> -->

<!-- ------------------Services web development End---------------->


 <!-- -------------------------Banner Section Start----------------- -->
 <!-- <section class="banner"> -->

<!-- </section> -->
<!-- -------------------------Banner From end----------------- -->

<!-- -------------------------our-business Section Start----------------- -->
<section class="our-business">
  <div class="row  our-business-heading mt-0">
    <div class="col-sm-12">
      <h2>Get Your Business Found Online</h2>
      <span>Top Rated SEO Agency in India</span>
    </div>
  </div>
  <div class="row content-one mt-5">
    <div class="col-sm-6 center-line">
      <img loading="lazy" src="./assets/img/our-business.png" alt="our-business">
      <div class="s-no">
        <span>01</span>
      </div>
    </div>
    <div class="col-sm-6 googleRanking">
      <div class="float">
        <h3>Google Top 10 Rankings</h3>
        <p>Websites that rank on the first page of Google search results are more visible, and get more clicks, hence,
          reap more profits. Our search engine optimization team applies proven and white-hat Google SEO (Search
          Engine Optimization) strategies as well as industry-leading processes to help brands secure top 10 rankings
          for their business keywords. Our SEO consultants put more focus on technical SEO, high-quality content,
          internal links, and E-A-T to boost your search rankings on Google.</p>
      </div>
    </div>
  </div>
  <div class="row content-one mt-0">
    <div class="col-sm-6 localseoservice" >
      <div class="float-l">
        <h3>Local SEO Services</h3>
        <p class="one-p">Our Local SEO services can help you grow your online visibility within your target locations.
          Get more
          traffic and business with our tried and tested local SEO plans. We target "near me" and geo-specific search
          queries to make sure your business ranks high for local search queries. Our local SEO packages bring tons of
          business benefits including a boost in online visibility, more traffic to your brick-and-mortar location and
          the opportunity to enhance your brand’s credibility and trust.</p>
      </div>
    </div>
    <div class="col-sm-6 center-line-l">
      <img loading="lazy" src="./assets/img/our-business-2.png" alt="our-business-2">
      <div class="s-no-l">
        <span>02</span>
      </div>
    </div>
  </div>
  <div class="row content-one mt-0">
    <div class="col-sm-6 center-line">
      <img loading="lazy" src="./assets/img/our-business-3.png" alt="our-business-3">
      <div class="s-no">
        <span>03</span>
      </div>
    </div>
    <div class="col-sm-6 googlePenalty">
      <div class="float">
        <h3>Google Penalty Recovery
          Services</h3>
        <p>Websites that rank on the first page of Google search results are more visible, and get more clicks, hence,
          reap more profits. Our search engine optimization team applies proven and white-hat Google SEO (Search
          Engine Optimization) strategies as well as industry-leading processes to help brands secure top 10 rankings
          for their business keywords. Our SEO consultants put more focus on technical SEO, high-quality content,
          internal links, and E-A-T to boost your search rankings on Google.</p>
      </div>
    </div>
  </div>
</section>
<!-- -------------------------our-business Section end----------------- -->

<!-- -------------------------our-business-mobile Section start----------------- -->
<section class="our-business-mobile">
  <div class="row  our-business-heading mt-0">
    <div class="col-sm-12">
      <h2>Get Your Business Found Online</h2>
      <span>Top Rated SEO Agency in India</span>
    </div>
  </div>
  <div class="row content-one mt-5">
    <div class="col-sm-6 center-line">
      <img loading="lazy" src="./assets/img/our-business.png" alt="our-business">
      <div class="s-no">
        <span>01</span>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="container float">
        <h3>Google Top 10 Rankings</h3>
        <p>Websites that rank on the first page of Google search results are more visible, and get more clicks, hence,
          reap more profits. Our search engine optimization team applies proven and white-hat Google SEO (Search
          Engine Optimization) strategies as well as industry-leading processes to help brands secure top 10 rankings
          for their business keywords. Our SEO consultants put more focus on technical SEO, high-quality content,
          internal links, and E-A-T to boost your search rankings on Google.</p>
      </div>
    </div>
  </div>
  <div class="row content-one mt-0">
    <div class="col-sm-6 center-line-l">
      <img loading="lazy" src="./assets/img/our-business-2.png" alt="our-business-2">
      <div class="s-no-l">
        <span>02</span>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="container float">
        <h3>Local SEO Services</h3>
        <p class="one-p">Our Local SEO services can help you grow your online visibility within your target locations.
          Get more traffic and business with our tried and tested local SEO plans. We target "near me" and geo-specific search
          queries to make sure your business ranks high for local search queries. Our local SEO packages bring tons of
          business benefits including a boost in online visibility, more traffic to your brick-and-mortar location and
          the opportunity to enhance your brand’s credibility and trust.</p>
      </div>
    </div>
  </div>
  <div class="row content-one mt-0">
    <div class="col-sm-6 center-line">
      <img loading="lazy" src="./assets/img/our-business-3.png" alt="our-business-3">
      <div class="s-no">
        <span>03</span>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="container float mb-3">
        <h3>Google Penalty Recovery
          Services</h3>
        <p>Websites that rank on the first page of Google search results are more visible, and get more clicks, hence,
          reap more profits. Our search engine optimization team applies proven and white-hat Google SEO (Search
          Engine Optimization) strategies as well as industry-leading processes to help brands secure top 10 rankings
          for their business keywords. Our SEO consultants put more focus on technical SEO, high-quality content,
          internal links, and E-A-T to boost your search rankings on Google.</p>
      </div>
    </div>
  </div>
</section>
<!-- -------------------------our-business-mobile Section end----------------- -->

<!-- -------------------------our-Process Section Start----------------- -->
<section class="our-Process">
  <div class="row">
    <div class="col-12 process">
      <!-- <div class="container"> -->
        <h2>Our-Process</h2>
        <span>Top SEO Company in India</span>
        <p>Google uses over 200 ranking signals to determine the top websites for top 10 positions on SERPs. To be on
          the list, you must have a perfectly optimized website . Brainpulse is the top SEO agency in India that works
          with proven SEO strategy followed by best consultants, advanced SEO tools, and unmatched search engine
          optimization expertise spanning over two decades. We put our best efforts to achieve your business’ SEO
          service objectives. We rely on our best-in class-SEO processes that are 100% white hat, organic and designed
          to bring your website on top of Google organic search rankings.</p>
      <!-- </div> -->
    </div>
  </div>
  <div class="row mt-5 mb-5 ">
    <div class="col-sm-4">
      <!-- <div class="Audit-icon">
        <img loading="lazy" src="./assets/img/backlinks.png" alt="">
      </div> -->
      <div class="Audit">

        <h3>Website Audit</h3>
        <p>Our detailed website analysis is done by top-notch SEO agency consultants. They find the weaknesses &
          strengths of your website. This audit lists website elements that need improvement. </p>
      </div>
      <div class="Analysis">
        <!-- <div class="Analysis-icon">
          <img loading="lazy" src="./assets/img/search.png" alt="">
        </div> -->
        <h3>Competitor Analysis</h3>
        <p>Competitive analysis by an SEO Services firm is an essential element of our SEO Services plan. It’s an
          assessment to outline potential competitors’ strengths as well as weaknesses. </p>
      </div>
      <div class="Backlink mb-5">
        <!-- <div class="Backlink-icon">
          <img loading="lazy" src="./assets/img/diagram.png" alt="">
        </div> -->
        <h3>Backlink Audit</h3>
        <p>Backlinks are the most important ranking factors. Thus, it’s essential to find out good and spammy
          backlinks. Our backlink audit does it well. </p>
      </div>
    </div>
    <div class="col-sm-4">
      <!-- <div class="our-logo"> -->
        <img loading="lazy" src="./assets/img/seo-footer.png" alt="seo-footer" class="logo" >
        <!-- <img loading="lazy" src="./assets/img/RS-name.png" alt="" class="name"> -->
      <!-- </div> -->
      <!-- <div class="our-icon">
      </div> -->


    </div>
    <div class="col-sm-4">
      <div class="Marketing">
        <!-- <div class="Marketing-icon">
          <img loading="lazy" src="./assets/img/content.png" alt="">
        </div> -->
        <h3>Content Marketing</h3>
        <p>To promote your business across digital mediums, our SEO content marketing team writes informative content
          and promotes it. It helps us to engage the interested audience and convert them into buyers.</p>
      </div>
      <div class="Optimization">
        <!-- <div class="Optimization-icon">
          <img loading="lazy" src="./assets/img/competitor.png" alt="">
        </div> -->
        <h3>Off Page & On Page Optimization</h3>
        <p>Off-page and on-page optimization are integral to an SEO campaign. We are a reputed SEO services provider
          that takes care of both. </p>
      </div>
      <div class="Reporting ">
        <!-- <div class="Reporting-icon">
          <img loading="lazy" src="./assets/img/coding.png" alt="">
        </div> -->
        <h3>Performance Reporting</h3>
        <p>Upon completion of the campaign, our SEO agency consultants take stock of its performance. Based on various
          reports generated, they measure the metrics to determine if the set goals are achieved. </p>
      </div>
    </div>
  </div>

</section>
<!-- -------------------------our-Process Section end----------------- -->


<!-- -------------------------our-Process-mobile Section start----------------- -->
<section class="our-Process-mobile">
  <div class="container">
    <div class="row">
      <div class="col-12 process">
        <!-- <div class="container"> -->
          <h2>Our-Process</h2>
          <span>Top SEO Company in India</span>
          <p>Google uses over 200 ranking signals to determine the top websites for top 10 positions on SERPs. To be
            on
            the list, you must have a perfectly optimized website. Brainpulse is the top SEO agency in India that
            works
            with proven SEO strategy followed by best consultants, advanced SEO tools, and unmatched search engine
            optimization expertise spanning over two decades. We put our best efforts to achieve your business’ SEO
            service objectives. We rely on our best-in class-SEO processes that are 100% white hat, organic and
            designed
            to bring your website on top of Google organic search rankings.</p>
        <!-- </div> -->
      </div>
    </div>

    <div class="row mt-5 mb-5 ">
      <div class="col-sm-12">

        <div class="Audit-mobile">
          <div class="row">
            <div class="col-2 ">
              <div class="Audit-img">
                <img loading="lazy" src="./assets/img/backlinks.png" alt="backlinks">
              </div>
            </div>
            <div class="col-10">
              <h3>Website Audit</h3>
              <p>Our detailed website analysis is done by top-notch SEO agency consultants. They find the weaknesses &
                strengths of your website. This audit lists website elements that need improvement. </p>
            </div>
          </div>
        </div>
        <div class="Audit-mobile">
          <div class="row">
            <div class="col-2 ">
              <div class="Audit-img">
                <img loading="lazy" src="./assets/img/diagram.png" alt="diagram">
              </div>
            </div>
            <div class="col-10">
              <h3>Competitor Analysis</h3>
              <p>Competitive analysis by an SEO Services firm is an essential element of our SEO Services plan. It’s
                an
                assessment to outline potential competitors’ strengths as well as weaknesses. </p>
            </div>
          </div>
        </div>
        <div class="Audit-mobile">
          <div class="row">
            <div class="col-2 ">
              <div class="Audit-img">
                <img loading="lazy" src="./assets/img/search.png" alt="search">
              </div>
            </div>
            <div class="col-10">
              <h3>Backlink Audit</h3>
              <p>Backlinks are the most important ranking factors. Thus, it’s essential to find out good and spammy
                backlinks. Our backlink audit does it well. </p>
            </div>
          </div>
        </div>
        <div class="Audit-mobile">
          <div class="row">
            <div class="col-2 ">
              <div class="Audit-img">
                <img loading="lazy" src="./assets/img/content.png" alt="content">
              </div>
            </div>
            <div class="col-10">
              <h3>Content Marketing</h3>
              <p>To promote your business across digital mediums, our SEO content marketing team writes informative
                content
                and promotes it. It helps us to engage the interested audience and convert them into buyers.</p>
            </div>
          </div>
        </div>
        <div class="Audit-mobile">
          <div class="row">
            <div class="col-2 ">
              <div class="Audit-img">
                <img loading="lazy" src="./assets/img/coding.png" alt="coading">
              </div>
            </div>
            <div class="col-10">
              <h3>Off Page & On Page Optimization</h3>
              <p>Off-page and on-page optimization are integral to an SEO campaign. We are a reputed SEO services
                provider
                that takes care of both. </p>
            </div>
          </div>
        </div>
        <div class="Audit-mobile">
          <div class="row">
            <div class="col-2 ">
              <div class="Audit-img">
                <img loading="lazy" src="./assets/img/competitor.png" alt="competitor">
              </div>
            </div>
            <div class="col-10">
              <h3>Performance Reporting</h3>
              <p>Upon completion of the campaign, our SEO agency consultants take stock of its performance. Based on
                various
                reports generated, they measure the metrics to determine if the set goals are achieved. </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- -------------------------our-Process-mobile Section end----------------- -->
