import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { capatelize } from 'src/app/SEO_Constant/routes.constant';

@Component({
  selector: 'app-software-development',
  templateUrl: './software-development.component.html',
  styleUrls: ['./software-development.component.css']
})
export class SoftwareDevelopmentComponent implements OnInit {
  titles:any
  bannerArray:any = {
    "bannerTXT" : [
      {
        title: 'Web development services help create all types of web-based software.',
      },
      {
        title: 'Web development is the building and maintenance of websites.',
      },
      {
        title: 'It`s make website look great.',
      },
      {
        title: 'Work fast and perform well with a seamless user experience.',
      },
    ],
    "bannerImg" : {
      "banner": "assets/img/web_dev.png",
      "backgroundImg": "assets/img/banner-background.jpg"
    }
  }

  constructor( private title : Title,private router: Router ) {
    this.titles = router.url.split('/')[router.url.split('/').length-1].replace(/-/g,' ')
   }

  ngOnInit(): void {
    // this.title.setTitle('Web Development | Resiliencesoft')
    this.title.setTitle(capatelize(this.titles) + ' | Resiliencesoft')
  }

}
