import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { capatelize } from 'src/app/SEO_Constant/routes.constant';
import { description, keyword } from 'src/app/SEO_Constant/seo';

@Component({
  selector: 'app-android-app-development',
  templateUrl: './android-app-development.component.html',
  styleUrls: ['./android-app-development.component.css'],
})
export class AndroidAppDevelopmentComponent implements OnInit {
  titles:any;
  bannerArray:any = {
    "bannerTXT" : [
      {
        title: 'Android app development catch the mind and heart of the customers to build brand loyalty.',
      },
      {
        title: 'Android App Development offer valuable marketing opportunities and unlock market reach.',
      },
      {
        title: ' Android app development help you by using cutting-edge tools and technological advancements',
      },
      {
        title: ' Android App development offer business-friendly features for boost in market penetration. ',
      },
    ],
    "bannerImg" : {
      "banner": "assets/img/android_dev.png",
      "backgroundImg": "assets/img/banner-background.jpg"
    }
  }
  constructor(private title: Title, private meta: Meta, private router : Router) {
    this.titles = router.url.split('/')[router.url.split('/').length-1].replace(/-/g,' ')
  }

  ngOnInit(): void {
    this.title.setTitle(
      capatelize(this.titles)+" | Resiliencesoft"
    );
    this.meta.removeTag('name=description');
    this.meta.removeTag('name=keywords');
    this.meta.addTags([
      {
        name: 'description',
        content: description.AndroidAppDevelopment,
      },
      {
        name: 'keywords',
        content: capatelize(keyword.globle) + capatelize(keyword.AndroidAppDevelopment),
      },
    ]);
  }
}
