import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { capatelize } from 'src/app/SEO_Constant/routes.constant';

@Component({
  selector: 'app-hybrid-app-development',
  templateUrl: './hybrid-app-development.component.html',
  styleUrls: ['./hybrid-app-development.component.css']
})
export class HybridAppDevelopmentComponent implements OnInit {
  titles:any;
  bannerArray:any = {
    "bannerTXT" : [
      {
        title: 'Hybrid app Development is an economical and effective solution for businesses.',
      },
      {
        title: 'Hybrid apps are a blend of both native and web solutions.',
      },
      {
        title: 'Hybrid app run on multiple operating systems like Windows, Android and iOS.',
      },
      {
        title: 'Hybrid application development is cost effective and faster.',
      },
    ],
    "bannerImg" : {
      "banner": "assets/img/Hybrid_ App.png",
     "backgroundImg": "assets/img/banner-background.jpg"
    }
  }

  constructor(  private title : Title , private router:Router) {
    this.titles = router.url.split('/')[router.url.split('/').length-1].replace(/-/g,' ')
   }

  ngOnInit(): void {
    this.title.setTitle(capatelize(this.titles) +' | Resiliencesoft')
  }

}
