  <!-- ------------------Page Heading Start------------- -->

  <div class="page_heading">
    <div class="container">
      <!-- <h2>Website Devlopment</h2> -->
      <h2>{{titles}}</h2>
    </div>
  </div>
  <app-banner [BannerData]="bannerArray"></app-banner>

  <!-- ------------------Page Heading End------------- -->

  <!-- ------------------Services web development Start-------------->
  <!-- <section class="why-choos-lg pad-tb deep-dark">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="common-heading text-l">
            <h2 class="mb20">BEST <span class="text-second text-bold">Web Development Agency</span> Among The Leading Companies in India</h2>
            <p>Our website development services have led to a results driven process that ensures successful outcomes. Our development process starts with the analysis, research and planning followed by strong SEO driven content and collaborative design.</p>
            <div class="itm-media-object mt40 tilt-3d">
              <div class="media">
                <div class="img-ab- base" data-tilt="" data-tilt-max="20" data-tilt-speed="1000"><img loading="lazy" src="assets/images/computers.png" alt="icon" class="layer"></div>
                <div class="media-body">
                  <h4>Quality Web Content</h4>
                  <p>Content should include important information and come in the forms that are pertinent to the website.</p>
                </div>
              </div>
              <div class="media mt40">
                <div class="img-ab- base" data-tilt="" data-tilt-max="20" data-tilt-speed="1000"><img loading="lazy" src="assets/images/worker.png" alt="icon" class="layer"></div>
                <div class="media-body">
                  <h4>Sensible Web design</h4>
                  <p>Balanced distribution of content and graphics is required and the use of slightly contrasting colours and clear fonts is key.</p>
                </div>
              </div>
              <div class="media mt40">
                <div class="img-ab- base" data-tilt="" data-tilt-max="20" data-tilt-speed="1000"> <img loading="lazy" src="assets/images/deal.png" alt="icon" class="layer"></div>
                <div class="media-body">
                  <h4>CMS Websites</h4>
                  <p>Content management system complements a website by providing website owners with the tools to manage their website’s content themselves.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div data-tilt="" data-tilt-max="5" data-tilt-speed="1000" class="single-image bg-shape-dez wow fadeIn" data-wow-duration="2s" style="visibility: visible; animation-duration: 2s; animation-name: fadeIn; will-change: transform; transform: perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1);"><img loading="lazy" src="assets/images/web-development_new.png" alt="image" class="img-fluid"></div>


        </div>
      </div>
    </div>
  </section> -->

  <!-- ------------------Services web development End---------------->


  <!-- copy -->

  <section class="our-business">
    <div class="row  our-business-heading mt-0">
      <div class="col-sm-12 pb-3">
        <h2> Web Development </h2>
        <span> Best Web Development Agency Among The Leading Companies in India</span>
      </div>
    </div>
    <div class="row content-one mt-5">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="./assets/img/our-business-22.png" alt="our-business-3">
        <div class="s-no">
          <span>01</span>
        </div>
      </div>
      <div class="col-sm-6 googleRanking">
        <div class="float">
          <h3>Elevating the Artistry of Web Development Excellence</h3>
          <p>Our team of exceptionally talented web developers doesn't just code; they meticulously craft digital masterpieces that go beyond the ordinary. With artistic expertise that defines our dedication to web development, we bring your visionary concepts to life, infusing them with innovative designs, automatic navigation, and immersive user experiences that resonate profoundly with your target audience </p>
        </div>
      </div>
    </div>
    <div class="row content-one mt-0">
      <div class="col-sm-6 localseoservice">
        <div class="float-l">
          <h3>Crafting Bespoke Web Development Solutions for Your Unique Vision</h3>
          <p class="one-p">Recognizing that each business possesses distinct needs, we specialize in tailor-made web development solutions that harmonize seamlessly with your specific objectives. Whether you're imagining an e-commerce enormous, a corporate link, an artistic portfolio, or a complex web application, our expertise in web development adapts to bring your dreams into digital reality.</p>
        </div>
      </div>
      <div class="col-sm-6 center-line-l">
        <img loading="lazy" src="./assets/img/our-business-21.png" alt="our-business-2" class="imgflip">
        <div class="s-no-l">
          <span>02</span>
        </div>
      </div>
    </div>
    <div class="row content-one mt-0">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="./assets/img/our-business-3.png" alt="our-business-3" >
        <div class="s-no">
          <span>03</span>
        </div>
      </div>
      <div class="col-sm-6 googlePenalty">
        <div class="float">
          <h3>Innovative Cutting-Edge Web Development Technologies with Foresight
          </h3>
          <p>The digital landscape evolves at a breakneck pace, and we thrive on its dynamism. With an inflexible commitment to innovation in web development, we stay consistently ahead of the curve in web development technologies and trends. This ensures that your digital footprint is engraved using the latest tools and techniques, continuously positioning you at the forefront of web development Proficiency.
          </p>
        </div>
      </div>
    </div>
    <div class="row content-one mt-0">
      <div class="col-sm-6 localseoservice">
        <div class="float-l">
        <h3>Seamless Adaptability in the Mobile-First Web Development Model</h3>
            <p class="one-p">In a world centered around mobile engagement, the demand for responsive design is categorical. Our websites are meticulously engineered in the realm of web development to adapt across diverse devices and screen dimensions fluidly. The outcome? A flawless viewing experience, regardless of the user's chosen platform, perfectly aligns with modern web development standards.</p>
          
        </div>
      </div>
      <div class="col-sm-6 center-line-l">
        <img loading="lazy" src="./assets/img/our-business-2.png" alt="our-business-2">
        <div class="s-no-l">
          <span>04</span>
        </div>
      </div>
    </div>

  </section>






  <!-- -------------------------our-business-mobile Section start----------------- -->
  <section class="our-business-mobile">
    <div class="row  our-business-heading mt-0">
      <div class="col-sm-12">
        <h2> Web Development</h2>
        <span>
          Best Web Development Agency Among The Leading Companies in India</span>
      </div>
    </div>
    <div class="row content-one mt-5">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="./assets/img/our-business-22.png" alt="our-business">
        <div class="s-no">
          <span>01</span>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="container float">

          <h3>Elevating the Artistry of Web Development Excellence</h3>

          <p>Our team of exceptionally talented web developers doesn't just code; they meticulously craft digital masterpieces that go beyond the ordinary. With artistic expertise that defines our dedication to web development, we bring your visionary concepts to life, infusing them with innovative designs, automatic navigation, and immersive user experiences that resonate profoundly with your target audience </p>

        </div>
      </div>
    </div>
    <div class="row content-one mt-0">
      <div class="col-sm-6 center-line-l">
        <img loading="lazy" src="./assets/img/our-business-21.png" alt="our-business-2" class="imgflip">
        <div class="s-no-l">
          <span>02</span>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="container float">
             <h3>Crafting Bespoke Web Development Solutions for Your Unique Vision</h3>
          <p class="one-p">Recognizing that each business possesses distinct needs, we specialize in tailor-made web development solutions that harmonize seamlessly with your specific objectives. Whether you're imagining an e-commerce enormous, a corporate link, an artistic portfolio, or a complex web application, our expertise in web development adapts to bring your dreams into digital reality.</p>
        </div>
      </div>
    </div>
    <div class="row content-one mt-0">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="./assets/img/our-business-3.png" alt="our-business-3">
        <div class="s-no">
          <span>03</span>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="container float mb-3">
         <h3>Innovative Cutting-Edge Web Development Technologies with Foresight
          </h3>
          <p>The digital landscape evolves at a breakneck pace, and we thrive on its dynamism. With an inflexible commitment to innovation in web development, we stay consistently ahead of the curve in web development technologies and trends. This ensures that your digital footprint is engraved using the latest tools and techniques, continuously positioning you at the forefront of web development Proficiency.
          </p>
        </div>
      </div>
    </div>


    <div class="row content-one mt-0">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="./assets/img/our-business-2.png" alt="our-business-3">
        <div class="s-no">
          <span>03</span>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="container float mb-3">
          <h3>Seamless Adaptability in the Mobile-First Web Development Model</h3>
          <p>In a world centered around mobile engagement, the demand for responsive design is categorical. Our websites are meticulously engineered in the realm of web development to adapt across diverse devices and screen dimensions fluidly. The outcome? A flawless viewing experience, regardless of the user's chosen platform, perfectly aligns with modern web development standards.</p>
        </div>
      </div>
    </div>
  </section>
  <!-- -------------------------our-business-mobile Section end----------------- -->