import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { capatelize } from 'src/app/SEO_Constant/routes.constant';

@Component({
  selector: 'app-social-media-promotion',
  templateUrl: './social-media-promotion.component.html',
  styleUrls: ['./social-media-promotion.component.css']
})
export class SocialMediaPromotionComponent implements OnInit {
  titles:any;
  bannerArray:any = {
    "bannerTXT" : [
      {
        title: 'Social media marketing drive website traffic, build awareness & generate leads.',
      },
      {
        title: 'Social media marketing is incredibly valuable and beneficial for business growth.',
      },
      {
        title: 'Social media marketing use social platforms to achieve marketing and branding goals.',
      },
      {
        title: 'Social media marketing use of social media platforms to promote a product or service. ',
      },
    ],
    "bannerImg" : {
      "banner": "assets/img/Social_Media.jpg",
      "backgroundImg": "assets/img/banner-background.jpg"
    }
  }

  constructor( private title : Title , private router:Router) {
    this.titles = router.url.split('/')[router.url.split('/').length-1].replace(/-/g,' ')
   }

  ngOnInit(): void {
    this.title.setTitle(capatelize(this.titles) +' | Resiliencesoft')
  }

}
