import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { capatelize } from 'src/app/SEO_Constant/routes.constant';

@Component({
  selector: 'app-bulk-sms-and-email-marketing',
  templateUrl: './bulk-sms-and-email-marketing.component.html',
  styleUrls: ['./bulk-sms-and-email-marketing.component.css']
})
export class BulkSmsAndEmailMarketingComponent implements OnInit {
  titles:any;
  bannerArray:any = {
    "bannerTXT" : [
      {
        title: 'Email marketing is utilized to inform, build a community and drive sales.',
      },
      {
        title: 'Bulk sms services, you can connect with your target audience effortlessly',
      },
      {
        title: 'Email marketing send a commercial message to a group of people, using email.',
      },
      {
        title: 'Bulk SMS services potentially enhance and generate higher conversion rates.',
      },
    ],
    "bannerImg" : {
      "banner": "assets/img/Bulk_Sms.png",
      "backgroundImg": "assets/img/banner-background.jpg"
    }
  }

  constructor( private title : Title , private router:Router) {
    this.titles = router.url.split('/')[router.url.split('/').length-1].replace(/-/g,' ')
   }

  ngOnInit(): void {
      this.title.setTitle(capatelize(this.titles) +' | Resiliencesoft')
  }

}
