  <!-- ------------------Page Heading Start------------- -->

  <div class="page_heading">
    <div class="container">
      <!-- <h2>Digital Media Maketing</h2> -->
      <h2>{{titles}}</h2>
    </div>
  </div>
  <app-banner [BannerData]="bannerArray"></app-banner>

  <!-- ------------------Page Heading End------------- -->

  <!-- ------------------Services web development Start-------------->
  <!-- <section class="why-choos-lg pad-tb deep-dark">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="common-heading text-l">
            <h2 class="mb20">BEST <span class="text-second text-bold">Digital Media Maketing Agency</span> Among The Leading Companies in India</h2>
            <p>We are here to make brands grow and thrive in today’s competitive and changing markets. We are here to ride the big Digital wave; offering our clients value-for-money ideas, where each spend is justified and fruitful. Led by top-notch professionals who have shone in the fields of Advertising, Creativity, and Digital Marketing, we are a strong team of experts who can synergize their talents and experience to create powerful campaigns that work </p>
          </div>
        </div>
        <div class="col-lg-6">
          <div data-tilt="" data-tilt-max="5" data-tilt-speed="1000" class="single-image bg-shape-dez wow fadeIn" data-wow-duration="2s" style="visibility: visible; animation-duration: 2s; animation-name: fadeIn; will-change: transform; transform: perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1);"><img loading="lazy" src="assets/images/digital-margketing_new.png" alt="image" class="img-fluid"></div>


        </div>
      </div>
    </div>
  </section> -->

  <!-- ------------------Services web development End---------------->


  <!-- new code -->


  <section class="our-business">
    <div class="row  our-business-heading mt-0">
      <div class="col-sm-12 pb-3">
        <h2> Digital Media Marketing</h2>
        <span>Why Digital Media Marketing Matters for Your Software Company </span>
      </div>
    </div>
    <div class="row content-one mt-5">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="./assets/img/our-business-22.png" alt="our-business-3">
        <div class="s-no">
          <span>01</span>
        </div>
      </div>
      <div class="col-sm-6 googleRanking">
        <div class="float">
          <h3>Strategic Mastery </h3>
          <p>
            At ResilienceSoft, we don't just practise marketing; we're digital strategists committed to constant innovation in digital media marketing. Our adept team of digital media experts is perpetually ahead of the curve in digital media marketing. We live and breathe digital, crafting and implementing data-driven strategies that consistently transcend the ordinary in digital media marketing. Our focus? Ensuring every digital campaign serves your growth objectives through digital media marketing.



            
            
            


            .</p>
        </div>
      </div>
    </div>
    <div class="row content-one mt-0">
      <div class="col-sm-6 localseoservice">
        <div class="float-l">
          <h3>Tailored Excellence</h3>
          <p class="one-p">
            In the ever-evolving world of digital media marketing, one size does not fit all. We recognize your brand's distinctiveness and thrive on customising solutions tailored precisely to your requirements in digital media marketing. From holistic digital marketing campaigns designed to amplify your online presence to finely-targeted initiatives in digital media marketing, we are artisans in crafting strategies that harmonise with your brand's unique identity in digital media marketing.

          </p>
        </div>
      </div>
      <div class="col-sm-6 center-line-l">
        <img loading="lazy" src="./assets/img/our-business-21.png" alt="our-business-2" class="imgflip" >
        <div class="s-no-l">
          <span>02</span>
        </div>
      </div>
    </div>
    <div class="row content-one mt-0">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="./assets/img/our-business-3.png" alt="our-business-3">
        <div class="s-no">
          <span>03</span>
        </div>
      </div>
      <div class="col-sm-6 googlePenalty">
        <div class="float">
          <h3>Comprehensive Brilliance
          </h3>
          <p>Our approach to digital media marketing doesn't stop at mere brushstrokes; we create the whole canvas in digital media marketing. ResilienceSoft offers an all-encompassing suite of digital marketing services in digital media marketing that work together seamlessly to amplify your brand's resonance. From the finesse of SEO and the artistry of social media mastery to the narrative brilliance of compelling content creation and the precision of paid advertising in digital media marketing, we provide a symphony of services that harmoniously contribute to your digital success.</p>
        </div>
      </div>
    </div>
    <div class="row content-one mt-0">
      <div class="col-sm-6 localseoservice">
        <div class="float-l">
          <h3>Innovation Through Insight</h3>
          <p class="one-p">Data is our North Star in digital media marketing. We base our decisions on comprehensive analysis and real-time insights in digital media marketing. Our unwavering commitment to data in digital media marketing isn't just about measuring success; it's about sculpting strategies continuously for evergreen relevance in it. This ensures your brand remains at the forefront of engagement, consistently capturing the hearts and minds of your audience through digital media marketing.</p>
            
        </div>
      </div>
      <div class="col-sm-6 center-line-l">
        <img loading="lazy" src="./assets/img/our-business-2.png" alt="our-business-2">
        <div class="s-no-l">
          <span>04</span>
        </div>
      </div>
    </div>

  </section>



  <!-- -------------------------our-business-mobile Section start----------------- -->
  <section class="our-business-mobile">
    <div class="row  our-business-heading mt-0">
      <div class="col-sm-12">
        <h2>Digital Media Marketing</h2>
        <span>
          Why Digital Media Marketing Matters for Your Software Company</span>
      </div>
    </div>
    <div class="row content-one mt-5">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="./assets/img/our-business-22.png" alt="our-business">
        <div class="s-no">
          <span>01</span>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="container float">

          <h3>Strategic Mastery</h3>
          <p>
            At ResilienceSoft, we don't just practise marketing; we're digital strategists committed to constant innovation in digital media marketing. Our adept team of digital media experts is perpetually ahead of the curve in digital media marketing. We live and breathe digital, crafting and implementing data-driven strategies that consistently transcend the ordinary in digital media marketing. Our focus? Ensuring every digital campaign serves your growth objectives through digital media marketing.
            </p>
        </div>
      </div>
    </div>
    <div class="row content-one mt-0">
      <div class="col-sm-6 center-line-l">
        <img loading="lazy" src="./assets/img/our-business-21.png" alt="our-business-2" class="imgflip" >
        <div class="s-no-l">
          <span>02</span>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="container float">
          <h3>Tailored Excellence</h3>
          <p class="one-p">In the ever-evolving world of digital media marketing, one size does not fit all. We recognize your brand's distinctiveness and thrive on customising solutions tailored precisely to your requirements in digital media marketing. From holistic digital marketing campaigns designed to amplify your online presence to finely-targeted initiatives in digital media marketing, we are artisans in crafting strategies that harmonise with your brand's unique identity in digital media marketing.

</p>
        </div>
      </div>
    </div>
    <div class="row content-one mt-0">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="./assets/img/our-business-3.png" alt="our-business-3">
        <div class="s-no">
          <span>03</span>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="container float mb-3">
          <h3>Comprehensive Brilliance
          </h3>
          <p>Our approach to digital media marketing doesn't stop at mere brushstrokes; we create the whole canvas in digital media marketing. ResilienceSoft offers an all-encompassing suite of digital marketing services in digital media marketing that work together seamlessly to amplify your brand's resonance. From the finesse of SEO and the artistry of social media mastery to the narrative brilliance of compelling content creation and the precision of paid advertising in digital media marketing, we provide a symphony of services that harmoniously contribute to your digital success.</p>
        </div>
      </div>
    </div>


    <div class="row content-one mt-0">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="./assets/img/our-business-2.png" alt="our-business-3">
        <div class="s-no">
          <span>03</span>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="container float mb-3">
          <h3>Innovation Through Insight</h3>
          <p class="one-p">Data is our North Star in digital media marketing. We base our decisions on comprehensive analysis and real-time insights in digital media marketing. Our unwavering commitment to data in digital media marketing isn't just about measuring success; it's about sculpting strategies continuously for evergreen relevance in it. This ensures your brand remains at the forefront of engagement, consistently capturing the hearts and minds of your audience through digital media marketing.</p>
        </div>
      </div>
    </div>
  </section>
  <!-- -------------------------our-business-mobile Section end----------------- -->