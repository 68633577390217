  <!-- ------------------Page Heading Start------------- -->

  <div class="page_heading">
    <div class="container">
      <!-- <h2>Native App development</h2> -->
      <h2>{{titles}}</h2>
    </div>
  </div>
  <app-banner [BannerData]="bannerArray"></app-banner>
  <!-- ------------------Page Heading End------------- -->

  <!-- ------------------Services web development Start-------------->
  <!-- <section class="why-choos-lg pad-tb deep-dark">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="common-heading text-l">
            <h2 class="mb20">BEST <span class="text-second text-bold">Native App Development Agency</span> Among The Leading Companies in India</h2>
            <p>iOS Apps Development- Swift 4 and earlier versions <br>
              Android Mobile Apps Development- Java <br>
              Extending Native Plug-ins <br>
              Knowledge about all native IDE <br>
              Knowledge of various UI style conventions and standards.</p>
           
          </div>
        </div>
        <div class="col-lg-6">
          <div data-tilt="" data-tilt-max="5" data-tilt-speed="1000" class="single-image bg-shape-dez wow fadeIn" data-wow-duration="2s" style="visibility: visible; animation-duration: 2s; animation-name: fadeIn; will-change: transform; transform: perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1);"><img loading="lazy" src="assets/images/app-development-rn.png" alt="image" class="img-fluid"></div>
         
        </div>
      </div>
    </div>
  </section> -->

  <!-- ------------------Services web development End---------------->

  <!-- new code -->
  <section class="our-business">
    <div class="row  our-business-heading mt-0">
      <div class="col-sm-12  ">
        <h2> Native App development

        </h2>
        <span> Why Native App Development Matters</span>
      </div>
    </div>
    <div class="row content-one mt-5">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="./assets/img/our-business-22.png" alt="our-business-3">
        <div class="s-no">
          <span>01</span>
        </div>
      </div>
      <div class="col-sm-6 googleRanking">
        <div class="float">
          <h3>Native App Development</h3>
          <p>
            In the realm of native app development, every project is a distinctive entity. At Resiliencesoft, we don't just acknowledge this fact – we embrace it. Our approach begins with an immersive collaboration, where we delve deep into your native app development vision and requirements. We're not just developers; we're partners in your native app development journey. By understanding your aspirations, we craft solutions that are as unique as your ideas, and meticulously aligned with your objectives and branding identity. This level of personalization ensures that your native app stands out from the crowd, catering precisely to your target audience's native app development needs.</p>
        </div>
      </div>
    </div>
    <div class="row content-one mt-0">
      <div class="col-sm-6 localseoservice">
        <div class="float-l">
          <h3>Design Excellence</h3>
          <p class="one-p"> In a world where first impressions matter, design plays a key role in native app success. At Resiliencesoft, design isn't just about visuals; it's about creating a native app experience that users can't resist. Our team of exceptionally talented native app designers doesn't just create stunning visuals; they produce an aesthetic symphony that harmoniously blends with functionality. The result? Native apps that aren't just beautiful, but also reflexive and user-centric. When native app aesthetics and functionality combine seamlessly, users don't just interact – they fall in love.
          </p>
        </div>
      </div>
      <div class="col-sm-6 center-line-l">
        <img loading="lazy" src="./assets/img/our-business-2.png" alt="our-business-2">
        <div class="s-no-l">
          <span>02</span>
        </div>
      </div>
    </div>
    <div class="row content-one mt-0">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="./assets/img/our-business-3.png" alt="our-business-3">
        <div class="s-no">
          <span>03</span>
        </div>
      </div>
      <div class="col-sm-6 googlePenalty">
        <div class="float">
          <h3>Quality Assurance
          </h3>
          <p>
            The journey from concept to a fully functional native app demands a commitment to excellence in native app development. At Resiliencesoft, we are uncompromising in our pursuit of quality in native app development. Our testing processes are as strict as they are comprehensive. We meticulously analyze your native app across a multitude of devices, screen sizes, and real-world scenarios. This unwavering dedication to quality assurance ensures that your native app doesn't just work – it works flawlessly. We leave no room for bugs, glitches, or hiccups that could spoil the user experience of your native app. With us, your users' trust in native app development isn't just earned – it's safeguarded.
          </p>
        </div>
      </div>
    </div>
    <div class="row content-one mt-0">
      <div class="col-sm-6 localseoservice">
        <div class="float-l">
          <h3>Agile Development</h3>
          <p class="one-p">In the dynamic landscape of native app development, change is unavoidable. That's why, at Resiliencesoft, we embrace agility in native app development. Our native app development methodology isn't just a process; it's a mindset. We follow agile methodologies that empower us to adapt swiftly to evolving requirements in native app development. This agility in native app development doesn't just mean we navigate change – it means we thrive in it. Through incremental updates and constant communication, we keep you in the loop in native app development, ensuring that your feedback shapes the native app development journey. With us, your native app isn't just a project; it's a collaborative evolution that leads to excellence in native app development.

          </p>
        </div>
      </div>
      <div class="col-sm-6 center-line-l">
        <img loading="lazy" src="./assets/img/our-business-21.png" alt="our-business-2" 
        class="imgflip" >
        <div class="s-no-l">
          <span>04</span>
        </div>
      </div>
    </div>



  </section>





  <!-- -------------------------our-business-mobile Section start----------------- -->
  <section class="our-business-mobile">
    <div class="row  our-business-heading mt-0">
      <div class="col-sm-12">
        <h2>Native App development</h2>
        <span> Why Native App Development Matters</span>
      </div>
    </div>
    <div class="row content-one mt-5">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="./assets/img/our-business-22.png" alt="our-business">
        <div class="s-no">
          <span>01</span>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="container float">

          <h3>Native App Development</h3>
          <p>
            In the realm of native app development, every project is a distinctive entity. At Resiliencesoft, we don't just acknowledge this fact – we embrace it. Our approach begins with an immersive collaboration, where we delve deep into your native app development vision and requirements. We're not just developers; we're partners in your native app development journey. By understanding your aspirations, we craft solutions that are as unique as your ideas, and meticulously aligned with your objectives and branding identity. This level of personalization ensures that your native app stands out from the crowd, catering precisely to your target audience's native app development needs.</p>
        </div>
      </div>
    </div>
    <div class="row content-one mt-0">
      <div class="col-sm-6 center-line-l">
        <img loading="lazy" src="./assets/img/our-business-2.png" alt="our-business-2">
        <div class="s-no-l">
          <span>02</span>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="container float">
          <h3>Design Excellence</h3>
          <p class="one-p"> In a world where first impressions matter, design plays a key role in native app success. At Resiliencesoft, design isn't just about visuals; it's about creating a native app experience that users can't resist. Our team of exceptionally talented native app designers doesn't just create stunning visuals; they produce an aesthetic symphony that harmoniously blends with functionality. The result? Native apps that aren't just beautiful, but also reflexive and user-centric. When native app aesthetics and functionality combine seamlessly, users don't just interact – they fall in love.
          </p>
        </div>
      </div>
    </div>
    <div class="row content-one mt-0">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="./assets/img/our-business-3.png" alt="our-business-3">
        <div class="s-no">
          <span>03</span>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="container float mb-3">
            
          
          <h3>Quality Assurance
          </h3>
          <p>
            The journey from concept to a fully functional native app demands a commitment to excellence in native app development. At Resiliencesoft, we are uncompromising in our pursuit of quality in native app development. Our testing processes are as strict as they are comprehensive. We meticulously analyze your native app across a multitude of devices, screen sizes, and real-world scenarios. This unwavering dedication to quality assurance ensures that your native app doesn't just work – it works flawlessly. We leave no room for bugs, glitches, or hiccups that could spoil the user experience of your native app. With us, your users' trust in native app development isn't just earned – it's safeguarded.
          </p>
        </div>
      </div>
    </div>


    <div class="row content-one mt-0">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="./assets/img/our-business-21.png" alt="our-business-3" 
        class="imgflip" >
        <div class="s-no">
          <span>03</span>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="container float mb-3">
          <h3>Agile Development</h3>
          <p class="one-p">In the dynamic landscape of native app development, change is unavoidable. That's why, at Resiliencesoft, we embrace agility in native app development. Our native app development methodology isn't just a process; it's a mindset. We follow agile methodologies that empower us to adapt swiftly to evolving requirements in native app development. This agility in native app development doesn't just mean we navigate change – it means we thrive in it. Through incremental updates and constant communication, we keep you in the loop in native app development, ensuring that your feedback shapes the native app development journey. With us, your native app isn't just a project; it's a collaborative evolution that leads to excellence in native app development.

          </p>
        </div>
      </div>
    </div>
  </section>
  <!-- -------------------------our-business-mobile Section end----------------- -->