import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { capatelize } from 'src/app/SEO_Constant/routes.constant';

@Component({
  selector: 'app-website-designing',
  templateUrl: './website-designing.component.html',
  styleUrls: ['./website-designing.component.css']
})
export class WebsiteDesigningComponent implements OnInit {
  titles:any
  bannerArray:any = {
    "bannerTXT" : [
      {
        title: 'Web design refers to the design of websites that are displayed on the internet.',
      },
      {
        title: 'Learning web design gives you the skills.',
      },
      {
        title: 'Designers also have a great scope in the media Industry.',
      },
      {
        title: 'Web design has numerous components.',
      },
    ],
    "bannerImg" : {
      "banner": "assets/img/web_design.png",
      "backgroundImg": "assets/img/banner-background.jpg"
    }
  }
  constructor( private title : Title, private router:Router ) {
    this.titles = router.url.split('/')[router.url.split('/').length-1].replace(/-/g,' ')
   }


  ngOnInit(): void {

    this.title.setTitle(capatelize(this.titles) +' | Resiliencesoft')
    // this.bannerArray.bannerTxt = this.bannerTXT
    // this.bannerArray.bannerImg = {}
    // this.bannerArray.bannerImg.banner = "assets/images/Screenshot 2022-09-24 163844.png"
    // this.bannerArray.bannerImg.bannerMobile = "assets/images/Our-Product.jpg"
    // console.log(this.bannerArray.bannerTxt)
    // console.log(this.bannerArray)
  }

}
