  <!-- ------------------Page Heading Start------------- -->

  <div class="page_heading">
    <div class="container">
      <!-- <h2>Logo Design</h2> -->
      <h2>{{titles}}</h2>
    </div>
  </div>
  <app-banner [BannerData]="bannerArray"></app-banner>
  <!-- ------------------Page Heading End------------- -->

  <!-- ------------------Services web development Start-------------->
  <!-- <section class="why-choos-lg pad-tb deep-dark">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="common-heading text-l">
            <h2 class="mb20">BEST <span class="text-second text-bold">Logo Design Agency</span> Among The Leading Companies in India</h2>
            <p>A well-designed logo builds trust by validating your professionalism and get's people to stick around. It tells potential clients who you are, what you do, and how that benefits them. It communicates to people with no prior knowledge or experience with your business that you do great work.</p>
            <div class="itm-media-object mt40 tilt-3d">
              <div class="media">
                  <p>A logo can quickly grab viewers’ attention and communicate a company’s core values in an interesting way. That short attention span you know, the one that causes consumers to judge your business by its appearance, can work to your advantage, if you have a solid logo to speak for your company.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div data-tilt="" data-tilt-max="5" data-tilt-speed="1000" class="single-image bg-shape-dez wow fadeIn" data-wow-duration="2s" style="visibility: visible; animation-duration: 2s; animation-name: fadeIn; will-change: transform; transform: perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1);"><img loading="lazy" src="assets/images/logo-design.png" alt="image" class="img-fluid"></div>
        </div>
      </div>
    </div>
  </section> -->

  <!-- ------------------Services web development End---------------->



  <!-- new code nikhil -->

  <!-- -------------------------our-business Section Start----------------- -->
  <section class="our-business">
    <div class="row  our-business-heading mt-0">
      <div class="col-sm-12">
        <h2>Logo Design</h2>
        <span> Best logo Designing Agency Among The Leading Companies in India
        </span>
      </div>
    </div>
    <div class="row content-one mt-5">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="./assets/img/our-business-3.png" alt="our-business-3">

        <div class="s-no">
          <span>01</span>
        </div>
      </div>
      <div class="col-sm-6 googleRanking">
        <div class="float">
          <h3>Excellence in Every Stroke</h3>
          <p>At Resilience Soft, Logo Design is not just our specialty; it's our passion. We breathe life into your ideas through our exceptional logo design expertise. Our team of skilled designers is dedicated to crafting unique and memorable logos that set your brand apart. Explore our extensive portfolio, a testament to our versatility in logo design styles and our unwavering commitment to excellence in every logo design project.


          </p>
        </div>
      </div>
    </div>
    <div class="row content-one mt-0">
      <div class="col-sm-6 localseoservice">
        <div class="float-l">
          <h3>Collaborative Approach to Logo Design</h3>
          <p class="one-p">At the heart of our logo design process lies your vision. We firmly embrace a collaborative approach, partnering with you throughout every step of logo design. Your valuable input and feedback are not just important; they are pivotal. They steer the course of logo development. Your satisfaction stands as our ultimate aspiration and guides our every move in the intricate world of logo design.</p>
        </div>
      </div>
      <div class="col-sm-6 center-line-l">
        <img loading="lazy" src="./assets/img/our-business-22.png" alt="our-business-2" class="imgflip" >
        <div class="s-no-l">
          <span>02</span>
        </div>
      </div>
    </div>
    <div class="row content-one mt-0">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="./assets/img/our-business-21.png" alt="our-business-3">
        <div class="s-no">
          <span>03</span>
        </div>
      </div>
      <div class="col-sm-6 googlePenalty">
        <div class="float">
          <h3>Timely Delivery of Your Logo </h3>
          <p>
            We deeply appreciate the significance of logo design deadlines. Our unwavering dedication to punctuality ensures that your logo design project is not only delivered on time but is also of the highest quality. We recognize the value of your time and understand that your logo design is a fundamental cornerstone of your brand's identity. Our commitment to delivering your logo design promptly, without ever compromising on the quality, is a testament to our dedication to your brand's success.
            </p>
        </div>
      </div>
    </div>





    <div class="row content-one mt-0">
      <div class="col-sm-6 localseoservice">
        <div class="float-l">
          <h3> Affordable Excellence in Logo Design</h3>
          <p class="one-p">Logo design is our passion, and we're here to prove that it doesn't have to break the bank.At our core, we're committed to providing top-notch logo design services that won't drain your wallet. We firmly believe that everyone, regardless of their budget, should have access to exceptional logo design. Our mission is simple: affordable excellence in logo design. When you partner with us, you're investing in a high-quality logo that perfectly represents your brand. Your brand deserves nothing less than the best, and that's precisely what we deliver through our logo design services</p>
        </div>
      </div>
      <div class="col-sm-6 center-line-l">
        <img loading="lazy" src="./assets/img/our-business-2.png" alt="our-business-2">
        <div class="s-no-l">
          <span>04</span>
        </div>
      </div>
    </div>

  </section>




    <!-- -------------------------our-business-mobile Section start----------------- -->
    <section class="our-business-mobile">
      <div class="row  our-business-heading mt-0">
        <div class="col-sm-12">
          <h2>logo Design</h2>
          <span>
            Best Logo Designing Agency Among The Leading Companies in India</span>
        </div>
      </div>
      <div class="row content-one mt-5">
        <div class="col-sm-6 center-line">
          <img loading="lazy" src="./assets/img/our-business-3.png" alt="our-business">
          <div class="s-no">
            <span>01</span>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="container float">
  
            <h3>Excellence in Every Stroke</h3>
            <p>
              At Resilience Soft, Logo Design is not just our specialty; it's our passion. We breathe life into your ideas through our exceptional logo design expertise. Our team of skilled designers is dedicated to crafting unique and memorable logos that set your brand apart. Explore our extensive portfolio, a testament to our versatility in logo design styles and our unwavering commitment to excellence in every logo design project.
              </p>
          </div>
        </div>
      </div>
      <div class="row content-one mt-0">
        <div class="col-sm-6 center-line-l">
          <img loading="lazy" src="./assets/img/our-business-22.png" alt="our-business-2" class="imgflip" >
          <div class="s-no-l">
            <span>02</span>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="container float">
            <h3>Collaborative Approach to Logo Design
            </h3>
            <p class="one-p">At the heart of our logo design process lies your vision. We firmly embrace a collaborative approach, partnering with you throughout every step of logo design. Your valuable input and feedback are not just important; they are pivotal. They steer the course of logo development. Your satisfaction stands as our ultimate aspiration and guides our every move in the intricate world of logo design.</p>
          </div>
        </div>
      </div>
      <div class="row content-one mt-0">
        <div class="col-sm-6 center-line">
          <img loading="lazy" src="./assets/img/our-business-21.png" alt="our-business-3">
          <div class="s-no">
            <span>03</span>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="container float mb-3">
            <h3>Timely Delivery of Your Logo </h3>
            <p>
              We deeply appreciate the significance of logo design deadlines. Our unwavering dedication to punctuality ensures that your logo design project is not only delivered on time but is also of the highest quality. We recognize the value of your time and understand that your logo design is a fundamental cornerstone of your brand's identity. Our commitment to delivering your logo design promptly, without ever compromising on the quality, is a testament to our dedication to your brand's success.</p>
          </div>
        </div>
      </div>
  
  
      <div class="row content-one mt-0">
        <div class="col-sm-6 center-line">
          <img loading="lazy" src="./assets/img/our-business-2.png" alt="our-business-3">
          <div class="s-no">
            <span>03</span>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="container float mb-3">
            <h3> Affordable Excellence in Logo Design</h3>
            <p>Logo design is our passion, and we're here to prove that it doesn't have to break the bank.At our core, we're committed to providing top-notch logo design services that won't drain your wallet. We firmly believe that everyone, regardless of their budget, should have access to exceptional logo design. Our mission is simple: affordable excellence in logo design. When you partner with us, you're investing in a high-quality logo that perfectly represents your brand. Your brand deserves nothing less than the best, and that's precisely what we deliver through our logo design services</p>
          </div>
        </div>
      </div>
    </section>
    <!-- -------------------------our-business-mobile Section end----------------- -->



  <!-- <section class="our-business">
    <div class="row  our-business-heading mt-0">
      <div class="col-sm-12">
        <span> Why Choose Resiliencesoft for Logo Design:
          </span>
      </div>
    </div>
    <div class="row content-one mt-5">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="./assets/img/our-business-3.png" alt="our-business-3">

        <div class="s-no">
          <span>01</span>
        </div>
      </div>
      <div class="col-sm-6 googleRanking">
        <div class="float">
          <h3>Expertise</h3>
          <p>Our design team comprises talented and experienced professionals who have a keen eye for aesthetics and a deep understanding of branding.</p>
        </div>
      </div>
    </div>
    <div class="row content-one mt-0">
      <div class="col-sm-6 localseoservice" >
        <div class="float-l">
          <h3>Conceptualization</h3>
          <p class="one-p">Tailored Solutions: We don't believe in one-size-fits-all solutions. Each logo we create is custom-made to reflect the uniqueness of your software company.</p>
        </div>
      </div>
      <div class="col-sm-6 center-line-l">
        <img loading="lazy" src="./assets/img/our-business-2.png" alt="our-business-2">
        <div class="s-no-l">
          <span>02</span>
        </div>
      </div>
    </div>
    <div class="row content-one mt-0">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="./assets/img/our-business-3.png" alt="our-business-3">
        <div class="s-no">
          <span>03</span>
        </div>
      </div>
      <div class="col-sm-6 googlePenalty">
        <div class="float">
          <h3>Timely Delivery</h3>
          <p>We understand the importance of time in the business world. You can rely on us to deliver your logo within agreed-upon deadlines.
          </p>
        </div>
      </div>
    </div>
  
  
  
    <div class="row content-one mt-0">
      <div class="col-sm-6 localseoservice" >
        <div class="float-l">
          <h3>Affordable Pricing</h3>
          <p class="one-p">Our pricing is competitive and transparent. We offer packages suitable for businesses of all sizes and budgets.</p>
        </div>
      </div>
      <div class="col-sm-6 center-line-l">
        <img loading="lazy" src="./assets/img/our-business-2.png" alt="our-business-2">
        <div class="s-no-l">
          <span>04</span>
        </div>
      </div>
    </div>
    
  </section> -->