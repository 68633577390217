  <!-- ------------------Page Heading Start------------- -->

  <div class="page_heading">
    <div class="container">
      <!-- <h2>Digital Visiting Card</h2> -->
      <h2>{{titles}}</h2>
    </div>
  </div>
  <app-banner [BannerData]="bannerArray"></app-banner>
  <!-- ------------------Page Heading End------------- -->

  <!-- ------------------Services web development Start-------------->
  <!-- <section class="why-choos-lg pad-tb deep-dark">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="common-heading text-l">
          <h2 class="mb20">BEST <span class="text-second text-bold">Digital Visiting Card Agency</span> Among The Leading Companies in India</h2>
          <p>A digital visiting card is the virtual version of a paper visiting card. Digital visiting cards can be sent and received by anyone, anywhere, digital cards contain more information, digital business cards are cost-effective and eco-friendly.</p>
          <div class="itm-media-object mt40 tilt-3d">
            <div class="media">
                <p>Not only does it convey important personal contact information such as name, title, email, website, address and phone number, but oftentimes it is also the first exposure to the overall image of the business. The company logo is prominently displayed for brand identity.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div data-tilt="" data-tilt-max="5" data-tilt-speed="1000" class="single-image bg-shape-dez wow fadeIn" data-wow-duration="2s" style="visibility: visible; animation-duration: 2s; animation-name: fadeIn; will-change: transform; transform: perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1);"><img loading="lazy" src="assets/images/visiting.png" alt="image" class="img-fluid"></div>
      </div>
    </div>
  </div>
</section> -->

  <!-- ------------------Services web development End---------------->



  <!-- new code nikhil -->

  <!-- -------------------------our-business Section Start----------------- -->
  <section class="our-business">
    <div class="row  our-business-heading mt-0">
      <div class="col-sm-12">
        <h2>Digital Visiting Card</h2>
        <span>Why Choose Digital Visit Cards for Your Company
        </span>
      </div>
    </div>
    <div class="row content-one mt-5">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="./assets/img/our-business-3.png" alt="our-business-3">

        <div class="s-no">
          <span>01</span>
        </div>
      </div>
      <div class="col-sm-6 googleRanking">
        <div class="float">
          <h3>Digital Visiting Card</h3>
          <p>
            In today's world, where first impressions can define your success, the digital visiting card takes centre stage as your ultimate digital introduction. It's not just a static piece of contact information; it's a dynamic reflection of your modern, tech-savvy persona. When you hand out your digital visiting card, you're not just exchanging information; you're signalling to your connections that you're forward-thinking and fully prepared for the digital age. This sleek and interactive digital card doesn't just blend in; it shines brilliantly amid the sea of traditional paper cards. It's your golden opportunity to make an unforgettable and memorable impression in the minds of your contacts.
          </p>
        </div>
      </div>
    </div>
    <div class="row content-one mt-0">
      <div class="col-sm-6 localseoservice">
        <div class="float-l">
          <h3>Embracing Sustainability with Digital Visiting Cards</h3>
          <p class="one-p">
            In a world where sustainability isn't just a trend but a shared responsibility, the digital visiting card emerges as a champion of eco-friendliness. It's time to bid farewell to the paper waste associated with traditional networking tools. Instead, wholeheartedly embrace the digital transformation that respects our environment. Your commitment to environmental responsibility won't go unnoticed, especially among eco-conscious professionals who deeply appreciate your forward-looking approach to networking through digital visiting cards.
            </p>
        </div>
      </div>
      <div class="col-sm-6 center-line-l">
        <img loading="lazy" src="./assets/img/our-business-21.png" alt="our-business-2" class="imgflip"   >
        <div class="s-no-l">
          <span>02</span>
        </div>
      </div>
    </div>
    <div class="row content-one mt-0">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="./assets/img/our-business-22.png" alt="our-business-3"  >
        <div class="s-no">
          <span>03</span>
        </div>
      </div>
      <div class="col-sm-6 googlePenalty">
        <div class="float">
          <h3>Accessibility Anytime, Anywhere</h3>
          <p>
            One of the most significant advantages of the digital visiting card is that it's never left behind. Whether you're at a bustling conference, a high-stakes business meeting, or a casual networking event, your digital card is always right at your fingertips. It resides seamlessly on smartphones, tablets, and computers, ensuring that your essential contact information and professional details are just a tap away for your contacts. There's no longer a need to carry physical cards or worry about running out of them at crucial moments. Your digital visiting card is your 24/7 virtual presence, ready to be shared anytime and anywhere, even when you're on the move.
            </p>
        </div>
      </div>
    </div>





    <div class="row content-one mt-0">
      <div class="col-sm-6 localseoservice">
        <div class="float-l">
          <h3>Engage and Interact Effectively</h3>
          <p class="one-p">
            What sets digital visiting cards apart from their paper counterparts is their remarkable interactivity. They Outshine the static nature of traditional cards, transforming into dynamic tools that empower you to engage your audience effectively. With ease, you can include clickable links to your website, social media profiles, and much more. Showcase your portfolio, share engaging videos that narrate your brand story, or provide direct access to your services. This high level of interactivity doesn't just make your card more engaging; it also provides valuable insights into your audience's interests and interactions, helping you tailor your networking approach more effectively.
          </p>
        </div>
      </div>
      <div class="col-sm-6 center-line-l">
        <img loading="lazy" src="./assets/img/our-business-2.png" alt="our-business-2">
        <div class="s-no-l">
          <span>04</span>
        </div>
      </div>
    </div>



    <!-- 
    <div class="row content-one mt-0">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="./assets/img/our-business-3.png" alt="our-business-3">
        <div class="s-no">
          <span>05</span>
        </div>
      </div>
      <div class="col-sm-6 googlePenalty">
        <div class="float">
          <h3>Eco-Friendly & Sustainable</h3>
          <p>By going digital, you contribute to a greener environment by reducing paper waste and the carbon footprint associated with traditional business cards.

          </p>
        </div>
      </div>
    </div> -->




  </section>




  <!-- -------------------------our-business-mobile Section start----------------- -->
  <section class="our-business-mobile">
    <div class="row  our-business-heading mt-0">
      <div class="col-sm-12">
        <h2>Digital Visiting Card</h2>
        <span>Why Choose Digital Visit Cards for Your Company</span>
      </div>
    </div>
    <div class="row content-one mt-5">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="./assets/img/our-business-3.png" alt="our-business">
        <div class="s-no">
          <span>01</span>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="container float">

          <h3>Digital Visiting Card</h3>
          <p>
            In today's world, where first impressions can define your success, the digital visiting card takes centre stage as your ultimate digital introduction. It's not just a static piece of contact information; it's a dynamic reflection of your modern, tech-savvy persona. When you hand out your digital visiting card, you're not just exchanging information; you're signalling to your connections that you're forward-thinking and fully prepared for the digital age. This sleek and interactive digital card doesn't just blend in; it shines brilliantly amid the sea of traditional paper cards. It's your golden opportunity to make an unforgettable and memorable impression in the minds of your contacts.</p>
        </div>
      </div>
    </div>
    <div class="row content-one mt-0">
      <div class="col-sm-6 center-line-l">
        <img loading="lazy" src="./assets/img/our-business-21.png" alt="our-business-2" class="imgflip" >
        <div class="s-no-l">
          <span>02</span>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="container float">
          <h3>Embracing Sustainability with Digital Visiting Cards</h3>
          <p class="one-p">
            In a world where sustainability isn't just a trend but a shared responsibility, the digital visiting card emerges as a champion of eco-friendliness. It's time to bid farewell to the paper waste associated with traditional networking tools. Instead, wholeheartedly embrace the digital transformation that respects our environment. Your commitment to environmental responsibility won't go unnoticed, especially among eco-conscious professionals who deeply appreciate your forward-looking approach to networking through digital visiting cards.
            </p>
        </div>
      </div>
    </div>
    <div class="row content-one mt-0">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="./assets/img/our-business-22.png" alt="our-business-3" >
        <div class="s-no">
          <span>03</span>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="container float mb-3">
          <h3>Accessibility Anytime, Anywhere</h3>
          <p>
            One of the most significant advantages of the digital visiting card is that it's never left behind. Whether you're at a bustling conference, a high-stakes business meeting, or a casual networking event, your digital card is always right at your fingertips. It resides seamlessly on smartphones, tablets, and computers, ensuring that your essential contact information and professional details are just a tap away for your contacts. There's no longer a need to carry physical cards or worry about running out of them at crucial moments. Your digital visiting card is your 24/7 virtual presence, ready to be shared anytime and anywhere, even when you're on the move.
            </p>
        </div>
      </div>
    </div>


    <div class="row content-one mt-0">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="./assets/img/our-business-2.png" alt="our-business-3">
        <div class="s-no">
          <span>03</span>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="container float mb-3">
          <h3>Engage and Interact Effectively</h3>
          <p class="one-p">
            What sets digital visiting cards apart from their paper counterparts is their remarkable interactivity. They Outshine the static nature of traditional cards, transforming into dynamic tools that empower you to engage your audience effectively. With ease, you can include clickable links to your website, social media profiles, and much more. Showcase your portfolio, share engaging videos that narrate your brand story, or provide direct access to your services. This high level of interactivity doesn't just make your card more engaging; it also provides valuable insights into your audience's interests and interactions, helping you tailor your networking approach more effectively.
          </p>
        </div>
      </div>
    </div>
  </section>
  <!-- -------------------------our-business-mobile Section end----------------- -->



  <!-- <section class="our-business">
    <div class="row  our-business-heading mt-0">
      <div class="col-sm-12">
        <span>Features of Our Digital Visit Cards:
          </span>
      </div>
    </div>
    <div class="row content-one mt-5">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="./assets/img/our-business-3.png" alt="our-business-3">

        <div class="s-no">
          <span>01</span>
        </div>
      </div>
      <div class="col-sm-6 googleRanking">
        <div class="float">
          <h3>Custom Branding</h3>
          <p>Tailor your Digital Visit Card to reflect your software company's branding, colors, and logo, ensuring consistency across all touchpoints..</p>
        </div>
      </div>
    </div>
    <div class="row content-one mt-0">
      <div class="col-sm-6 localseoservice" >
        <div class="float-l">
          <h3>Contact Details & Social Links</h3>
          <p class="one-p">Tailored Solutions: We don't believe in one-size-fits-all solutions. Each logo we create is custom-made to reflect the uniqueness of your software company.</p>
        </div>
      </div>
      <div class="col-sm-6 center-line-l">
        <img loading="lazy" src="./assets/img/our-business-2.png" alt="our-business-2">
        <div class="s-no-l">
          <span>02</span>
        </div>
      </div>
    </div>
    <div class="row content-one mt-0">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="./assets/img/our-business-3.png" alt="our-business-3">
        <div class="s-no">
          <span>03</span>
        </div>
      </div>
      <div class="col-sm-6 googlePenalty">
        <div class="float">
          <h3>Rich Media Integration</h3>
          <p>Showcase your software solutions with multimedia elements, such as product videos, app demos, and portfolio images.
          </p>
        </div>
      </div>
    </div>
  
  
  
    <div class="row content-one mt-0">
      <div class="col-sm-6 localseoservice" >
        <div class="float-l">
          <h3>One-Tap Sharing</h3>
          <p class="one-p">Share your Digital Visit Card effortlessly via a simple tap on a smartphone screen or a quick scan of the QR code.</p>
        </div>
      </div>
      <div class="col-sm-6 center-line-l">
        <img loading="lazy" src="./assets/img/our-business-2.png" alt="our-business-2">
        <div class="s-no-l">
          <span>04</span>
        </div>
      </div>
    </div>



    <div class="row content-one mt-0">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="./assets/img/our-business-3.png" alt="our-business-3">
        <div class="s-no">
          <span>05</span>
        </div>
      </div>
      <div class="col-sm-6 googlePenalty">
        <div class="float">
          <h3>Analytics & Insights</h3>
          <p>Track the performance of your Digital Visit Card with real-time analytics, allowing you to measure its effectiveness in engaging your contacts.
          </p>
        </div>
      </div>
    </div>


    
  </section> -->