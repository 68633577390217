import { isPlatformBrowser } from '@angular/common';
import { Component, HostListener, Inject, Input, OnInit, PLATFORM_ID, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { APIcalls } from 'src/app/Services/api';
import { APIService } from 'src/app/Services/main-service.service';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css'],
})
export class BannerComponent implements OnInit {
  @Input() BannerData :any = [];
  // url = '/assets/img/banner-1.jpg';

  screenWidth?: number;

  slideConfigmsg = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2500,
  };
  formData: FormGroup;
  isBrowser: boolean;

  constructor(private api: APIService, private fb: FormBuilder, @Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.formData = this.fb.group({
      firstname: ['', [Validators.required, Validators.pattern('[a-zA-Z- ]*')]],
      lastname: ['', [ Validators.pattern('[a-zA-Z- ]*')]],
      email: ['', [Validators.pattern('^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$')]],
      Number: [
        '',
        [
          Validators.required,
          Validators.pattern('[0-9]*'),
          Validators.minLength(10),
          Validators.maxLength(10),
        ],
      ],
      msg: ['', [Validators.required, Validators.minLength(10)]],
    });
  }
  ngOnInit(): void {
    if(this.isBrowser){

      this.screenWidth = window.innerWidth;
    }
  }
  noSpace(event:any) {
    if (event.keyCode === 32 && !event.target.value) return false;
    return true;
  }
  get firstname() {
    return this.formData.get('firstname');
  }
  get lastname() {
    return this.formData.get('lastname');
  }
  get contact() {
    return this.formData.get('Number');
  }
  get email() {
    return this.formData.get('email');
  }
  get msg() {
    return this.formData.get('msg');
  }

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  @HostListener('window:resize')
  onResize() {
    if(this.isBrowser){
      this.screenWidth = window.innerWidth;
      console.log(this.screenWidth)
    }
  }

  getUrl( data:any){
    return { 'background-image': ` url('${data?.bannerImg?.backgroundImg}') `}
  }
  isSubmitting = false;
  submit() {
    if (this.isSubmitting) {
      return; // If already submitting, return to prevent multiple submissions
    }
  
    this.isSubmitting = true;
    const formData = new FormData();
    formData.append('name', (this.formData.get('firstname')?.value) + ' ' +(this.formData.get('lastname')?.value));
    formData.append('email', this.formData.get('email')?.value);
    formData.append('phone', this.formData.get('Number')?.value);
    formData.append('subject', 'Internship/Training');
    formData.append('message', this.formData.get('msg')?.value);
    this.api
      .postData(APIcalls.mailApi.Contact, formData)
      .subscribe({
      next: (res: any) => {
        this.api.openSnackBar(
          ' Form Has Been Successfully Submitted, We Will Contact You Soon ',
          5
        );
        this.formData.reset();
      },
      error: () => {
        this.api.openSnackBar(
          " We Can't Proceed Your Request at This Time ",
          2
        );
      },
      complete: () => {
        this.isSubmitting = false; // Set isSubmitting to false after the call is complete (either success or error)
      }
  });
  }
}
