import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { capatelize } from 'src/app/SEO_Constant/routes.constant';
import { description, keyword } from 'src/app/SEO_Constant/seo';

@Component({
  selector: 'app-search-engine-optimization',
  templateUrl: './search-engine-optimization.component.html',
  styleUrls: ['./search-engine-optimization.component.css'],
})
export class SearchEngineOptimizationComponent implements OnInit {
    titles:any;
    bannerArray:any = {
    "bannerTXT" : [
      {
        title: '#1 in Google SEO Services Since 2012.',
      },
      {
        title: '100% Targeted And Relevant Traffic.',
      },
      {
        title: 'Assured Top 10 Organic Rankings.',
      },
      {
        title: 'More Visibility, More Leads, More Business.',
      },
    ],
    "bannerImg" : {
      "banner": "assets/img/mobile-banner.png",
      "backgroundImg": "assets/img/banner-background.jpg"
    }
  }

  constructor(private title: Title, private meta: Meta, private router:Router) {
    this.titles = router.url.split('/')[router.url.split('/').length-1].replace(/-/g,' ')
  }

  ngOnInit(): void {
    this.title.setTitle(
      capatelize(this.titles) +' | Resiliencesoft'
    );
    this.meta.removeTag('name=description');
    this.meta.removeTag('name=keywords');
    this.meta.addTags([
      {
        name: 'description',
        content: description.SEO,
      },
      { name: 'keywords', content: capatelize(keyword.globle) + capatelize(keyword.SEO) },
    ]);
  }
}
