import { InternshipComponent } from '../internship/internship.component';
import { AndroidAppDevelopmentComponent } from '../service/Appsolution/android-app-development/android-app-development.component';
import { HybridAppDevelopmentComponent } from '../service/Appsolution/hybrid-app-development/hybrid-app-development.component';
import { IosAppDevelopmentComponent } from '../service/Appsolution/ios-app-development/ios-app-development.component';
import { NativeAppDevelopmentComponent } from '../service/Appsolution/native-app-development/native-app-development.component';
import { BulkSmsAndEmailMarketingComponent } from '../service/digitalmarketing/bulk-sms-and-email-marketing/bulk-sms-and-email-marketing.component';
import { DigitalMediaMarketingComponent } from '../service/digitalmarketing/digital-media-marketing/digital-media-marketing.component';
import { SearchEngineOptimizationComponent } from '../service/digitalmarketing/search-engine-optimization/search-engine-optimization.component';
import { SocialMediaPromotionComponent } from '../service/digitalmarketing/social-media-promotion/social-media-promotion.component';
import { DigitalVisitingCardComponent } from '../service/moreservices/digital-visiting-card/digital-visiting-card.component';
import { GraphicDesignComponent } from '../service/moreservices/graphic-design/graphic-design.component';
import { LogoDesignComponent } from '../service/moreservices/logo-design/logo-design.component';
import { VideoCreationComponent } from '../service/moreservices/video-creation/video-creation.component';
import { SoftwareDevelopmentComponent } from '../service/websolution/software-development/software-development.component';
import { WebApplicationDevelopmentComponent } from '../service/websolution/web-application-development/web-application-development.component';
import { WebHostingSolutionsComponent } from '../service/websolution/web-hosting-solutions/web-hosting-solutions.component';
import { WebsiteDesigningComponent } from '../service/websolution/website-designing/website-designing.component';

export const Route: { [k: string]: { component: any } } = {
  'Website Development Company In Bilaspur': {
    component: SoftwareDevelopmentComponent,
  },
  'Website Development Company In Bilaspur Chhattisgarh': {
    component: SoftwareDevelopmentComponent,
  },
  'Website Development Company In Raipur': {
    component: SoftwareDevelopmentComponent,
  },
  'Website Development Company In Raipur Chhattisgarh': {
    component: SoftwareDevelopmentComponent,
  },
  'Website Development Company In Chhattisgarh': {
    component: SoftwareDevelopmentComponent,
  },

  'Website Development Agency In Bilaspur': {
    component: SoftwareDevelopmentComponent,
  },
  'Website Development Agency In Bilaspur Chhattisgarh': {
    component: SoftwareDevelopmentComponent,
  },
  'Website Development Agency In Raipur': {
    component: SoftwareDevelopmentComponent,
  },
  'Website Development Agency In Raipur Chhattisgarh': {
    component: SoftwareDevelopmentComponent,
  },
  'Website Development Agency In Chhattisgarh': {
    component: SoftwareDevelopmentComponent,
  },

  'Best Website Development Company In Bilaspur': {
    component: SoftwareDevelopmentComponent,
  },
  'Best Website Development Company In Bilaspur Chhattisgarh': {
    component: SoftwareDevelopmentComponent,
  },
  'Best Website Development Company In Raipur': {
    component: SoftwareDevelopmentComponent,
  },
  'Best Website Development Company In Raipur Chhattisgarh': {
    component: SoftwareDevelopmentComponent,
  },
  'Best Website Development Company In Chhattisgarh': {
    component: SoftwareDevelopmentComponent,
  },
  'Best Website Development Agency In Bilaspur': {
    component: SoftwareDevelopmentComponent,
  },
  'Best Website Development Agency In Bilaspur Chhattisgarh': {
    component: SoftwareDevelopmentComponent,
  },
  'Best Website Development Agency In Raipur': {
    component: SoftwareDevelopmentComponent,
  },
  'Best Website Development Agency In Raipur Chhattisgarh': {
    component: SoftwareDevelopmentComponent,
  },
  'Best Website Development Agency In Chhattisgarh': {
    component: SoftwareDevelopmentComponent,
  },

  'Top Website Development Company In Bilaspur': {
    component: SoftwareDevelopmentComponent,
  },
  'Top Website Development Company In Bilaspur Chhattisgarh': {
    component: SoftwareDevelopmentComponent,
  },
  'Top Website Development Company In Raipur': {
    component: SoftwareDevelopmentComponent,
  },
  'Top Website Development Company In Raipur Chhattisgarh': {
    component: SoftwareDevelopmentComponent,
  },
  'Top Website Development Company In Chhattisgarh': {
    component: SoftwareDevelopmentComponent,
  },
  'Top Website Development Agency In Bilaspur': {
    component: SoftwareDevelopmentComponent,
  },
  'Top Website Development Agency In Bilaspur Chhattisgarh': {
    component: SoftwareDevelopmentComponent,
  },
  'Top Website Development Agency In Raipur': {
    component: SoftwareDevelopmentComponent,
  },
  'Top Website Development Agency In Raipur Chhattisgarh': {
    component: SoftwareDevelopmentComponent,
  },
  'Top Website Development Agency In Chhattisgarh': {
    component: SoftwareDevelopmentComponent,
  },
  'Software Development Company near me': {
    component: SoftwareDevelopmentComponent,
  },
  'Software Development Companies near me': {
    component: SoftwareDevelopmentComponent,
  },

  //Website-Designing

  'Website Designing Company In Bilaspur': {
    component: WebsiteDesigningComponent,
  },
  'Website Designing Company In Bilaspur Chhattisgarh': {
    component: WebsiteDesigningComponent,
  },
  'Website Designing Company In Raipur': {
    component: WebsiteDesigningComponent,
  },
  'Website Designing Company In Raipur Chhattisgarh': {
    component: WebsiteDesigningComponent,
  },
  'Website Designing Company In Chhattisgarh': {
    component: WebsiteDesigningComponent,
  },

  'Website Designing Agency In Bilaspur': {
    component: WebsiteDesigningComponent,
  },
  'Website Designing Agency In Bilaspur Chhattisgarh': {
    component: WebsiteDesigningComponent,
  },
  'Website Designing Agency In Raipur': {
    component: WebsiteDesigningComponent,
  },
  'Website Designing Agency In Raipur Chhattisgarh': {
    component: WebsiteDesigningComponent,
  },
  'Website Designing Agency In Chhattisgarh': {
    component: WebsiteDesigningComponent,
  },

  'Best Website Designing Company In Bilaspur': {
    component: WebsiteDesigningComponent,
  },
  'Best Website Designing Company In Bilaspur Chhattisgarh': {
    component: WebsiteDesigningComponent,
  },
  'Best Website Designing Company In Raipur': {
    component: WebsiteDesigningComponent,
  },
  'Best Website Designing Company In Raipur Chhattisgarh': {
    component: WebsiteDesigningComponent,
  },
  'Best Website Designing Company In Chhattisgarh': {
    component: WebsiteDesigningComponent,
  },
  'Best Website Designing Agency In Bilaspur': {
    component: WebsiteDesigningComponent,
  },
  'Best Website Designing Agency In Bilaspur Chhattisgarh': {
    component: WebsiteDesigningComponent,
  },
  'Best Website Designing Agency In Raipur': {
    component: WebsiteDesigningComponent,
  },
  'Best Website Designing Agency In Raipur Chhattisgarh': {
    component: WebsiteDesigningComponent,
  },
  'Best Website Designing Agency In Chhattisgarh': {
    component: WebsiteDesigningComponent,
  },

  'Top Website Designing Company In Bilaspur': {
    component: WebsiteDesigningComponent,
  },
  'Top Website Designing Company In Bilaspur Chhattisgarh': {
    component: WebsiteDesigningComponent,
  },
  'Top Website Designing Company In Raipur': {
    component: WebsiteDesigningComponent,
  },
  'Top Website Designing Company In Raipur Chhattisgarh': {
    component: WebsiteDesigningComponent,
  },
  'Top Website Designing Company In Chhattisgarh': {
    component: WebsiteDesigningComponent,
  },
  'Top Website Designing Agency In Bilaspur': {
    component: WebsiteDesigningComponent,
  },
  'Top Website Designing Agency In Bilaspur Chhattisgarh': {
    component: WebsiteDesigningComponent,
  },
  'Top Website Designing Agency In Raipur': {
    component: WebsiteDesigningComponent,
  },
  'Top Website Designing Agency In Raipur Chhattisgarh': {
    component: WebsiteDesigningComponent,
  },
  'Top Website Designing Agency In Chhattisgarh': {
    component: WebsiteDesigningComponent,
  },

  //Web Application Development

  'Web Application Devlopment Company In Bilaspur': {
    component: WebApplicationDevelopmentComponent,
  },
  'Web Application Devlopment Company In Bilaspur Chhattisgarh': {
    component: WebApplicationDevelopmentComponent,
  },
  'Web Application Devlopment Company In Raipur': {
    component: WebApplicationDevelopmentComponent,
  },
  'Web Application Devlopment Company In Raipur Chhattisgarh': {
    component: WebApplicationDevelopmentComponent,
  },
  'Web Application Devlopment Company In Chhattisgarh': {
    component: WebApplicationDevelopmentComponent,
  },

  'Web Application Devlopment Agency In Bilaspur': {
    component: WebApplicationDevelopmentComponent,
  },
  'Web Application Devlopment Agency In Bilaspur Chhattisgarh': {
    component: WebApplicationDevelopmentComponent,
  },
  'Web Application Devlopment Agency In Raipur': {
    component: WebApplicationDevelopmentComponent,
  },
  'Web Application Devlopment Agency In Raipur Chhattisgarh': {
    component: WebApplicationDevelopmentComponent,
  },
  'Web Application Devlopment Agency In Chhattisgarh': {
    component: WebApplicationDevelopmentComponent,
  },

  'Best Web Application Devlopment Company In Bilaspur': {
    component: WebApplicationDevelopmentComponent,
  },
  'Best Web Application Devlopment Company In Bilaspur Chhattisgarh': {
    component: WebApplicationDevelopmentComponent,
  },
  'Best Web Application Devlopment Company In Raipur': {
    component: WebApplicationDevelopmentComponent,
  },
  'Best Web Application Devlopment Company In Raipur Chhattisgarh': {
    component: WebApplicationDevelopmentComponent,
  },
  'Best Web Application Devlopment Company In Chhattisgarh': {
    component: WebApplicationDevelopmentComponent,
  },
  'Best Web Application Devlopment Agency In Bilaspur': {
    component: WebApplicationDevelopmentComponent,
  },
  'Best Web Application Devlopment Agency In Bilaspur Chhattisgarh': {
    component: WebApplicationDevelopmentComponent,
  },
  'Best Web Application Devlopment Agency In Raipur': {
    component: WebApplicationDevelopmentComponent,
  },
  'Best Web Application Devlopment Agency In Raipur Chhattisgarh': {
    component: WebApplicationDevelopmentComponent,
  },
  'Best Web Application Devlopment Agency In Chhattisgarh': {
    component: WebApplicationDevelopmentComponent,
  },

  'Top Web Application Devlopment Company In Bilaspur': {
    component: WebApplicationDevelopmentComponent,
  },
  'Top Web Application Devlopment Company In Bilaspur Chhattisgarh': {
    component: WebApplicationDevelopmentComponent,
  },
  'Top Web Application Devlopment Company In Raipur': {
    component: WebApplicationDevelopmentComponent,
  },
  'Top Web Application Devlopment Company In Raipur Chhattisgarh': {
    component: WebApplicationDevelopmentComponent,
  },
  'Top Web Application Devlopment Company In Chhattisgarh': {
    component: WebApplicationDevelopmentComponent,
  },
  'Top Web Application Devlopment Agency In Bilaspur': {
    component: WebApplicationDevelopmentComponent,
  },
  'Top Web Application Devlopment Agency In Bilaspur Chhattisgarh': {
    component: WebApplicationDevelopmentComponent,
  },
  'Top Web Application Devlopment Agency In Raipur': {
    component: WebApplicationDevelopmentComponent,
  },
  'Top Web Application Devlopment Agency In Raipur Chhattisgarh': {
    component: WebApplicationDevelopmentComponent,
  },
  'Top Web Application Devlopment Agency In Chhattisgarh': {
    component: WebApplicationDevelopmentComponent,
  },

  // Web hosting

  'Web Hosting Solutions Company In Bilaspur': {
    component: WebHostingSolutionsComponent,
  },
  'Web Hosting Solutions Company In Bilaspur Chhattisgarh': {
    component: WebHostingSolutionsComponent,
  },
  'Web Hosting Solutions Company In Raipur': {
    component: WebHostingSolutionsComponent,
  },
  'Web Hosting Solutions Company In Raipur Chhattisgarh': {
    component: WebHostingSolutionsComponent,
  },
  'Web Hosting Solutions Company In Chhattisgarh': {
    component: WebHostingSolutionsComponent,
  },

  'Web Hosting Solutions Agency In Bilaspur': {
    component: WebHostingSolutionsComponent,
  },
  'Web Hosting Solutions Agency In Bilaspur Chhattisgarh': {
    component: WebHostingSolutionsComponent,
  },
  'Web Hosting Solutions Agency In Raipur': {
    component: WebHostingSolutionsComponent,
  },
  'Web Hosting Solutions Agency In Raipur Chhattisgarh': {
    component: WebHostingSolutionsComponent,
  },
  'Web Hosting Solutions Agency In Chhattisgarh': {
    component: WebHostingSolutionsComponent,
  },

  'Best Web Hosting Solutions Company In Bilaspur': {
    component: WebHostingSolutionsComponent,
  },
  'Best Web Hosting Solutions Company In Bilaspur Chhattisgarh': {
    component: WebHostingSolutionsComponent,
  },
  'Best Web Hosting Solutions Company In Raipur': {
    component: WebHostingSolutionsComponent,
  },
  'Best Web Hosting Solutions Company In Raipur Chhattisgarh': {
    component: WebHostingSolutionsComponent,
  },
  'Best Web Hosting Solutions Company In Chhattisgarh': {
    component: WebHostingSolutionsComponent,
  },
  'Best Web Hosting Solutions Agency In Bilaspur': {
    component: WebHostingSolutionsComponent,
  },
  'Best Web Hosting Solutions Agency In Bilaspur Chhattisgarh': {
    component: WebHostingSolutionsComponent,
  },
  'Best Web Hosting Solutions Agency In Raipur': {
    component: WebHostingSolutionsComponent,
  },
  'Best Web Hosting Solutions Agency In Raipur Chhattisgarh': {
    component: WebHostingSolutionsComponent,
  },
  'Best Web Hosting Solutions Agency In Chhattisgarh': {
    component: WebHostingSolutionsComponent,
  },

  'Top Web Hosting Solutions Company In Bilaspur': {
    component: WebHostingSolutionsComponent,
  },
  'Top Web Hosting Solutions Company In Bilaspur Chhattisgarh': {
    component: WebHostingSolutionsComponent,
  },
  'Top Web Hosting Solutions Company In Raipur': {
    component: WebHostingSolutionsComponent,
  },
  'Top Web Hosting Solutions Company In Raipur Chhattisgarh': {
    component: WebHostingSolutionsComponent,
  },
  'Top Web Hosting Solutions Company In Chhattisgarh': {
    component: WebHostingSolutionsComponent,
  },
  'Top Web Hosting Solutions Agency In Bilaspur': {
    component: WebHostingSolutionsComponent,
  },
  'Top Web Hosting Solutions Agency In Bilaspur Chhattisgarh': {
    component: WebHostingSolutionsComponent,
  },
  'Top Web Hosting Solutions Agency In Raipur': {
    component: WebHostingSolutionsComponent,
  },
  'Top Web Hosting Solutions Agency In Raipur Chhattisgarh': {
    component: WebHostingSolutionsComponent,
  },
  'Top Web Hosting Solutions Agency In Chhattisgarh': {
    component: WebHostingSolutionsComponent,
  },

  //Android App Development

  'Android App Development Company In Bilaspur': {
    component: AndroidAppDevelopmentComponent,
  },
  'Android App Development Company In Bilaspur Chhattisgarh': {
    component: AndroidAppDevelopmentComponent,
  },
  'Android App Development Company In Raipur': {
    component: AndroidAppDevelopmentComponent,
  },
  'Android App Development Company In Raipur Chhattisgarh': {
    component: AndroidAppDevelopmentComponent,
  },
  'Android App Development Company In Chhattisgarh': {
    component: AndroidAppDevelopmentComponent,
  },

  'Android App Development Agency In Bilaspur': {
    component: AndroidAppDevelopmentComponent,
  },
  'Android App Development Agency In Bilaspur Chhattisgarh': {
    component: AndroidAppDevelopmentComponent,
  },
  'Android App Development Agency In Raipur': {
    component: AndroidAppDevelopmentComponent,
  },
  'Android App Development Agency In Raipur Chhattisgarh': {
    component: AndroidAppDevelopmentComponent,
  },
  'Android App Development Agency In Chhattisgarh': {
    component: AndroidAppDevelopmentComponent,
  },

  'Best Android App Development Company In Bilaspur': {
    component: AndroidAppDevelopmentComponent,
  },
  'Best Android App Development Company In Bilaspur Chhattisgarh': {
    component: AndroidAppDevelopmentComponent,
  },
  'Best Android App Development Company In Raipur': {
    component: AndroidAppDevelopmentComponent,
  },
  'Best Android App Development Company In Raipur Chhattisgarh': {
    component: AndroidAppDevelopmentComponent,
  },
  'Best Android App Development Company In Chhattisgarh': {
    component: AndroidAppDevelopmentComponent,
  },
  'Best Android App Development Agency In Bilaspur': {
    component: AndroidAppDevelopmentComponent,
  },
  'Best Android App Development Agency In Bilaspur Chhattisgarh': {
    component: AndroidAppDevelopmentComponent,
  },
  'Best Android App Development Agency In Raipur': {
    component: AndroidAppDevelopmentComponent,
  },
  'Best Android App Development Agency In Raipur Chhattisgarh': {
    component: AndroidAppDevelopmentComponent,
  },
  'Best Android App Development Agency In Chhattisgarh': {
    component: AndroidAppDevelopmentComponent,
  },

  'Top Android App Development Company In Bilaspur': {
    component: AndroidAppDevelopmentComponent,
  },
  'Top Android App Development Company In Bilaspur Chhattisgarh': {
    component: AndroidAppDevelopmentComponent,
  },
  'Top Android App Development Company In Raipur': {
    component: AndroidAppDevelopmentComponent,
  },
  'Top Android App Development Company In Raipur Chhattisgarh': {
    component: AndroidAppDevelopmentComponent,
  },
  'Top Android App Development Company In Chhattisgarh': {
    component: AndroidAppDevelopmentComponent,
  },
  'Top Android App Development Agency In Bilaspur': {
    component: AndroidAppDevelopmentComponent,
  },
  'Top Android App Development Agency In Bilaspur Chhattisgarh': {
    component: AndroidAppDevelopmentComponent,
  },
  'Top Android App Development Agency In Raipur': {
    component: AndroidAppDevelopmentComponent,
  },
  'Top Android App Development Agency In Raipur Chhattisgarh': {
    component: AndroidAppDevelopmentComponent,
  },
  'Top Android App Development Agency In Chhattisgarh': {
    component: AndroidAppDevelopmentComponent,
  },

  // Ios App Development

  'Ios App Development Company In Bilaspur': {
    component: IosAppDevelopmentComponent,
  },
  'Ios App Development Company In Bilaspur Chhattisgarh': {
    component: IosAppDevelopmentComponent,
  },
  'Ios App Development Company In Raipur': {
    component: IosAppDevelopmentComponent,
  },
  'Ios App Development Company In Raipur Chhattisgarh': {
    component: IosAppDevelopmentComponent,
  },
  'Ios App Development Company In Chhattisgarh': {
    component: IosAppDevelopmentComponent,
  },

  'Ios App Development Agency In Bilaspur': {
    component: IosAppDevelopmentComponent,
  },
  'Ios App Development Agency In Bilaspur Chhattisgarh': {
    component: IosAppDevelopmentComponent,
  },
  'Ios App Development Agency In Raipur': {
    component: IosAppDevelopmentComponent,
  },
  'Ios App Development Agency In Raipur Chhattisgarh': {
    component: IosAppDevelopmentComponent,
  },
  'Ios App Development Agency In Chhattisgarh': {
    component: IosAppDevelopmentComponent,
  },

  'Best Ios App Development Company In Bilaspur': {
    component: IosAppDevelopmentComponent,
  },
  'Best Ios App Development Company In Bilaspur Chhattisgarh': {
    component: IosAppDevelopmentComponent,
  },
  'Best Ios App Development Company In Raipur': {
    component: IosAppDevelopmentComponent,
  },
  'Best Ios App Development Company In Raipur Chhattisgarh': {
    component: IosAppDevelopmentComponent,
  },
  'Best Ios App Development Company In Chhattisgarh': {
    component: IosAppDevelopmentComponent,
  },
  'Best Ios App Development Agency In Bilaspur': {
    component: IosAppDevelopmentComponent,
  },
  'Best Ios App Development Agency In Bilaspur Chhattisgarh': {
    component: IosAppDevelopmentComponent,
  },
  'Best Ios App Development Agency In Raipur': {
    component: IosAppDevelopmentComponent,
  },
  'Best Ios App Development Agency In Raipur Chhattisgarh': {
    component: IosAppDevelopmentComponent,
  },
  'Best Ios App Development Agency In Chhattisgarh': {
    component: IosAppDevelopmentComponent,
  },

  'Top Ios App Development Company In Bilaspur': {
    component: IosAppDevelopmentComponent,
  },
  'Top Ios App Development Company In Bilaspur Chhattisgarh': {
    component: IosAppDevelopmentComponent,
  },
  'Top Ios App Development Company In Raipur': {
    component: IosAppDevelopmentComponent,
  },
  'Top Ios App Development Company In Raipur Chhattisgarh': {
    component: IosAppDevelopmentComponent,
  },
  'Top Ios App Development Company In Chhattisgarh': {
    component: IosAppDevelopmentComponent,
  },
  'Top Ios App Development Agency In Bilaspur': {
    component: IosAppDevelopmentComponent,
  },
  'Top Ios App Development Agency In Bilaspur Chhattisgarh': {
    component: IosAppDevelopmentComponent,
  },
  'Top Ios App Development Agency In Raipur': {
    component: IosAppDevelopmentComponent,
  },
  'Top Ios App Development Agency In Raipur Chhattisgarh': {
    component: IosAppDevelopmentComponent,
  },
  'Top Ios App Development Agency In Chhattisgarh': {
    component: IosAppDevelopmentComponent,
  },

  //Hybrid-App-Development
  'Hybrid App Development Company In Bilaspur': {
    component: HybridAppDevelopmentComponent,
  },
  'Hybrid App Development Company In Bilaspur Chhattisgarh': {
    component: HybridAppDevelopmentComponent,
  },
  'Hybrid App Development Company In Raipur': {
    component: HybridAppDevelopmentComponent,
  },
  'Hybrid App Development Company In Raipur Chhattisgarh': {
    component: HybridAppDevelopmentComponent,
  },
  'Hybrid App Development Company In Chhattisgarh': {
    component: HybridAppDevelopmentComponent,
  },

  'Hybrid App Development Agency In Bilaspur': {
    component: HybridAppDevelopmentComponent,
  },
  'Hybrid App Development Agency In Bilaspur Chhattisgarh': {
    component: HybridAppDevelopmentComponent,
  },
  'Hybrid App Development Agency In Raipur': {
    component: HybridAppDevelopmentComponent,
  },
  'Hybrid App Development Agency In Raipur Chhattisgarh': {
    component: HybridAppDevelopmentComponent,
  },
  'Hybrid App Development Agency In Chhattisgarh': {
    component: HybridAppDevelopmentComponent,
  },

  'Best Hybrid App Development Company In Bilaspur': {
    component: HybridAppDevelopmentComponent,
  },
  'Best Hybrid App Development Company In Bilaspur Chhattisgarh': {
    component: HybridAppDevelopmentComponent,
  },
  'Best Hybrid App Development Company In Raipur': {
    component: HybridAppDevelopmentComponent,
  },
  'Best Hybrid App Development Company In Raipur Chhattisgarh': {
    component: HybridAppDevelopmentComponent,
  },
  'Best Hybrid App Development Company In Chhattisgarh': {
    component: HybridAppDevelopmentComponent,
  },
  'Best Hybrid App Development Agency In Bilaspur': {
    component: HybridAppDevelopmentComponent,
  },
  'Best Hybrid App Development Agency In Bilaspur Chhattisgarh': {
    component: HybridAppDevelopmentComponent,
  },
  'Best Hybrid App Development Agency In Raipur': {
    component: HybridAppDevelopmentComponent,
  },
  'Best Hybrid App Development Agency In Raipur Chhattisgarh': {
    component: HybridAppDevelopmentComponent,
  },
  'Best Hybrid App Development Agency In Chhattisgarh': {
    component: HybridAppDevelopmentComponent,
  },

  'Top Hybrid App Development Company In Bilaspur': {
    component: HybridAppDevelopmentComponent,
  },
  'Top Hybrid App Development Company In Bilaspur Chhattisgarh': {
    component: HybridAppDevelopmentComponent,
  },
  'Top Hybrid App Development Company In Raipur': {
    component: HybridAppDevelopmentComponent,
  },
  'Top Hybrid App Development Company In Raipur Chhattisgarh': {
    component: HybridAppDevelopmentComponent,
  },
  'Top Hybrid App Development Company In Chhattisgarh': {
    component: HybridAppDevelopmentComponent,
  },
  'Top Hybrid App Development Agency In Bilaspur': {
    component: HybridAppDevelopmentComponent,
  },
  'Top Hybrid App Development Agency In Bilaspur Chhattisgarh': {
    component: HybridAppDevelopmentComponent,
  },
  'Top Hybrid App Development Agency In Raipur': {
    component: HybridAppDevelopmentComponent,
  },
  'Top Hybrid App Development Agency In Raipur Chhattisgarh': {
    component: HybridAppDevelopmentComponent,
  },
  'Top Hybrid App Development Agency In Chhattisgarh': {
    component: HybridAppDevelopmentComponent,
  },

  //Native App Development

  'Native App Development Company In Bilaspur': {
    component: NativeAppDevelopmentComponent,
  },
  'Native App Development Company In Bilaspur Chhattisgarh': {
    component: NativeAppDevelopmentComponent,
  },
  'Native App Development Company In Raipur': {
    component: NativeAppDevelopmentComponent,
  },
  'Native App Development Company In Raipur Chhattisgarh': {
    component: NativeAppDevelopmentComponent,
  },
  'Native App Development Company In Chhattisgarh': {
    component: NativeAppDevelopmentComponent,
  },

  'Native App Development Agency In Bilaspur': {
    component: NativeAppDevelopmentComponent,
  },
  'Native App Development Agency In Bilaspur Chhattisgarh': {
    component: NativeAppDevelopmentComponent,
  },
  'Native App Development Agency In Raipur': {
    component: NativeAppDevelopmentComponent,
  },
  'Native App Development Agency In Raipur Chhattisgarh': {
    component: NativeAppDevelopmentComponent,
  },
  'Native App Development Agency In Chhattisgarh': {
    component: NativeAppDevelopmentComponent,
  },

  'Best Native App Development Company In Bilaspur': {
    component: NativeAppDevelopmentComponent,
  },
  'Best Native App Development Company In Bilaspur Chhattisgarh': {
    component: NativeAppDevelopmentComponent,
  },
  'Best Native App Development Company In Raipur': {
    component: NativeAppDevelopmentComponent,
  },
  'Best Native App Development Company In Raipur Chhattisgarh': {
    component: NativeAppDevelopmentComponent,
  },
  'Best Native App Development Company In Chhattisgarh': {
    component: NativeAppDevelopmentComponent,
  },
  'Best Native App Development Agency In Bilaspur': {
    component: NativeAppDevelopmentComponent,
  },
  'Best Native App Development Agency In Bilaspur Chhattisgarh': {
    component: NativeAppDevelopmentComponent,
  },
  'Best Native App Development Agency In Raipur': {
    component: NativeAppDevelopmentComponent,
  },
  'Best Native App Development Agency In Raipur Chhattisgarh': {
    component: NativeAppDevelopmentComponent,
  },
  'Best Native App Development Agency In Chhattisgarh': {
    component: NativeAppDevelopmentComponent,
  },

  'Top Native App Development Company In Bilaspur': {
    component: NativeAppDevelopmentComponent,
  },
  'Top Native App Development Company In Bilaspur Chhattisgarh': {
    component: NativeAppDevelopmentComponent,
  },
  'Top Native App Development Company In Raipur': {
    component: NativeAppDevelopmentComponent,
  },
  'Top Native App Development Company In Raipur Chhattisgarh': {
    component: NativeAppDevelopmentComponent,
  },
  'Top Native App Development Company In Chhattisgarh': {
    component: NativeAppDevelopmentComponent,
  },
  'Top Native App Development Agency In Bilaspur': {
    component: NativeAppDevelopmentComponent,
  },
  'Top Native App Development Agency In Bilaspur Chhattisgarh': {
    component: NativeAppDevelopmentComponent,
  },
  'Top Native App Development Agency In Raipur': {
    component: NativeAppDevelopmentComponent,
  },
  'Top Native App Development Agency In Raipur Chhattisgarh': {
    component: NativeAppDevelopmentComponent,
  },
  'Top Native App Development Agency In Chhattisgarh': {
    component: NativeAppDevelopmentComponent,
  },

  //search engine optimization

  'Search Engine Optimisation Company In Bilaspur': {
    component: SearchEngineOptimizationComponent,
  },
  'Search Engine Optimisation Company In Bilaspur Chhattisgarh': {
    component: SearchEngineOptimizationComponent,
  },
  'Search Engine Optimisation Company In Raipur': {
    component: SearchEngineOptimizationComponent,
  },
  'Search Engine Optimisation Company In Raipur Chhattisgarh': {
    component: SearchEngineOptimizationComponent,
  },
  'Search Engine Optimisation Company In Chhattisgarh': {
    component: SearchEngineOptimizationComponent,
  },

  'Search Engine Optimisation Agency In Bilaspur': {
    component: SearchEngineOptimizationComponent,
  },
  'Search Engine Optimisation Agency In Bilaspur Chhattisgarh': {
    component: SearchEngineOptimizationComponent,
  },
  'Search Engine Optimisation Agency In Raipur': {
    component: SearchEngineOptimizationComponent,
  },
  'Search Engine Optimisation Agency In Raipur Chhattisgarh': {
    component: SearchEngineOptimizationComponent,
  },
  'Search Engine Optimisation Agency In Chhattisgarh': {
    component: SearchEngineOptimizationComponent,
  },

  'Best Search Engine Optimisation Company In Bilaspur': {
    component: SearchEngineOptimizationComponent,
  },
  'Best Search Engine Optimisation Company In Bilaspur Chhattisgarh': {
    component: SearchEngineOptimizationComponent,
  },
  'Best Search Engine Optimisation Company In Raipur': {
    component: SearchEngineOptimizationComponent,
  },
  'Best Search Engine Optimisation Company In Raipur Chhattisgarh': {
    component: SearchEngineOptimizationComponent,
  },
  'Best Search Engine Optimisation Company In Chhattisgarh': {
    component: SearchEngineOptimizationComponent,
  },
  'Best Search Engine Optimisation Agency In Bilaspur': {
    component: SearchEngineOptimizationComponent,
  },
  'Best Search Engine Optimisation Agency In Bilaspur Chhattisgarh': {
    component: SearchEngineOptimizationComponent,
  },
  'Best Search Engine Optimisation Agency In Raipur': {
    component: SearchEngineOptimizationComponent,
  },
  'Best Search Engine Optimisation Agency In Raipur Chhattisgarh': {
    component: SearchEngineOptimizationComponent,
  },
  'Best Search Engine Optimisation Agency In Chhattisgarh': {
    component: SearchEngineOptimizationComponent,
  },

  'Top Search Engine Optimisation Company In Bilaspur': {
    component: SearchEngineOptimizationComponent,
  },
  'Top Search Engine Optimisation Company In Bilaspur Chhattisgarh': {
    component: SearchEngineOptimizationComponent,
  },
  'Top Search Engine Optimisation Company In Raipur': {
    component: SearchEngineOptimizationComponent,
  },
  'Top Search Engine Optimisation Company In Raipur Chhattisgarh': {
    component: SearchEngineOptimizationComponent,
  },
  'Top Search Engine Optimisation Company In Chhattisgarh': {
    component: SearchEngineOptimizationComponent,
  },
  'Top Search Engine Optimisation Agency In Bilaspur': {
    component: SearchEngineOptimizationComponent,
  },
  'Top Search Engine Optimisation Agency In Bilaspur Chhattisgarh': {
    component: SearchEngineOptimizationComponent,
  },
  'Top Search Engine Optimisation Agency In Raipur': {
    component: SearchEngineOptimizationComponent,
  },
  'Top Search Engine Optimisation Agency In Raipur Chhattisgarh': {
    component: SearchEngineOptimizationComponent,
  },
  'Top search Engine Optimisation Agency In Chhattisgarh': {
    component: SearchEngineOptimizationComponent,
  },

  //Digital media marketing

  'Digital Media Marketing Company In Bilaspur': {
    component: DigitalMediaMarketingComponent,
  },
  'Digital Media Marketing Company In Bilaspur Chhattisgarh': {
    component: DigitalMediaMarketingComponent,
  },
  'Digital Media Marketing Company In Raipur': {
    component: DigitalMediaMarketingComponent,
  },
  'Digital Media Marketing Company In Raipur Chhattisgarh': {
    component: DigitalMediaMarketingComponent,
  },
  'Digital Media Marketing Company In Chhattisgarh': {
    component: DigitalMediaMarketingComponent,
  },

  'Digital Media Marketing Agency In Bilaspur': {
    component: DigitalMediaMarketingComponent,
  },
  'Digital Media Marketing Agency In Bilaspur Chhattisgarh': {
    component: DigitalMediaMarketingComponent,
  },
  'Digital Media Marketing Agency In Raipur': {
    component: DigitalMediaMarketingComponent,
  },
  'Digital Media Marketing Agency In Raipur Chhattisgarh': {
    component: DigitalMediaMarketingComponent,
  },
  'Digital Media Marketing Agency In Chhattisgarh': {
    component: DigitalMediaMarketingComponent,
  },

  'Best Digital Media Marketing Company In Bilaspur': {
    component: DigitalMediaMarketingComponent,
  },
  'Best Digital Media Marketing Company In Bilaspur Chhattisgarh': {
    component: DigitalMediaMarketingComponent,
  },
  'Best Digital Media Marketing Company In Raipur': {
    component: DigitalMediaMarketingComponent,
  },
  'Best Digital Media Marketing Company In Raipur Chhattisgarh': {
    component: DigitalMediaMarketingComponent,
  },
  'Best Digital Media Marketing Company In Chhattisgarh': {
    component: DigitalMediaMarketingComponent,
  },
  'Best Digital Media Marketing Agency In Bilaspur': {
    component: DigitalMediaMarketingComponent,
  },
  'Best Digital Media Marketing Agency In Bilaspur Chhattisgarh': {
    component: DigitalMediaMarketingComponent,
  },
  'Best Digital Media Marketing Agency In Raipur': {
    component: DigitalMediaMarketingComponent,
  },
  'Best Digital Media Marketing Agency In Raipur Chhattisgarh': {
    component: DigitalMediaMarketingComponent,
  },
  'Best Digital Media Marketing Agency In Chhattisgarh': {
    component: DigitalMediaMarketingComponent,
  },

  'Top Digital Media Marketing Company In Bilaspur': {
    component: DigitalMediaMarketingComponent,
  },
  'Top Digital Media Marketing Company In Bilaspur Chhattisgarh': {
    component: DigitalMediaMarketingComponent,
  },
  'Top Digital Media Marketing Company In Raipur': {
    component: DigitalMediaMarketingComponent,
  },
  'Top Digital Media Marketing Company In Raipur Chhattisgarh': {
    component: DigitalMediaMarketingComponent,
  },
  'Top Digital Media Marketing Company In Chhattisgarh': {
    component: DigitalMediaMarketingComponent,
  },
  'Top Digital Media Marketing Agency In Bilaspur': {
    component: DigitalMediaMarketingComponent,
  },
  'Top Digital Media Marketing Agency In Bilaspur Chhattisgarh': {
    component: DigitalMediaMarketingComponent,
  },
  'Top Digital Media Marketing Agency In Raipur': {
    component: DigitalMediaMarketingComponent,
  },
  'Top Digital Media Marketing Agency In Raipur Chhattisgarh': {
    component: DigitalMediaMarketingComponent,
  },
  'Top Digital Media Marketing Agency In Chhattisgarh': {
    component: DigitalMediaMarketingComponent,
  },

  //Social media promotion

  'Social Media Promotion Company In Bilaspur': {
    component: SocialMediaPromotionComponent,
  },
  'Social Media Promotion Company In Bilaspur Chhattisgarh': {
    component: SocialMediaPromotionComponent,
  },
  'Social Media Promotion Company In Raipur': {
    component: SocialMediaPromotionComponent,
  },
  'Social Media Promotion Company In Raipur Chhattisgarh': {
    component: SocialMediaPromotionComponent,
  },
  'Social Media Promotion Company In Chhattisgarh': {
    component: SocialMediaPromotionComponent,
  },

  'Social Media Promotion Agency In Bilaspur': {
    component: SocialMediaPromotionComponent,
  },
  'Social Media Promotion Agency In Bilaspur Chhattisgarh': {
    component: SocialMediaPromotionComponent,
  },
  'Social Media Promotion Agency In Raipur': {
    component: SocialMediaPromotionComponent,
  },
  'Social Media Promotion Agency In Raipur Chhattisgarh': {
    component: SocialMediaPromotionComponent,
  },
  'Social Media Promotion Agency In Chhattisgarh': {
    component: SocialMediaPromotionComponent,
  },

  'Best Social Media Promotion Company In Bilaspur': {
    component: SocialMediaPromotionComponent,
  },
  'Best Social Media Promotion Company In Bilaspur Chhattisgarh': {
    component: SocialMediaPromotionComponent,
  },
  'Best Social Media Promotion Company In Raipur': {
    component: SocialMediaPromotionComponent,
  },
  'Best Social Media Promotion Company In Raipur Chhattisgarh': {
    component: SocialMediaPromotionComponent,
  },
  'Best Social Media Promotion Company In Chhattisgarh': {
    component: SocialMediaPromotionComponent,
  },
  'Best Social Media Promotion Agency In Bilaspur': {
    component: SocialMediaPromotionComponent,
  },
  'Best Social Media Promotion Agency In Bilaspur Chhattisgarh': {
    component: SocialMediaPromotionComponent,
  },
  'Best Social Media Promotion Agency In Raipur': {
    component: SocialMediaPromotionComponent,
  },
  'Best Social Media Promotion Agency In Raipur Chhattisgarh': {
    component: SocialMediaPromotionComponent,
  },
  'Best Social Media Promotion Agency In Chhattisgarh': {
    component: SocialMediaPromotionComponent,
  },

  'Top Social Media Promotion Company In Bilaspur': {
    component: SocialMediaPromotionComponent,
  },
  'Top Social Media Promotion Company In Bilaspur Chhattisgarh': {
    component: SocialMediaPromotionComponent,
  },
  'Top Social Media Promotion Company In Raipur': {
    component: SocialMediaPromotionComponent,
  },
  'Top Social Media Promotion Company In Raipur Chhattisgarh': {
    component: SocialMediaPromotionComponent,
  },
  'Top Social Media Promotion Company In Chhattisgarh': {
    component: SocialMediaPromotionComponent,
  },
  'Top Social Media Promotion Agency In Bilaspur': {
    component: SocialMediaPromotionComponent,
  },
  'Top Social Media Promotion Agency In Bilaspur Chhattisgarh': {
    component: SocialMediaPromotionComponent,
  },
  'Top Social Media Promotion Agency In Raipur': {
    component: SocialMediaPromotionComponent,
  },
  'Top Social Media Promotion Agency In Raipur Chhattisgarh': {
    component: SocialMediaPromotionComponent,
  },
  'Top Social Media Promotion Agency In Chhattisgarh': {
    component: SocialMediaPromotionComponent,
  },

  //Bulk sms email And email marketing

  'Bulk Sms And Email Marketing Company In Bilaspur': {
    component: BulkSmsAndEmailMarketingComponent,
  },
  'Bulk Sms And Email Marketing Company In Bilaspur Chhattisgarh': {
    component: BulkSmsAndEmailMarketingComponent,
  },
  'Bulk Sms And Email Marketing Company In Raipur': {
    component: BulkSmsAndEmailMarketingComponent,
  },
  'Bulk Sms And Email Marketing Company In Raipur Chhattisgarh': {
    component: BulkSmsAndEmailMarketingComponent,
  },
  'Bulk Sms And Email Marketing Company In Chhattisgarh': {
    component: BulkSmsAndEmailMarketingComponent,
  },

  'Bulk Sms And Email Marketing Agency In Bilaspur': {
    component: BulkSmsAndEmailMarketingComponent,
  },
  'Bulk Sms And Email Marketing Agency In Bilaspur Chhattisgarh': {
    component: BulkSmsAndEmailMarketingComponent,
  },
  'Bulk Sms And Email Marketing Agency In Raipur': {
    component: BulkSmsAndEmailMarketingComponent,
  },
  'Bulk Sms And Email Marketing Agency In Raipur Chhattisgarh': {
    component: BulkSmsAndEmailMarketingComponent,
  },
  'Bulk Sms And Email Marketing Agency In Chhattisgarh': {
    component: BulkSmsAndEmailMarketingComponent,
  },

  'Best Bulk Sms And Email Marketing Company In Bilaspur': {
    component: BulkSmsAndEmailMarketingComponent,
  },
  'Best Bulk Sms And Email Marketing Company In Bilaspur Chhattisgarh': {
    component: BulkSmsAndEmailMarketingComponent,
  },
  'Best Bulk Sms And Email Marketing Company In Raipur': {
    component: BulkSmsAndEmailMarketingComponent,
  },
  'Best Bulk Sms And Email Marketing Company In Raipur Chhattisgarh': {
    component: BulkSmsAndEmailMarketingComponent,
  },
  'Best Bulk Sms And Email Marketing Company In Chhattisgarh': {
    component: BulkSmsAndEmailMarketingComponent,
  },
  'Best Bulk Sms And Email Marketing Agency In Bilaspur': {
    component: BulkSmsAndEmailMarketingComponent,
  },
  'Best Bulk Sms And Email Marketing Agency In Bilaspur Chhattisgarh': {
    component: BulkSmsAndEmailMarketingComponent,
  },
  'Best Bulk Sms And Email Marketing Agency In Raipur': {
    component: BulkSmsAndEmailMarketingComponent,
  },
  'Best Bulk Sms And Email Marketing Agency In Raipur Chhattisgarh': {
    component: BulkSmsAndEmailMarketingComponent,
  },
  'Best Bulk Sms And Email Marketing Agency In Chhattisgarh': {
    component: BulkSmsAndEmailMarketingComponent,
  },

  'Top Bulk Sms And Email Marketing Company In Bilaspur': {
    component: BulkSmsAndEmailMarketingComponent,
  },
  'Top Bulk Sms And Email Marketing Company In Bilaspur Chhattisgarh': {
    component: BulkSmsAndEmailMarketingComponent,
  },
  'Top Bulk Sms And Email Marketing Company In Raipur': {
    component: BulkSmsAndEmailMarketingComponent,
  },
  'Top Bulk Sms And Email Marketing Company In Raipur Chhattisgarh': {
    component: BulkSmsAndEmailMarketingComponent,
  },
  'Top Bulk Sms And Email Marketing Company In Chhattisgarh': {
    component: BulkSmsAndEmailMarketingComponent,
  },
  'Top Bulk Sms And Email Marketing Agency In Bilaspur': {
    component: BulkSmsAndEmailMarketingComponent,
  },
  'Top Bulk Sms And Email Marketing Agency In Bilaspur Chhattisgarh': {
    component: BulkSmsAndEmailMarketingComponent,
  },
  'Top Bulk Sms And Email Marketing Agency In Raipur': {
    component: BulkSmsAndEmailMarketingComponent,
  },
  'Top Bulk Sms And Email Marketing Agency In Raipur Chhattisgarh': {
    component: BulkSmsAndEmailMarketingComponent,
  },
  'Top Bulk Sms And Email Marketing Agency In Chhattisgarh': {
    component: BulkSmsAndEmailMarketingComponent,
  },

  //Logo design

  'Logo Design Company In Bilaspur': { component: LogoDesignComponent },
  'Logo Design Company In Bilaspur Chhattisgarh': {
    component: LogoDesignComponent,
  },
  'Logo Design Company In Raipur': { component: LogoDesignComponent },
  'Logo Design Company In Raipur Chhattisgarh': {
    component: LogoDesignComponent,
  },
  'Logo Design Company In Chhattisgarh': { component: LogoDesignComponent },

  'Logo Design Agency In Bilaspur': { component: LogoDesignComponent },
  'Logo Design Agency In Bilaspur Chhattisgarh': {
    component: LogoDesignComponent,
  },
  'Logo Design Agency In Raipur': { component: LogoDesignComponent },
  'Logo Design Agency In Raipur Chhattisgarh': {
    component: LogoDesignComponent,
  },
  'Logo Design Agency In Chhattisgarh': { component: LogoDesignComponent },

  'Best Logo Design Company In Bilaspur': { component: LogoDesignComponent },
  'Best Logo Design Company In Bilaspur Chhattisgarh': {
    component: LogoDesignComponent,
  },
  'Best Logo Design Company In Raipur': { component: LogoDesignComponent },
  'Best Logo Design Company In Raipur Chhattisgarh': {
    component: LogoDesignComponent,
  },
  'Best Logo Design Company In Chhattisgarh': {
    component: LogoDesignComponent,
  },
  'Best Logo Design Agency In Bilaspur': { component: LogoDesignComponent },
  'Best Logo Design Agency In Bilaspur Chhattisgarh': {
    component: LogoDesignComponent,
  },
  'Best Logo Design Agency In Raipur': { component: LogoDesignComponent },
  'Best Logo Design Agency In Raipur Chhattisgarh': {
    component: LogoDesignComponent,
  },
  'Best Logo Design Agency In Chhattisgarh': { component: LogoDesignComponent },

  'Top Logo Design Company In Bilaspur': { component: LogoDesignComponent },
  'Top Logo Design Company In Bilaspur Chhattisgarh': {
    component: LogoDesignComponent,
  },
  'Top Logo Design Company In Raipur': { component: LogoDesignComponent },
  'Top Logo Design Company In Raipur Chhattisgarh': {
    component: LogoDesignComponent,
  },
  'Top Logo Design Company In Chhattisgarh': { component: LogoDesignComponent },
  'Top Logo Design Agency In Bilaspur': { component: LogoDesignComponent },
  'Top Logo Design Agency In Bilaspur Chhattisgarh': {
    component: LogoDesignComponent,
  },
  'Top Logo Design Agency In Raipur': { component: LogoDesignComponent },
  'Top Logo Design Agency In Raipur Chhattisgarh': {
    component: LogoDesignComponent,
  },
  'Top Logo Design Agency In Chhattisgarh': { component: LogoDesignComponent },

  //Graphics design

  'Graphic Design Company In Bilaspur': { component: GraphicDesignComponent },
  'Graphic Design Company In Bilaspur Chhattisgarh': {
    component: GraphicDesignComponent,
  },
  'Graphic Design Company In Raipur': { component: GraphicDesignComponent },
  'Graphic Design Company In Raipur Chhattisgarh': {
    component: GraphicDesignComponent,
  },
  'Graphic Design Company In Chhattisgarh': {
    component: GraphicDesignComponent,
  },

  'Graphic Design Agency In Bilaspur': { component: GraphicDesignComponent },
  'Graphic Design Agency In Bilaspur Chhattisgarh': {
    component: GraphicDesignComponent,
  },
  'Graphic Design Agency In Raipur': { component: GraphicDesignComponent },
  'Graphic Design Agency In Raipur Chhattisgarh': {
    component: GraphicDesignComponent,
  },
  'Graphic Design Agency In Chhattisgarh': {
    component: GraphicDesignComponent,
  },

  'Best Graphic Design Company In Bilaspur': {
    component: GraphicDesignComponent,
  },
  'Best Graphic Design Company In Bilaspur Chhattisgarh': {
    component: GraphicDesignComponent,
  },
  'Best Graphic Design Company In Raipur': {
    component: GraphicDesignComponent,
  },
  'Best Graphic Design Company In Raipur Chhattisgarh': {
    component: GraphicDesignComponent,
  },
  'Best Graphic Design Company In Chhattisgarh': {
    component: GraphicDesignComponent,
  },
  'Best Graphic Design Agency In Bilaspur': {
    component: GraphicDesignComponent,
  },
  'Best Graphic Design Agency In Bilaspur Chhattisgarh': {
    component: GraphicDesignComponent,
  },
  'Best Graphic Design Agency In Raipur': { component: GraphicDesignComponent },
  'Best Graphic Design Agency In Raipur Chhattisgarh': {
    component: GraphicDesignComponent,
  },
  'Best Graphic Design Agency In Chhattisgarh': {
    component: GraphicDesignComponent,
  },

  'Top Graphic Design Company In Bilaspur': {
    component: GraphicDesignComponent,
  },
  'Top Graphic Design Company In Bilaspur Chhattisgarh': {
    component: GraphicDesignComponent,
  },
  'Top Graphic Design Company In Raipur': { component: GraphicDesignComponent },
  'Top Graphic Design Company In Raipur Chhattisgarh': {
    component: GraphicDesignComponent,
  },
  'Top Graphic Design Company In Chhattisgarh': {
    component: GraphicDesignComponent,
  },
  'Top Graphic Design Agency In Bilaspur': {
    component: GraphicDesignComponent,
  },
  'Top Graphic Design Agency In Bilaspur Chhattisgarh': {
    component: GraphicDesignComponent,
  },
  'Top Graphic Design Agency In Raipur': { component: GraphicDesignComponent },
  'Top Graphic Design Agency In Raipur Chhattisgarh': {
    component: GraphicDesignComponent,
  },
  'Top Graphic Design Agency In Chhattisgarh': {
    component: GraphicDesignComponent,
  },

  //Video Creation

  'Digital Visiting Card Company In Bilaspur': {
    component: DigitalVisitingCardComponent,
  },
  'Digital Visiting Card Company In Bilaspur Chhattisgarh': {
    component: DigitalVisitingCardComponent,
  },
  'Digital Visiting Card Company In Raipur': {
    component: DigitalVisitingCardComponent,
  },
  'Digital Visiting Card Company In Raipur Chhattisgarh': {
    component: DigitalVisitingCardComponent,
  },
  'Digital Visiting Card Company In Chhattisgarh': {
    component: DigitalVisitingCardComponent,
  },

  'Digital Visiting Card Agency In Bilaspur': {
    component: DigitalVisitingCardComponent,
  },
  'Digital Visiting Card Agency In Bilaspur Chhattisgarh': {
    component: DigitalVisitingCardComponent,
  },
  'Digital Visiting Card Agency In Raipur': {
    component: DigitalVisitingCardComponent,
  },
  'Digital Visiting Card Agency In Raipur Chhattisgarh': {
    component: DigitalVisitingCardComponent,
  },
  'Digital Visiting Card Agency In Chhattisgarh': {
    component: DigitalVisitingCardComponent,
  },

  'Best Digital Visiting Card Company In Bilaspur': {
    component: DigitalVisitingCardComponent,
  },
  'Best Digital Visiting Card Company In Bilaspur Chhattisgarh': {
    component: DigitalVisitingCardComponent,
  },
  'Best Digital Visiting Card Company In Raipur': {
    component: DigitalVisitingCardComponent,
  },
  'Best Digital Visiting Card Company In Raipur Chhattisgarh': {
    component: DigitalVisitingCardComponent,
  },
  'Best Digital Visiting Card Company In Chhattisgarh': {
    component: DigitalVisitingCardComponent,
  },
  'Best Digital Visiting Card Agency In Bilaspur': {
    component: DigitalVisitingCardComponent,
  },
  'Best Digital Visiting Card Agency In Bilaspur Chhattisgarh': {
    component: DigitalVisitingCardComponent,
  },
  'Best Digital Visiting Card Agency In Raipur': {
    component: DigitalVisitingCardComponent,
  },
  'Best Digital Visiting Card Agency In Raipur Chhattisgarh': {
    component: DigitalVisitingCardComponent,
  },
  'Best Digital Visiting Card Agency In Chhattisgarh': {
    component: DigitalVisitingCardComponent,
  },

  'Top Digital Visiting Card Company In Bilaspur': {
    component: DigitalVisitingCardComponent,
  },
  'Top Digital Visiting Card Company In Bilaspur Chhattisgarh': {
    component: DigitalVisitingCardComponent,
  },
  'Top Digital Visiting Card Company In Raipur': {
    component: DigitalVisitingCardComponent,
  },
  'Top Digital Visiting Card Company In Raipur Chhattisgarh': {
    component: DigitalVisitingCardComponent,
  },
  'Top Digital Visiting Card Company In Chhattisgarh': {
    component: DigitalVisitingCardComponent,
  },
  'Top Digital Visiting Card Agency In Bilaspur': {
    component: DigitalVisitingCardComponent,
  },
  'Top Digital Visiting Card Agency In Bilaspur Chhattisgarh': {
    component: DigitalVisitingCardComponent,
  },
  'Top Digital Visiting Card Agency In Raipur': {
    component: DigitalVisitingCardComponent,
  },
  'Top Digital Visiting Card Agency In Raipur Chhattisgarh': {
    component: DigitalVisitingCardComponent,
  },
  'Top Digital Visiting Card Agency In Chhattisgarh': {
    component: DigitalVisitingCardComponent,
  },

  //Video Creation

  'Video Creation Company In Bilaspur': { component: VideoCreationComponent },
  'Video Creation Company In Bilaspur Chhattisgarh': {
    component: VideoCreationComponent,
  },
  'Video Creation Company In Raipur': { component: VideoCreationComponent },
  'Video Creation Company In Raipur Chhattisgarh': {
    component: VideoCreationComponent,
  },
  'Video Creation Company In Chhattisgarh': {
    component: VideoCreationComponent,
  },

  'Video Creation Agency In Bilaspur': { component: VideoCreationComponent },
  'Video Creation Agency In Bilaspur Chhattisgarh': {
    component: VideoCreationComponent,
  },
  'Video Creation Agency In Raipur': { component: VideoCreationComponent },
  'Video Creation Agency In Raipur Chhattisgarh': {
    component: VideoCreationComponent,
  },
  'Video Creation Agency In Chhattisgarh': {
    component: VideoCreationComponent,
  },

  'Best Video Creation Company In Bilaspur': {
    component: VideoCreationComponent,
  },
  'Best Video Creation Company In Bilaspur Chhattisgarh': {
    component: VideoCreationComponent,
  },
  'Best Video Creation Company In Raipur': {
    component: VideoCreationComponent,
  },
  'Best Video Creation Company In Raipur Chhattisgarh': {
    component: VideoCreationComponent,
  },
  'Best Video Creation Company In Chhattisgarh': {
    component: VideoCreationComponent,
  },
  'Best Video Creation Agency In Bilaspur': {
    component: VideoCreationComponent,
  },
  'Best Video Creation Agency In Bilaspur Chhattisgarh': {
    component: VideoCreationComponent,
  },
  'Best Video Creation Agency In Raipur': { component: VideoCreationComponent },
  'Best Video Creation Agency In Raipur Chhattisgarh': {
    component: VideoCreationComponent,
  },
  'Best Video Creation Agency In Chhattisgarh': {
    component: VideoCreationComponent,
  },

  'Top Video Creation Company In Bilaspur': {
    component: VideoCreationComponent,
  },
  'Top Video Creation Company In Bilaspur Chhattisgarh': {
    component: VideoCreationComponent,
  },
  'Top Video Creation Company In Raipur': { component: VideoCreationComponent },
  'Top Video Creation Company In Raipur Chhattisgarh': {
    component: VideoCreationComponent,
  },
  'Top Video Creation Company In Chhattisgarh': {
    component: VideoCreationComponent,
  },
  'Top Video Creation Agency In Bilaspur': {
    component: VideoCreationComponent,
  },
  'Top Video Creation Agency In Bilaspur Chhattisgarh': {
    component: VideoCreationComponent,
  },
  'Top Video Creation Agency In Raipur': { component: VideoCreationComponent },
  'Top Video Creation Agency In Raipur Chhattisgarh': {
    component: VideoCreationComponent,
  },
  'Top Video Creation Agency In Chhattisgarh': {
    component: VideoCreationComponent,
  },
};

export const internshipRouting: { [k: string]: { component: any } } = {
  'training': { component: InternshipComponent },
  'internship': { component: InternshipComponent },

  'Angular Internship': { component: InternshipComponent },
  'Angular Internship Near Me': { component: InternshipComponent },
  'Angular Internship In Bilaspur': { component: InternshipComponent },
  'Angular Internship In Raipur': { component: InternshipComponent },
  'Angular Internship In Chhattisgarh': { component: InternshipComponent },

  'Angular Training': { component: InternshipComponent },
  'Angular Training Near Me': { component: InternshipComponent },
  'Angular Training In Bilaspur': { component: InternshipComponent },
  'Angular Training In Raipur': { component: InternshipComponent },
  'Angular Training In Chhattisgarh': { component: InternshipComponent },

  'Designing Internship': { component: InternshipComponent },
  'Designing Internship Near Me': { component: InternshipComponent },
  'Designing Internship In Bilaspur': { component: InternshipComponent },
  'Designing Internship In Raipur': { component: InternshipComponent },
  'Designing Internship In Chhattisgarh': { component: InternshipComponent },

  'Designing Training': { component: InternshipComponent },
  'Designing Training Near Me': { component: InternshipComponent },
  'Designing Training In Bilaspur': { component: InternshipComponent },
  'Designing Training In Raipur': { component: InternshipComponent },
  'Designing Training In Chhattisgarh': { component: InternshipComponent },

  'Dotnet Internship': { component: InternshipComponent },
  'Dotnet Internship Near Me': { component: InternshipComponent },
  'Dotnet Internship In Bilaspur': { component: InternshipComponent },
  'Dotnet Internship In Raipur': { component: InternshipComponent },
  'Dotnet Internship In Chhattisgarh': { component: InternshipComponent },

  'Dotnet Training': { component: InternshipComponent },
  'Dotnet Training Near Me': { component: InternshipComponent },
  'Dotnet Training In Bilaspur': { component: InternshipComponent },
  'Dotnet Training In Raipur': { component: InternshipComponent },
  'Dotnet Training In Chhattisgarh': { component: InternshipComponent },

  'Nodejs Internship': { component: InternshipComponent },
  'Nodejs Internship Near Me': { component: InternshipComponent },
  'Nodejs Internship In Bilaspur': { component: InternshipComponent },
  'Nodejs Internship In Raipur': { component: InternshipComponent },
  'Nodejs Internship In Chhattisgarh': { component: InternshipComponent },

  'Nodejs Training': { component: InternshipComponent },
  'Nodejs Training Near Me': { component: InternshipComponent },
  'Nodejs Training In Bilaspur': { component: InternshipComponent },
  'Nodejs Training In Raipur': { component: InternshipComponent },
  'Nodejs Training In Chhattisgarh': { component: InternshipComponent },

  'React Native Internship': { component: InternshipComponent },
  'React Native Internship Near Me': { component: InternshipComponent },
  'React Native Internship In Bilaspur': { component: InternshipComponent },
  'React Native Internship In Raipur': { component: InternshipComponent },
  'React Native Internship In Chhattisgarh': { component: InternshipComponent },

  'React Native Training': { component: InternshipComponent },
  'React Native Training Near Me': { component: InternshipComponent },
  'React Native Training In Bilaspur': { component: InternshipComponent },
  'React Native Training In Raipur': { component: InternshipComponent },
  'React Native Training In Chhattisgarh': { component: InternshipComponent },

  'Sql Internship': { component: InternshipComponent },
  'Sql Internship Near Me': { component: InternshipComponent },
  'Sql Internship In Bilaspur': { component: InternshipComponent },
  'Sql Internship In Raipur': { component: InternshipComponent },
  'Sql Internship In Chhattisgarh': { component: InternshipComponent },

  'Sql Training': { component: InternshipComponent },
  'Sql Training Near Me': { component: InternshipComponent },
  'Sql Training In Bilaspur': { component: InternshipComponent },
  'Sql Training In Raipur': { component: InternshipComponent },
  'Sql Training In Chhattisgarh': { component: InternshipComponent },

  'Testing Internship': { component: InternshipComponent },
  'Testing Internship Near Me': { component: InternshipComponent },
  'Testing Internship In Bilaspur': { component: InternshipComponent },
  'Testing Internship In Raipur': { component: InternshipComponent },
  'Testing Internship In Chhattisgarh': { component: InternshipComponent },

  'Testing Training': { component: InternshipComponent },
  'Testing Training Near Me': { component: InternshipComponent },
  'Testing Training In Bilaspur': { component: InternshipComponent },
  'Testing Training In Raipur': { component: InternshipComponent },
  'Testing Training In Chhattisgarh': { component: InternshipComponent },

  'Figma Internship': { component: InternshipComponent },
  'Figma Internship Near Me': { component: InternshipComponent },
  'Figma Internship In Bilaspur': { component: InternshipComponent },
  'Figma Internship In Raipur': { component: InternshipComponent },
  'Figma Internship In Chhattisgarh': { component: InternshipComponent },

  'Figma Training': { component: InternshipComponent },
  'Figma Training Near Me': { component: InternshipComponent },
  'Figma Training In Bilaspur': { component: InternshipComponent },
  'Figma Training In Raipur': { component: InternshipComponent },
  'Figma Training In Chhattisgarh': { component: InternshipComponent },

  'Graphic Design Internship': { component: InternshipComponent },
  'Graphic Design Internship Near Me': { component: InternshipComponent },
  'Graphic Design Internship In Bilaspur': { component: InternshipComponent },
  'Graphic Design Internship In Raipur': { component: InternshipComponent },
  'Graphic Design Internship In Chhattisgarh': { component: InternshipComponent },

  'Graphic Design Training': { component: InternshipComponent },
  'Graphic Design Training Near Me': { component: InternshipComponent },
  'Graphic Design Training In Bilaspur': { component: InternshipComponent },
  'Graphic Design Training In Raipur': { component: InternshipComponent },
  'Graphic Design Training In Chhattisgarh': { component: InternshipComponent },

  'Java Design Internship': { component: InternshipComponent },
  'Java Internship Near Me': { component: InternshipComponent },
  'Java Internship In Bilaspur': { component: InternshipComponent },
  'Java Internship In Raipur': { component: InternshipComponent },
  'Java Internship In Chhattisgarh': { component: InternshipComponent },

  'Java Training': { component: InternshipComponent },
  'Java Training Near Me': { component: InternshipComponent },
  'Java Training In Bilaspur': { component: InternshipComponent },
  'Java Training In Raipur': { component: InternshipComponent },
  'Java Training In Chhattisgarh': { component: InternshipComponent },

  'Laravel Design Internship': { component: InternshipComponent },
  'Laravel Internship Near Me': { component: InternshipComponent },
  'Laravel Internship In Bilaspur': { component: InternshipComponent },
  'Laravel Internship In Raipur': { component: InternshipComponent },
  'Laravel Internship In Chhattisgarh': { component: InternshipComponent },

  'Laravel Training': { component: InternshipComponent },
  'Laravel Training Near Me': { component: InternshipComponent },
  'Laravel Training In Bilaspur': { component: InternshipComponent },
  'Laravel Training In Raipur': { component: InternshipComponent },
  'Laravel Training In Chhattisgarh': { component: InternshipComponent },

  'Mongodb Internship': { component: InternshipComponent },
  'Mongodb Internship Near Me': { component: InternshipComponent },
  'Mongodb Internship In Bilaspur': { component: InternshipComponent },
  'Mongodb Internship In Raipur': { component: InternshipComponent },
  'Mongodb Internship In Chhattisgarh': { component: InternshipComponent },

  'Mongodb Training': { component: InternshipComponent },
  'Mongodb Training Near Me': { component: InternshipComponent },
  'Mongodb Training In Bilaspur': { component: InternshipComponent },
  'Mongodb Training In Raipur': { component: InternshipComponent },
  'Mongodb Training In Chhattisgarh': { component: InternshipComponent },

  'Php Internship': { component: InternshipComponent },
  'Php Internship Near Me': { component: InternshipComponent },
  'Php Internship In Bilaspur': { component: InternshipComponent },
  'Php Internship In Raipur': { component: InternshipComponent },
  'Php Internship In Chhattisgarh': { component: InternshipComponent },

  'Php Training': { component: InternshipComponent },
  'Php Training Near Me': { component: InternshipComponent },
  'Php Training In Bilaspur': { component: InternshipComponent },
  'Php Training In Raipur': { component: InternshipComponent },
  'Php Training In Chhattisgarh': { component: InternshipComponent },

  'Python Internship': { component: InternshipComponent },
  'Python Internship Near Me': { component: InternshipComponent },
  'Python Internship In Bilaspur': { component: InternshipComponent },
  'Python Internship In Raipur': { component: InternshipComponent },
  'Python Internship In Chhattisgarh': { component: InternshipComponent },

  'Python Training': { component: InternshipComponent },
  'Python Training Near Me': { component: InternshipComponent },
  'Python Training In Bilaspur': { component: InternshipComponent },
  'Python Training In Raipur': { component: InternshipComponent },
  'Python Training In Chhattisgarh': { component: InternshipComponent },

  'Reactjs Internship': { component: InternshipComponent },
  'Reactjs Internship Near Me': { component: InternshipComponent },
  'Reactjs Internship In Bilaspur': { component: InternshipComponent },
  'Reactjs Internship In Raipur': { component: InternshipComponent },
  'Reactjs Internship In Chhattisgarh': { component: InternshipComponent },

  'Reactjs Training': { component: InternshipComponent },
  'Reactjs Training Near Me': { component: InternshipComponent },
  'Reactjs Training In Bilaspur': { component: InternshipComponent },
  'Reactjs Training In Raipur': { component: InternshipComponent },
  'Reactjs Training In Chhattisgarh': { component: InternshipComponent },

  'Social Media Marketing Internship': { component: InternshipComponent },
  'Social Media Marketing Internship Near Me': { component: InternshipComponent },
  'Social Media Marketing Internship In Bilaspur': { component: InternshipComponent },
  'Social Media Marketing Internship In Raipur': { component: InternshipComponent },
  'Social Media Marketing Internship In Chhattisgarh': { component: InternshipComponent },

  'Social Media Marketing Training': { component: InternshipComponent },
  'Social Media Marketing Training Near Me': { component: InternshipComponent },
  'Social Media Marketing Training In Bilaspur': { component: InternshipComponent },
  'Social Media Marketing Training In Raipur': { component: InternshipComponent },
  'Social Media Marketing Training In Chhattisgarh': { component: InternshipComponent },

  'Word Press Internship': { component: InternshipComponent },
  'Word Press Internship Near Me': { component: InternshipComponent },
  'Word Press Internship In Bilaspur': { component: InternshipComponent },
  'Word Press Internship In Raipur': { component: InternshipComponent },
  'Word Press Internship In Chhattisgarh': { component: InternshipComponent },

  'Word Press Training': { component: InternshipComponent },
  'Word Press Training Near Me': { component: InternshipComponent },
  'Word Press Training In Bilaspur': { component: InternshipComponent },
  'Word Press Training In Raipur': { component: InternshipComponent },
  'Word Press Training In Chhattisgarh': { component: InternshipComponent },


};



export const mapRoutes = (routes = Route) => Object.entries(routes)
    .filter((r)=> r[1].component)
    .map(([path, config])=> ({ ...config, path: path.replace(/ /g, '-') }));

export const capatelize = (data: any) => {
  if (typeof data === 'string')
    if (data.includes(' ')) {
      let str = '';
      data.split(' ').map((r) => {
        str = str + (r.slice(0, 1).toUpperCase() + r.slice(1)) + ' ';
      });
      return str;
    } else return data.slice(0, 1).toUpperCase() + data.slice(1);
  return data;
};
