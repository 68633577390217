  <!-- ------------------Page Heading Start------------- -->

  <div class="page_heading">
    <div class="container">
      <!-- <h2>Video Creation</h2> -->
      <h2>{{titles}}</h2>
    </div>
  </div>
  <app-banner [BannerData]="bannerArray"></app-banner>
  <!-- ------------------Page Heading End------------- -->

  <!-- ------------------Services web development Start-------------->
  <!-- <section class="why-choos-lg pad-tb deep-dark">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="common-heading text-l">
            <h2 class="mb20">BEST <span class="text-second text-bold">Video Creation Agency</span> Among The Leading Companies in India</h2>
            <p>A creator is someone who is responsible for the contribution of information to any media and most especially to digital media. They usually target a specific end-user / audience in specific contexts.</p>
            <div class="itm-media-object mt40 tilt-3d">
              <div class="media">
                <p>Videos let you establish authority and a more personal feel to your message. You will be far more likely to connect on an emotional level with your audience if you use video versus another content type.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div data-tilt="" data-tilt-max="5" data-tilt-speed="1000" class="single-image bg-shape-dez wow fadeIn" data-wow-duration="2s" style="visibility: visible; animation-duration: 2s; animation-name: fadeIn; will-change: transform; transform: perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1);"><img loading="lazy" src="assets/images/video.png" alt="image" class="img-fluid"></div>
        </div>
      </div>
    </div>
  </section> -->

  <!-- ------------------Services web development End---------------->

  <!-- new -->

  <section class="our-business">
    <div class="row  our-business-heading mt-0">
      <div class="col-sm-12 pb-3">
        <h2> Video Creation

        </h2>
        <span> Our Video Creation Services:

        </span>
      </div>
    </div>
    <div class="row content-one mt-5">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="./assets/img/our-business-22.png" alt="our-business-3" >
        <div class="s-no">
          <span>01</span>
        </div>
      </div>
      <div class="col-sm-6 googleRanking">
        <div class="float">
          <h3>Customized Video Solutions</h3>
          <p>
            At ResilienceSoft, we proudly champion the belief that one size certainly does not fit all when it comes to video creation. Our video creation services revolve around the meticulous tailoring of visual narratives to your unique needs and aspirations. Whether your objective is to unveil an attention-grabbing promotional video that captivates audiences, present an informative product demonstration that educates, or craft the compelling narrative of your brand's journey, rest assured, we have every aspect of your vision meticulously covered. With ResilienceSoft, your video creation experience is anything but ordinary; it's an extraordinary journey of transformation.
          </p>
        </div>
      </div>
    </div>
    <div class="row content-one mt-0">
      <div class="col-sm-6 localseoservice">
        <div class="float-l">
          <h3>Cutting-Edge Technology</h3>
          <p class="one-p">
            At ResilienceSoft, embracing the cutting edge of technological advancements is not a choice; it's a commitment etched into our DNA. We continually immerse ourselves in the latest video production equipment and software, ensuring that your video creation experience isn't just about visual magnificence; it's about consistently adhering to the most stringent industry standards. With ResilienceSoft, your videos aren't just visually stunning; they are the embodiment of innovation. Each frame is a testament to our dedication to pushing the boundaries of what's possible.
            



          </p>
        </div>
      </div>
      <div class="col-sm-6 center-line-l">
        <img loading="lazy" src="./assets/img/our-business-21.png" alt="our-business-2" class="imgflip" >
        <div class="s-no-l">
          <span>02</span>
        </div>
      </div>
    </div>
    <div class="row content-one mt-0">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="./assets/img/our-business-3.png" alt="our-business-3">
        <div class="s-no">
          <span>03</span>
        </div>
      </div>
      <div class="col-sm-6 googlePenalty">
        <div class="float">
          <h3>Creative Collaboration
          </h3>
          <p>
            In the grand tapestry of video creation, your vision isn't just a piece of the puzzle; it's the irreplaceable thread that weaves the entire narrative together. At ResilienceSoft, we not only respect your vision; we celebrate it. We place your unique perspective at the epicentre of our video creation process. From the exhilarating conceptualization stage to the meticulously orchestrated final edit, we embark on a profound journey of creative collaboration. This ensures that your ideas and feedback are not just heard; they are woven into the very fabric of the video. Your vision isn't just acknowledged; it's celebrated, nurtured, and brought to life with unparalleled vibrancy.
            



          </p>
        </div>
      </div>
    </div>
    <div class="row content-one mt-0">
      <div class="col-sm-6 localseoservice">
        <div class="float-l">
          <h3>Timely Delivery</h3>
          <p class="one-p">
            In the fast-paced realm of video creation, time is unequivocally of the essence. At ResilienceSoft, we understand this implicitly. Our unwavering commitment to punctuality ensures that you're not just assured of receiving your videos promptly; you're also guaranteed that this timeliness in no way equates to any compromise on quality. With ResilienceSoft, your videos aren't just delivered on schedule; they are delivered with an unwavering commitment to excellence. Each frame is a testament to our dedication to quality, ensuring that your final video doesn't just meet but surpasses your expectations.
            
          </p>
        </div>
      </div>
      <div class="col-sm-6 center-line-l">
        <img loading="lazy" src="./assets/img/our-business-2.png" alt="our-business-2">
        <div class="s-no-l">
          <span>04</span>
        </div>
      </div>
    </div>

  </section>




  <!-- -------------------------our-business-mobile Section start----------------- -->
  <section class="our-business-mobile">
    <div class="row  our-business-heading mt-0">
      <div class="col-sm-12">
        <h2> Video Creation</h2>
        <span>Our Video Creation Services</span>
      </div>
    </div>
    <div class="row content-one mt-5">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="./assets/img/our-business-22.png" alt="our-business" >
        <div class="s-no">
          <span>01</span>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="container float">
          <h3>Customized Video Solutions</h3>
          <p>
            At ResilienceSoft, we proudly champion the belief that one size certainly does not fit all when it comes to video creation. Our video creation services revolve around the meticulous tailoring of visual narratives to your unique needs and aspirations. Whether your objective is to unveil an attention-grabbing promotional video that captivates audiences, present an informative product demonstration that educates, or craft the compelling narrative of your brand's journey, rest assured, we have every aspect of your vision meticulously covered. With ResilienceSoft, your video creation experience is anything but ordinary; it's an extraordinary journey of transformation.
          </p>

        
        </div>
      </div>
    </div>
    <div class="row content-one mt-0">
      <div class="col-sm-6 center-line-l">
        <img loading="lazy" src="./assets/img/our-business-21.png" alt="our-business-2" class="imgflip" >
        <div class="s-no-l">
          <span>02</span>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="container float">
          <h3> Cutting-Edge Technology</h3>
          <p class="one-p"> 
            At ResilienceSoft, embracing the cutting edge of technological advancements is not a choice; it's a commitment etched into our DNA. We continually immerse ourselves in the latest video production equipment and software, ensuring that your video creation experience isn't just about visual magnificence; it's about consistently adhering to the most stringent industry standards. With ResilienceSoft, your videos aren't just visually stunning; they are the embodiment of innovation. Each frame is a testament to our dedication to pushing the boundaries of what's possible.
            </p>
        </div>
      </div>
    </div>
    <div class="row content-one mt-0">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="./assets/img/our-business-3.png" alt="our-business-3">
        <div class="s-no">
          <span>03</span>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="container float mb-3">
          <h3>Creative Collaboration</h3>
          <p>In the grand tapestry of video creation, your vision isn't just a piece of the puzzle; it's the irreplaceable thread that weaves the entire narrative together. At ResilienceSoft, we not only respect your vision; we celebrate it. We place your unique perspective at the epicentre of our video creation process. From the exhilarating conceptualization stage to the meticulously orchestrated final edit, we embark on a profound journey of creative collaboration. This ensures that your ideas and feedback are not just heard; they are woven into the very fabric of the video. Your vision isn't just acknowledged; it's celebrated, nurtured, and brought to life with unparalleled vibrancy.</p>
        </div>
      </div>
    </div>


    <div class="row content-one mt-0">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="./assets/img/our-business-2.png" alt="our-business-3">
        <div class="s-no">
          <span>03</span>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="container float mb-3">
          <h3>Timely Delivery</h3>
          <p> In the fast-paced realm of video creation, time is unequivocally of the essence. At ResilienceSoft, we understand this implicitly. Our unwavering commitment to punctuality ensures that you're not just assured of receiving your videos promptly; you're also guaranteed that this timeliness in no way equates to any compromise on quality. With ResilienceSoft, your videos aren't just delivered on schedule; they are delivered with an unwavering commitment to excellence. Each frame is a testament to our dedication to quality, ensuring that your final video doesn't just meet but surpasses your expectations.</p>
        </div>
      </div>
    </div>
  </section>
  <!-- -------------------------our-business-mobile Section end----------------- -->