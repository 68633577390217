<section
  class="main_container bannerComponent container-fluid"
  [ngStyle]="getUrl(BannerData)"
>
  <div class="adjustment_container row">
    <div class="col-sm-12 col-md-6 col-lg-6">
      <img loading="lazy"
        class="bannerimg"
        src="{{ BannerData?.bannerImg?.banner }}"
        alt="seo"
      />
      <div class="carousal_container">
        <ngx-slick-carousel
          #slickModal="slick-carousel"
          [config]="slideConfigmsg"
        >
          <div
            ngxSlickItem
            *ngFor="let txt of BannerData.bannerTXT"
            class="slide"
          >
            <div class="content">
              <p class="description" style="color: black">{{ txt.title }}</p>
            </div>
          </div>
        </ngx-slick-carousel>
      </div>
    </div>
    <div class="col-md-5 col-lg-4 col-xl-6 pull-right">
      <form [formGroup]="formData" class="banner-form">
        <h3 class="mb-3">Get in touch</h3>
        <div class="name">
          <div class="firstname">
            <input
              matInput
              class="form-control"
              type="text"
              placeholder="First Name*"
              formControlName="firstname"
              required
              style="margin-bottom: 0"
              (keypress)="noSpace($event)"
            />
            <div
              id="formErrors"
              *ngIf="firstname && firstname.invalid && firstname.touched"
            >
              <span *ngIf="firstname.errors?.['required']"
                ><i
                  aria-hidden="true"
                  class="fa fa-exclamation-triangle"
                  style="color: #fe3061"
                ></i>
                Required
              </span>
              <span
                *ngIf="firstname.errors?.['pattern'] && !firstname.errors?.['required']"
              >
                Invalid Name input
              </span>
            </div>
          </div>
          <div class="lastname">
            <input
              matInput
              class="form-control"
              type="text"
              placeholder="Last Name(Optional)"
              formControlName="lastname"
              style="margin-bottom: 0"
            />
            <div
              id="formErrorslastname"
              *ngIf="lastname && lastname.invalid && lastname.touched"
            >
              <span *ngIf="lastname.errors?.['required']"
                ><i
                  aria-hidden="true"
                  class="fa fa-exclamation-triangle"
                  style="color: #fe3061"
                ></i>
                Required
              </span>
              <span
                *ngIf="lastname.errors?.['pattern'] && !lastname.errors?.['required']"
              >
                Invalid Name input
              </span>
            </div>
          </div>
        </div>
        <div class="contact">
          <input
            matInput
            class="form-control"
            type="text"
            (keypress)="numberOnly($event)"
            placeholder="Mobile Number*"
            formControlName="Number"
            maxlength="10"
            required
          />
          <div
            id="formError"
            *ngIf="contact && contact.invalid && contact.touched"
          >
            <span *ngIf="contact.errors?.['required']"
              ><i
                aria-hidden="true"
                class="fa fa-exclamation-triangle"
                style="color: #fe3061"
              ></i>
              Required
            </span>
            <span
              *ngIf="contact.errors?.['pattern'] && !contact.errors?.['required']"
            >
              Invalid input
            </span>
            <span
              *ngIf="(contact.errors?.['minlength'] || contact.errors?.['maxlength']) && !contact.errors?.['pattern']"
            >
              Phone Number Must be of 10 Digit
            </span>
          </div>
        </div>
        <div class="email">
          <input
            matInput
            class="form-control"
            type="email"
            placeholder="Email(Optional)"
            formControlName="email"
          />
          <div id="formError" *ngIf="email && email.invalid && email.touched">
            <span *ngIf="email.errors?.['required']"
              ><i
                aria-hidden="true"
                class="fa fa-exclamation-triangle"
                style="color: #fe3061"
              ></i>
              Required
            </span>
            <span
              *ngIf="email.errors?.['pattern'] && !email.errors?.['required']"
            >
              Email is invalid
            </span>
          </div>
        </div>
        <!-- <input type="text" placeholder="Website-Url*"> -->
        <div class="message">
          <textarea
            id="Description"
            class="form-control"
            name="Description"
            rows="4"
            cols="auto"
            placeholder="Message*"
            formControlName="msg"
            (keypress)="noSpace($event)"
          ></textarea>
          <div id="formError" *ngIf="msg && msg.invalid && msg.touched">
            <span *ngIf=" msg.errors?.['required']"
              ><i
                aria-hidden="true"
                class="fa fa-exclamation-triangle"
                style="color: #fe3061"
              ></i>
              Required
            </span>
            <span *ngIf="msg.errors?.['minlength'] && !msg.errors?.['pattern']">
              Minimum 10 Words are Required
            </span>
          </div>
        </div>
        <button
          class="banner-btn"
          (click)="submit()"
          [disabled]="formData.invalid"
        >
          Submit
        </button>
      </form>
      <!-- <div class="form_container">
                <div class="common-heading text-l" style="text-align:center ;">
                    <h1 class="mb20"> <b>GET</b> <span class="text-second text-bold"> <b> IN TOUCH</b></span>
                    </h1>

                </div>
                <form [formGroup]="formData" class="container-fluid">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="form-group mrb-25">
                                <input name="ctl00$ContentPlaceHolder1$txtenqnam" type="text"
                                    id="ctl00_ContentPlaceHolder1_txtenqnam" placeholder="Full Name*"
                                    class="form-control" required formControlName="Name">
                                <div id="formError" *ngIf="fullName && fullName.invalid && fullName.touched ">
                                    <span *ngIf="fullName.errors?.['required']">

                                        Required
                                    </span>
                                    <span *ngIf="fullName.errors?.['pattern'] && !fullName.errors?.['required']">
                                        Invalid Name input </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-group mrb-25">
                                <input name="ctl00$ContentPlaceHolder1$txtenqmob" type="text"
                                    id="ctl00_ContentPlaceHolder1_txtenqmob" placeholder="Mobile Number*"
                                    class="form-control" maxlength="10" required formControlName="Number">
                                <div id="formError" *ngIf="contact && contact.invalid && contact.touched">
                                    <span *ngIf="contact.errors?.['required']">

                                        Required
                                    </span>
                                    <span *ngIf="contact.errors?.['pattern'] && !contact.errors?.['required']">

                                        Invalid input </span>
                                    <span
                                        *ngIf="(contact.errors?.['minlength'] || contact.errors?.['maxlength']) && !contact.errors?.['pattern']">

                                        Phone
                                        Number Must be of 10 Digit </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-group mrb-25">
                                <textarea name="ctl00$ContentPlaceHolder1$txtmsg" rows="4" cols="20"
                                    id="ctl00_ContentPlaceHolder1_txtmsg" class="form-text" placeholder="Message*"
                                    formControlName="msg"></textarea>
                                <div id="formError" *ngIf="msg && msg.invalid && msg.touched">
                                    <span *ngIf=" msg.errors?.['required']">
                                        Required
                                    </span>
                                    <span *ngIf="msg.errors?.['minlength'] && !msg.errors?.['pattern']">
                                        Minimum 10 Words are Required </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 mt-1">
                            <div class="form-group">

                                <button class="form_button_send" (click)="submit()" type="button"
                                    [disabled]="formData.invalid" name="submit">Send
                                    Message
                                </button>

                            </div>
                        </div>
                    </div>
                </form>
            </div> -->
    </div>
    <!-- <div class="plan_start">
            <div style="background-color: red; color: rgb(245, 230, 63);">
                <h1>PLAN START</h1>
            </div>
            <div class="INR" style="background-color:yellow;   color: rgb(3, 3, 3)">
                <h1>INR 12,999/m</h1>
            </div>
        </div> -->
  </div>
</section>
