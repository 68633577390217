import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { capatelize } from 'src/app/SEO_Constant/routes.constant';

@Component({
  selector: 'app-web-application-development',
  templateUrl: './web-application-development.component.html',
  styleUrls: ['./web-application-development.component.css']
})
export class WebApplicationDevelopmentComponent implements OnInit {
  titles:any;
  bannerArray:any = {
    "bannerTXT" : [
      {
        title: 'complex process of building, testing and deploying a web-based app.',
      },
      {
        title: 'Web applications are interactive pages.',
      },
      {
        title: 'A User-Friendly.Responsive Interface',
      },
      {
        title: 'Web application development enables interaction with the users.',
      },
    ],
    "bannerImg" : {
      "banner": "assets/img/web_dev.png",
      "backgroundImg": "assets/img/banner-background.jpg"
    }
  }

  constructor( private title : Title, private  router : Router ) {
    this.titles = router.url.split('/')[router.url.split('/').length-1].replace(/-/g,' ')
  }

  ngOnInit(): void {
    this.title.setTitle(capatelize(this.titles) +' | Resiliencesoft')
  }

}
