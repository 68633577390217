<div class="page_heading">
  <div class="container">
      <h2>{{title}}</h2>
  </div>
</div>
<app-banner [BannerData]="bannerArray"></app-banner>


<!-- -------------------------our-business Section Start----------------- -->
<section class="our-business">
  <div class="row  our-business-heading mt-0">
    <div class="col-sm-12">
      <h2>Internship/Training</h2>
      <h3>Welcome to Resiliencesoft, a leading IT company that specialises in developing innovative and cutting-edge software solutions. We are thrilled to offer an exciting training and paid internship opportunity for those looking to gain practical experience in the field of IT.</h3>
    </div>
  </div>
<ng-container *ngFor="let data of ourProducts | keyvalue let i=index;">
        <div *ngIf="i % 2 == 0" class="row content-one" [class.mt-0]="i>1">
          <div class="col-sm-6 center-line">
            <img loading="lazy" src="{{data.value.img}}" alt="product" width="400">
            <div class="s-no">
              <span>{{i+1}}</span>
            </div>
          </div>
          <div class="col-sm-6 googleRanking">
            <div class="float">
              <h3>{{data.value.heading}} Internship / {{data.value.heading}} Training</h3>
              <p>{{data?.value?.features}}</p>
            </div>
          </div>
        </div>
        <div *ngIf="i % 2 != 0" class="row content-one mt-0">
          <div class="col-sm-6 localseoservice">
            <div class="float-l">
              <h3>{{data.value.heading}} Internship / {{data.value.heading}} Training</h3>
              <p class="one-p">{{data?.value?.features}}</p>
            </div>
          </div>
          <div class="col-sm-6 center-line-l">
            <img loading="lazy" src="{{data.value.img}}" alt="product" width="400">
            <div class="s-no-l">
              <span>{{i+1}}</span>
            </div>
          </div>
        </div>
</ng-container>
  </section>

<!-- -------------------------our-business Section end----------------- -->

<!-- -------------------------our-business-mobile Section start----------------- -->
<section class="our-business-mobile">
  <div class="row  our-business-heading mt-0">
    <div class="col-sm-12">
      <h2>{{detail?.heading}} Features</h2>
    </div>
  </div>
  <ng-container *ngFor="let data of ourProducts | keyvalue let i=index;">
  <div *ngIf="i % 2 == 0" class="row content-one mt-5">
    <div class="col-sm-6 center-line">
      <img loading="lazy" src="{{data.value.img}}" alt="product">
      <div class="s-no">
        <span>{{i+1}}</span>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="container float">
        <h3>{{data.value.heading}}</h3>
        <p>{{data?.value?.features}}</p>
      </div>
    </div>
  </div>
  <div *ngIf="i % 2 != 0" class="row content-one mt-0">
    <div class="col-sm-6 center-line-l">
      <img loading="lazy" src="{{data.value.img}}" alt="product">
      <div class="s-no-l">
        <span>{{i+1}}</span>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="container float">
        <h3>{{data.value.heading}}</h3>
        <p class="one-p">{{data?.value?.features}}</p>
      </div>
    </div>
  </div>
</ng-container>
</section>
<!-- -------------------------our-business-mobile Section end----------------- -->
<hr class="mt-0">
<div class="title-wrapper">
  <h2 class="m-5">We Offer Complete Internship/Training</h2>
</div>
<div class="container tech-wrap mt-5">
  <div class="row">
      <div class="col-md-4 mb-3" *ngFor="let offer of completeOffer">
          <div class="card">
            <img class="card-img-top w-100" [src]="offer.img" alt="Card image cap">
              <div class="card-body">
                  <h5 class="card-title">{{offer.heading}}</h5>
                  <p class="card-text text-justify">{{ selectedOfferId === offer.id && readMore ? offer.features : offer.features.substr(0,125).concat('...') }}</p>
                  <a *ngIf="selectedOfferId !== offer.id" class="btn button-text" (click)="expandOffer(offer.id)">Read More</a>
                  <a *ngIf="selectedOfferId === offer.id" class="btn button-text" (click)="collapseOffer()">Read Less</a>
              </div>
          </div>
      </div>
      <!-- <div class="col-md-4">
          <div class="card">
              <div class="card-body">
                  <h5 class="card-title"><img class="w-100" src="../../assets/img/website.webp"></h5>
                  <p class="card-text">Some quick example text to build on the card title and make up the</p>
                  <a href="#" class="btn btn-primary">Read More</a>
              </div>
          </div>
      </div>
      <div class="col-md-4">
          <div class="card">
              <div class="card-body">
                  <h5 class="card-title"><img class="w-100" src="../../assets/img/website.webp"></h5>
                  <p class="card-text">Some quick example text to build on the card title and make up the</p>
                  <a href="#" class="btn btn-primary">Read More</a>
              </div>
          </div>
      </div> -->
  </div>
</div>
