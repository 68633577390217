import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { capatelize } from 'src/app/SEO_Constant/routes.constant';

@Component({
  selector: 'app-graphic-design',
  templateUrl: './graphic-design.component.html',
  styleUrls: ['./graphic-design.component.css']
})
export class GraphicDesignComponent implements OnInit {
  titles:any;
  bannerArray:any = {
    "bannerTXT" : [
      {
        title: 'The first and most basic element of design is the line.',
      },
      {
        title: 'Typography.',
      },
      {
        title: 'Texture.',
      },
      {
        title: 'Space.',
      },
    ],
    "bannerImg" : {
      "banner": "assets/img/Graphic_Design.png",
      "backgroundImg": "assets/img/banner-background.jpg"
    }
  }

  constructor( private title : Title, private router:Router ) {
    this.titles = router.url.split('/')[router.url.split('/').length-1].replace(/-/g,' ')
   }

  ngOnInit(): void {
    this.title.setTitle(capatelize(this.titles) +' | Resiliencesoft')
  }

}
