  <!-- ------------------Page Heading Start------------- -->

  <div class="page_heading">
    <div class="container">
      <!-- <h2>Web Hosting Solution</h2> -->
      <h2>{{titles}}</h2>
    </div>
  </div>
  <app-banner [BannerData]="bannerArray"></app-banner>

  <!-- ------------------Page Heading End------------- -->

  <!-- ------------------Services web development Start-------------->
  <!-- <section class="why-choos-lg pad-tb deep-dark">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="common-heading text-l">
            <h2 class="mb20">BEST <span class="text-second text-bold">Web Hosting Solutions Agency</span> Among The Leading Companies in India</h2>
            <p>Resiliencesoft Web Hosting solutions enable its clients to adeptly host a website with full cPanel access along with many additional websites. With highly advanced tools, technical support and experienced team of technical professionals by our side, we feel fully equipped to offer our clients the most stable and secure shared Hosting environment. In addition we present to our clients the choice of operating system and truly affordable, feature-rich shared hosting plans to meet core business requirements of all-sizes businesses.</p>
            <div class="itm-media-object mt40 tilt-3d">
              <div class="media">
                <div class="media-body">
                  <h4>Our Web Hosting Features are:</h4>
                  <p>99.99 % Uptime gurantee.<br>
                    Flexible and Easy to Use Control Panel.<br>
                    Servers are located in India itself for lowest latency and fastest access times.<br>
                    Readymade free script ready to install in your account by few clicks.<br>
                    Webmail Access: Horde, Squirelmail, RoundCube etc.<br>
                    Receive Your corporate mail on your phone with IMAP support.<br>
                    Online Support Portal 24x7 with ready tutorials for your quick reference. and many more....</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div data-tilt="" data-tilt-max="5" data-tilt-speed="1000" class="single-image bg-shape-dez wow fadeIn" data-wow-duration="2s" style="visibility: visible; animation-duration: 2s; animation-name: fadeIn; will-change: transform; transform: perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1);"><img loading="lazy" src="assets/images/web-hosting_design.png" alt="image" class="img-fluid"></div>
        </div>
      </div>
    </div>
  </section> -->

  <!-- ------------------Services web development End---------------->


  <!-- new code-->

  <section class="our-business">
    <div class="row  our-business-heading mt-0">
      <div class="col-sm-12 pb-3">
        <h2> Web Hosting Solutions</h2>
        <span>Best Web Hosting Solutions Agency Among The Leading Companies in India</span>

      </div>
    </div>
    <div class="row content-one mt-5">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="./assets/img/our-business-21.png" alt="our-business-3">
        <div class="s-no">
          <span>01</span>
        </div>
      </div>
      <div class="col-sm-6 googleRanking">
        <div class="float">
          <h3> Unparalleled Web Hosting Solutions</h3>
          <p>In today's digital landscape, the concept of web hosting has exceeded mere accommodation; it has become the cornerstone of online success. Acknowledging the crucial significance of uninterrupted connectivity, we at Resiliencesoft understand that your website's uptime is not a luxury but an absolute necessity. Within our realm of cutting-edge web hosting solutions, reliability stands as our guiding principle.
            </p>
        </div>
      </div>
    </div>
    <div class="row content-one mt-0">
      <div class="col-sm-6 localseoservice">
        <div class="float-l">
          <h3>Superior Speed</h3>
          <p class="one-p"> 
            Exceeding Digital Horizons Gone are the days when sluggish loading held back the potential of online experiences. Within our expansive dominion of cutting-edge web hosting solutions, velocity isn't just a possibility—it's a fundamental principle that propels us forward. Our servers, enflamed by the fires of high-performance technology, seamlessly merge with advanced caching skills to create an unparalleled web hosting experience.
            </p>
        </div>
      </div>
      <div class="col-sm-6 center-line-l">
        <img loading="lazy" src="./assets/img/our-business-22.png" alt="our-business-2" class="imgflip" >
        <div class="s-no-l">
          <span>02</span>
        </div>
      </div>
    </div>
    <div class="row content-one mt-0">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="./assets/img/our-business-2.png" alt="our-business-3" class="imgflip">
        <div class="s-no">
          <span>03</span>
        </div>
      </div>
      <div class="col-sm-6 googlePenalty">
        <div class="float">
          <h3>User-Friendly Control Panel</h3>
          <p>Empowering Your Digital Command Center Harnessing the complexities of your web hosting setup has undergone a revolution. Our control panel is an orchestra conductor's wand in your hands. Its readiness presents you with the reins of your hosting universe—settings, domains, emails, and beyond—called with a few clicks. Seamlessly manage your web hosting narrative and embrace innovative control. </p>
        </div>
      </div>
    </div>
    <div class="row content-one mt-0">
      <div class="col-sm-6 localseoservice">
        <div class="float-l">
          <h3>Expert Support</h3>
          <p class="one-p">
            Your 24/7 Hosting Companion In the realm of web hosting solutions, our support is not an entity; it's a representation of assurance. Expertise is our companion, and availability is our pledge. Around the clock, across time zones, our dedicated team stands attentive. Whether technical challenges confuse you or inquiries wave we're your guiding light through the network of web hosting, ensuring your experience is a journey of smoothness and stillness.
           </p>
        </div>
      </div>
      <div class="col-sm-6 center-line-l">
        <img loading="lazy" src="./assets/img/our-business-3.png" alt="our-business-2" class="imgflip">
        <div class="s-no-l">
          <span>04</span>
        </div>
      </div>
    </div>

  </section>




  <!-- -------------------------our-business-mobile Section start----------------- -->
  <section class="our-business-mobile">
    <div class="row  our-business-heading mt-0">
      <div class="col-sm-12">
        <h2> Web Hosting Solutions</h2>
        <span>Best Web Hosting Solutions Agency Among The Leading Companies in India</span>
      </div>
    </div>
    <div class="row content-one mt-5">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="./assets/img/our-business-21.png" alt="our-business" >
        <div class="s-no">
          <span>01</span>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="container float">

          <h3> Unparalleled Web Hosting Solutions</h3>
          <p>In today's digital landscape, the concept of web hosting has exceeded mere accommodation; it has become the cornerstone of online success. Acknowledging the crucial significance of uninterrupted connectivity, we at Resiliencesoft understand that your website's uptime is not a luxury but an absolute necessity. Within our realm of cutting-edge web hosting solutions, reliability stands as our guiding principle.
            </p>
        </div>
      </div>
    </div>
    <div class="row content-one mt-0">
      <div class="col-sm-6 center-line-l">
        <img loading="lazy" src="./assets/img/our-business-22.png" alt="our-business-2" class="imgflip" >
        <div class="s-no-l">
          <span>02</span>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="container float">
          <h3>Superior Speed</h3>
          <p class="one-p"> 
            Exceeding Digital Horizons Gone are the days when sluggish loading held back the potential of online experiences. Within our expansive dominion of cutting-edge web hosting solutions, velocity isn't just a possibility—it's a fundamental principle that propels us forward. Our servers, enflamed by the fires of high-performance technology, seamlessly merge with advanced caching skills to create an unparalleled web hosting experience.
            </p>
        </div>
      </div>
    </div>
    <div class="row content-one mt-0">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="./assets/img/our-business-2.png" alt="our-business-3" class="imgflip">
        <div class="s-no">
          <span>03</span>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="container float mb-3">
          <h3>User-Friendly Control Panel</h3>
          <p>Empowering Your Digital Command Center Harnessing the complexities of your web hosting setup has undergone a revolution. Our control panel is an orchestra conductor's wand in your hands. Its readiness presents you with the reins of your hosting universe—settings, domains, emails, and beyond—called with a few clicks. Seamlessly manage your web hosting narrative and embrace innovative control. </p>
        </div>
      </div>
    </div>


    <div class="row content-one mt-0">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="./assets/img/our-business-3.png" alt="our-business-3" class="imgflip">
        <div class="s-no">
          <span>03</span>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="container float mb-3">
          <h3>Expert Support</h3>
          <p>
            Your 24/7 Hosting Companion In the realm of web hosting solutions, our support is not an entity; it's a representation of assurance. Expertise is our companion, and availability is our pledge. Around the clock, across time zones, our dedicated team stands attentive. Whether technical challenges confuse you or inquiries wave we're your guiding light through the network of web hosting, ensuring your experience is a journey of smoothness and stillness.
           </p>
        </div>
      </div>
    </div>
  </section>
  <!-- -------------------------our-business-mobile Section end----------------- -->