import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BannerComponent } from './banner/banner.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

const component = [BannerComponent];
const modules = [
  SlickCarouselModule
];

@NgModule({
  declarations: [component],
  imports: [CommonModule, modules, FormsModule, ReactiveFormsModule],
  exports: [component, modules],
})
export class SharedModule {}
