export const keyword = {
  globle:
    'ResilienceSoft, ResilienceSoft in bilaspur, ResilienceSoft in chhattisgarh , S/W Company, S/W Company in bilaspur, S/W Company in chhattisgarh, SEO service, SEO service in bilaspur, SEO service in chhattisgarh, WEB-Developer, WEB-Developer in bilaspur, WEB-Developer in chhattisgarh, Web-Designer, Web-Designer in bilaspur, Web-Designer in chhattisgarh,',
  Home: 'digital marketing agency,digital marketing Company, digital media marketing company, digital media marketing agency, website development company, website development, web development, web development company, mobile app marketing agency, mobile app development , mobile app development company, mobile app development agency, android app development company, android app development agency, seo company, seo company near me, Software company near me, it companies near me, Software company, Software Development Company, top it companies in chhattisgarh, top it companies in bilaspur, top it companies in raipur, it companies in chhattisgarh, it companies in bilaspur, it companies in raipur',
  DigitalMediaMarketing:
    'digital marketing agency, digital marketing agency in bilaspur, digital marketing agency in chhattisgarh, digital media marketing company, digital media marketing company in bilaspur,digital media marketing company in chhattisgarh, digital media marketing agency, digital media marketing agency in bilaspur, digital media marketing agency in chhattisgarh,  ',
  AndroidAppDevelopment:
    'mobile app marketing agency, mobile app marketing agency in bilaspur, mobile app marketing agency in chhattisgarh, mobile app development, mobile app development in bilaspur, mobile app development in chhattisgarh, mobile app development company, mobile app development company in bilaspur, mobile app development company in chhattisgarh,mobile app development agency, mobile app development agency blaspur, mobile app development agency in chhattisgarh, android app development company, android app development company in bilaspur, android app development company in chhattisgarh, android app development agency, android app development agency in bilaspur, android app development agency in chhattisgarh,',
  SEO: ' seo company, seo company in bilaspur, seo company in chhattisgarh, seo company near me, seo company near me in bilaspur, seo company near me chhattisarh,',
  Training:'Php Training Bilaspur, Java Training Bilaspur, Dotnet Training Bilaspur, React Training Bilaspur, React Js Training Bilaspur, React Native Training Bilaspur, Angular Training Bilaspur, Python Training Bilaspur'
};

export const description = {
  Home: "India's leading website development company in chhattisgarh. We help businesses to plan, design, build, manage, market, and monetize websites.",
  DigitalMediaMarketing:
    'Are you looking for the best digital media marketing company in India? Resiliencesoft is the best online marketing agency in chhattisgarh that offers result-oriented digital marketing services.',
  AndroidAppDevelopment:
    'Resiliencesoft is a leading android app development and mobile app marketing company in India. Hire our expert Indian mobile app developers for Startups, Enterprises & businesses.',
  SEO: 'Resiliencesoft is the best SEO company in India, We offer reliable and best SEO services. Contact top SEO agency to improve rankings & ROI. For more information contact us at 7470907916',
  Training:"Elevate your skills with ResilienceSoft's expert-led training programs. Unlock potential & achieve success with PHP, Java, Dotnet, Angular, Python, React & more!"
};
