import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { capatelize } from 'src/app/SEO_Constant/routes.constant';

@Component({
  selector: 'app-ios-app-development',
  templateUrl: './ios-app-development.component.html',
  styleUrls: ['./ios-app-development.component.css']
})
export class IosAppDevelopmentComponent implements OnInit {

  titles:any
  bannerArray:any = {
    "bannerTXT" : [
      {
        title: 'iOS app development helps to promote your brand to reach your targeted audience.',
      },
      {
        title: 'iOS app development empower your business in the global market.',
      },
      {
        title: 'iOS app helps in prompt work and serve your customers quickly and satisfactorily.',
      },
      {
        title: 'iOS app development boost sales, revenue and earn reorganization in the local global market.',
      },
    ],
    "bannerImg" : {
      "banner": "assets/img/app_dev.png",
      "backgroundImg": "assets/img/banner-background.jpg"
    }
  }

  constructor( private title : Title , private router:Router) {
    this.titles = router.url.split('/')[router.url.split('/').length-1].replace(/-/g,' ')
  }

  ngOnInit(): void {
    this.title.setTitle(capatelize(this.titles) +' | Resiliencesoft')
  }

}
