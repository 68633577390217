<!-- ------------------Page Heading Start------------- -->

<div class="page_heading">
  <div class="container">
    <!-- <h2>IOS APP Devlopment</h2> -->
    <h2>{{ titles }}</h2>
  </div>
</div>
<app-banner [BannerData]="bannerArray"></app-banner>

<!-- ------------------Page Heading End------------- -->

<!-- ------------------Services web development Start-------------->
<!-- <section class="why-choos-lg pad-tb deep-dark">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="common-heading text-l">
            <h2 class="mb20">BEST <span class="text-second text-bold">Ios App Development Agency</span> Among The Leading Companies in India</h2>
            <p>We're an Apple of Every App Lover
              At Resiliencesoft , we master the art of conceptualizing, designing and creating cool App Store applications, built and developed around iOS framework. We present tailor-made solutions for iOS operatives, and weave simplicity and user-friendly app structure for iPhone, iPad and iPod Touch.
              .</p>
            <div class="itm-media-object mt40 tilt-3d">
              <div class="media">
                <p>We develop uniform iOS applications that synchronizes well with Mac too. Our experts not only understand and thoroughly research for your applications, we make sure the applications we develop remain free from issues. We draw inspiration from your web-based infrastructure and customize the applications..</p>
              </div>
              <div class="media mt40">
                <p>We tune excellence into all our iOS applications to enable maximum downloads and likes from the end users. Our expertise lies in simplified presentation of iOS programming language Objective-C. Our efforts remain focused on building brilliantly featured apps through smart use of Xcode, an Integrated Development Environment (IDE) for Apple's phone and Mac operating systems..</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div data-tilt="" data-tilt-max="5" data-tilt-speed="1000" class="single-image bg-shape-dez wow fadeIn" data-wow-duration="2s" style="visibility: visible; animation-duration: 2s; animation-name: fadeIn; will-change: transform; transform: perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1);"><img loading="lazy" src="assets/images/mobile-application_app.png" alt="image" class="img-fluid"></div>
        </div>
      </div>
    </div>
  </section> -->

<!-- ------------------Services web development End---------------->

<!-- new -->
<section class="our-business">
  <div class="row our-business-heading mt-0">
    <div class="col-sm-12">
      <h2>IOS App development</h2>
      <span>
        Best Ios App Development Agency Among The Leading Companies in
        India</span
      >
    </div>
  </div>
  <div class="row content-one mt-5">
    <div class="col-sm-6 center-line">
      <img
        loading="lazy"
        src="./assets/img/our-business-3.png"
        alt="our-business-3"
      />
      <div class="s-no">
        <span>01</span>
      </div>
    </div>
    <div class="col-sm-6 googleRanking">
      <div class="float">
        <h3>Proven Mastery in iOS App Development</h3>
        <p>
          A Symphony of Unparalleled Success in the world of innovation, our
          symphony of achievements resounds through our portfolio, standing as
          evidence of our mastery of iOS app development. With every app gracing
          our lineup, triumph echoes, praise accumulates, and success is
          magnified. Our track record isn't a mere listing; it's a living
          showcase of our unparalleled ability to deliver results that exceed
          expectations, transforming mere app ideas into resounding victories.
        </p>
      </div>
    </div>
  </div>
  <div class="row content-one mt-0">
    <div class="col-sm-6 localseoservice">
      <div class="float-l">
        <h3>Collaborative Partnership</h3>
        <p class="one-p">
          Your Vision, Our Creative Canvas in iOS App Development. The canvas of
          iOS app development becomes a tapestry of creativity when your vision
          entangles with our expertise. We are more than builders; we are
          collaborators. Our partnership harmonizes like a symphony, with your
          insights composing the melody of creation. From the inception to the
          rise of launch, we stand by your side, weaving your ideas into the
          very fabric of your iOS app's existence. Your vision isn't merely
          recognized; it's harmoniously woven into a masterpiece that resonates
          with your aspirations.
        </p>
      </div>
    </div>
    <div class="col-sm-6 center-line-l">
      <img
        loading="lazy"
        src="./assets/img/our-business-21.png"
        alt="our-business-2"
        class="imgflip"
      />
      <div class="s-no-l">
        <span>02</span>
      </div>
    </div>
  </div>
  <div class="row content-one mt-0">
    <div class="col-sm-6 center-line">
      <img
        loading="lazy"
        src="./assets/img/our-business-22.png"
        alt="our-business-3"
      />
      <div class="s-no">
        <span>03</span>
      </div>
    </div>
    <div class="col-sm-6 googlePenalty">
      <div class="float">
        <h3>Time-Bound Excellence</h3>
        <p>
          The Pace of Punctual iOS App Innovation In the rhythm of progress,
          time is our guide. The timekeeper of punctuality beats at the core of
          our iOS app development. Your time is cherished, and our streamlined
          workflow dances to the rhythm of efficiency and precision. With each
          phase, your iOS app takes shape seamlessly and promptly. Our
          unwavering commitment to timely excellence ensures that your app
          emerges on schedule, adorned with harmonious notes of quality.
        </p>
      </div>
    </div>
  </div>
  <div class="row content-one mt-0">
    <div class="col-sm-6 localseoservice">
      <div class="float-l">
        <h3>Continued Commitment</h3>
        <p class="one-p">
          Sustaining the Pulse of iOS App Evolution The launch of your iOS app
          marks not an end, but a commencement to a continuing story. Our
          commitment thrives beyond milestones; it flourishes in ongoing
          support. Post-launch, we stand resolute, a guardian of your app's
          performance. As the digital landscape evolves, we remain your
          steadfast partner, seamlessly adapting your app to industry shifts.
          Your app's energy and effectiveness are sustained, positioning it
          always at the forefront of performance.
        </p>
      </div>
    </div>
    <div class="col-sm-6 center-line-l">
      <img
        loading="lazy"
        src="./assets/img/our-business-2.png"
        alt="our-business-2"
      />
      <div class="s-no-l">
        <span>04</span>
      </div>
    </div>
  </div>
</section>

<!-- -------------------------our-business-mobile Section start----------------- -->
<section class="our-business-mobile">
  <div class="row our-business-heading mt-0">
    <div class="col-sm-12">
      <h2>IOS App development</h2>
      <span>
        Best Ios App Development Agency Among The Leading Companies in
        India</span
      >
    </div>
  </div>
  <div class="row content-one mt-5">
    <div class="col-sm-6 center-line">
      <img
        loading="lazy"
        src="./assets/img/our-business-3.png"
        alt="our-business"
      />
      <div class="s-no">
        <span>01</span>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="container float">
        <h3>Proven Mastery in iOS App Development</h3>
        <p>
          A Symphony of Unparalleled Success in the world of innovation, our
          symphony of achievements resounds through our portfolio, standing as
          evidence of our mastery of iOS app development. With every app gracing
          our lineup, triumph echoes, praise accumulates, and success is
          magnified. Our track record isn't a mere listing; it's a living
          showcase of our unparalleled ability to deliver results that exceed
          expectations, transforming mere app ideas into resounding victories.
        </p>
      </div>
    </div>
  </div>
  <div class="row content-one mt-0">
    <div class="col-sm-6 center-line-l">
      <img
        loading="lazy"
        src="./assets/img/our-business-21.png"
        alt="our-business-2"
        class="imgflip"
      />
      <div class="s-no-l">
        <span>02</span>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="container float">
        <h3>Collaborative Partnership</h3>
        <p class="one-p">
          Your Vision, Our Creative Canvas in iOS App Development. The canvas of
          iOS app development becomes a tapestry of creativity when your vision
          entangles with our expertise. We are more than builders; we are
          collaborators. Our partnership harmonizes like a symphony, with your
          insights composing the melody of creation. From the inception to the
          rise of launch, we stand by your side, weaving your ideas into the
          very fabric of your iOS app's existence. Your vision isn't merely
          recognized; it's harmoniously woven into a masterpiece that resonates
          with your aspirations.
        </p>
      </div>
    </div>
  </div>
  <div class="row content-one mt-0">
    <div class="col-sm-6 center-line">
      <img
        loading="lazy"
        src="./assets/img/our-business-22.png"
        alt="our-business-3"
      />
      <div class="s-no">
        <span>03</span>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="container float mb-3">
        <h3>Time-Bound Excellence</h3>
        <p>
          The Pace of Punctual iOS App Innovation In the rhythm of progress,
          time is our guide. The timekeeper of punctuality beats at the core of
          our iOS app development. Your time is cherished, and our streamlined
          workflow dances to the rhythm of efficiency and precision. With each
          phase, your iOS app takes shape seamlessly and promptly. Our
          unwavering commitment to timely excellence ensures that your app
          emerges on schedule, adorned with harmonious notes of quality.
        </p>
      </div>
    </div>
  </div>

  <div class="row content-one mt-0">
    <div class="col-sm-6 center-line">
      <img
        loading="lazy"
        src="./assets/img/our-business-2.png"
        alt="our-business-3"
      />
      <div class="s-no">
        <span>03</span>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="container float mb-3">
        <h3>Continued Commitment</h3>
        <p>
          Sustaining the Pulse of iOS App Evolution The launch of your iOS app
          marks not an end, but a commencement to a continuing story. Our
          commitment thrives beyond milestones; it flourishes in ongoing
          support. Post-launch, we stand resolute, a guardian of your app's
          performance. As the digital landscape evolves, we remain your
          steadfast partner, seamlessly adapting your app to industry shifts.
          Your app's energy and effectiveness are sustained, positioning it
          always at the forefront of performance.
        </p>
      </div>
    </div>
  </div>
</section>
<!-- -------------------------our-business-mobile Section end----------------- -->
