import { Component, OnInit } from "@angular/core";
import { APIService } from "src/app/Services/main-service.service";

@Component({
    selector: 'snack-bar',
    template: '{{msg}}',
    styles: [],
  })
  export class SnackComponent implements OnInit {
    msg ?: string
    constructor(private api:APIService){}
    ngOnInit(){
      this.api.snackmsgobserv.subscribe(res => {
        this.msg = res
    })
    }
  }
