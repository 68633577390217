<!-- ------------------Page Heading Start------------- -->

<div class="page_heading">
  <div class="container">
    <!-- <h2>Website Designing</h2> -->
    <h2>{{titles}}</h2>
  </div>
</div>
<app-banner [BannerData]="bannerArray"></app-banner>

<!-- ------------------Page Heading End------------- -->

<!-- ------------------Services web development Start-------------->
<!-- <section class="why-choos-lg pad-tb deep-dark">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="common-heading text-l">
            <h2 class="mb20">BEST <span class="text-second text-bold">Website Designing Agency</span> Among The Leading Companies in India</h2>
            <p>Developing website designs that connect with the users requires a thorough understanding of the medium and a creative mindset. Here are some factors why you should consider us for your website design:.</p>
            <div class="itm-media-object mt40 tilt-3d">
              <div class="media">
                <div class="media-body">
                  <p>1) In the business of developing creative websites <br>
                     2) Experienced Coders, UI designers and content developers.<br>
                     3) Specialized in Portal and Ecommerce website design.<br>
                     4) Smart integration of social media tools.<br>
                     5) Masters in optimizing websites to rank well on search engines.<br>
                     6) Special team for support and website maintenance.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div data-tilt="" data-tilt-max="5" data-tilt-speed="1000" class="single-image bg-shape-dez wow fadeIn" data-wow-duration="2s" style="visibility: visible; animation-duration: 2s; animation-name: fadeIn; will-change: transform; transform: perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1);"><img loading="lazy" src="assets/images/web_design_new.png" alt="image" class="img-fluid"></div>
        </div>
      </div>
    </div>
  </section> -->

<!-- ------------------Services web development End---------------->




<!-- new code nikhil -->

<!-- -------------------------our-business Section Start----------------- -->
<section class="our-business">
  <div class="row  our-business-heading mt-0">
    <div class="col-sm-12">
      <h2>Website Designing</h2>
      <span> BEST Website Designing Agency Among The Leading Companies in India
      </span>
    </div>
  </div>
  <div class="row content-one mt-5">
    <div class="col-sm-6 center-line">
      <img loading="lazy" src="./assets/img/our-business-3.png" alt="our-business-3">

      <div class="s-no">
        <span>01</span>
      </div>
    </div>
    <div class="col-sm-6 googleRanking">
      <div class="float">
        <h3> Creative Excellence in Website Designing</h3>
        <p>At ResilienceSoft, our team of talented designers thrives on creativity in website designing. We don't settle
          for the ordinary; we strive to create extraordinary website designs that stand out in the dynamic digital
          landscape. When you choose ResilienceSoft, you're not only selecting a partner for innovation but also for
          captivating website designs that leave a lasting impression.</p>
      </div>
    </div>
  </div>
  <div class="row content-one mt-0">
    <div class="col-sm-6 localseoservice">
      <div class="float-l">
        <h3>Proven Track Record of Outstanding Website Designs</h3>
        <p class="one-p">Our portfolio stands as proof of our expertise. We've had the privilege of collaborating with a
          diverse range of clients, each with unique design requirements. Our proven track record of delivering
          outstanding website designs and driving tangible results underscores our unchanging commitment to excellence
          in the field of website design. </p>
      </div>
    </div>
    <div class="col-sm-6 center-line-l">
      <img loading="lazy" src="./assets/img/our-business-21.png" alt="our-business-2" class="imgflip">
      <div class="s-no-l">
        <span>02</span>
      </div>
    </div>
  </div>
  <div class="row content-one mt-0">
    <div class="col-sm-6 center-line">
      <img loading="lazy" src="./assets/img/our-business-22.png" alt="our-business-3">
      <div class="s-no">
        <span>03</span>
      </div>
    </div>
    <div class="col-sm-6 googlePenalty">
      <div class="float">
        <h3>Affordability and Value in Website Designing</h3>
        <p> Exceptional quality in website design doesn't have to come with an expensive price tag. ResilienceSoft
          stands apart by offering competitive pricing without compromising on the quality of our work. We ensure that
          you receive excellent value for your investment in top-notch website design.</p>
      </div>
    </div>
  </div>












  <div class="row content-one mt-0">
    <div class="col-sm-6 localseoservice">
      <div class="float-l">
        <h3>User-Centric Approach to Website Designing</h3>
        <p class="one-p">Website designing with a user-centric approach is at the heart of what we do. We craft designs
          with your target audience in mind, creating responsive and user-friendly interfaces that engage visitors and
          guide them seamlessly through your website. Our website designs are not just visually appealing; they're
          meticulously designed to enhance user interaction and elevate conversion rates.
        </p>
      </div>
    </div>
    <div class="col-sm-6 center-line-l">
      <img loading="lazy" src="./assets/img/our-business-2.png" alt="our-business-2">
      <div class="s-no-l">
        <span>04</span>
      </div>
    </div>
  </div>
  <!-- <div class="row content-one mt-0">
    <div class="col-sm-6 center-line">
      <img loading="lazy" src="./assets/img/our-business-3.png" alt="our-business-3">
      <div class="s-no">
        <span>05</span>
      </div>
    </div>
    <div class="col-sm-6 googlePenalty">
      <div class="float">
        <h3>User Experience (UX) </h3>
        <p>User experience plays a vital role in website design as it determines how visitors interact with and perceive the website. UX involves designing intuitive navigation, clear information architecture, and seamless interactions to provide a positive and engaging experience for users.</p>
      </div>
    </div>
  </div> -->






</section>





<!-- -------------------------our-business-mobile Section start----------------- -->
<section class="our-business-mobile">
  <div class="row  our-business-heading mt-0">
    <div class="col-sm-12">
      <h2>Website Designing</h2>
      <span>BEST Website Designing Agency Among The Leading Companies in India</span>
    </div>
  </div>
  <div class="row content-one mt-5">
    <div class="col-sm-6 center-line">
      <img loading="lazy" src="./assets/img/our-business-3.png" alt="our-business">
      <div class="s-no">
        <span>01</span>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="container float">

        <h3>Creative Excellence in Website Designing</h3>
        <p>
          At ResilienceSoft, our team of talented designers thrives on creativity in website designing. We don't settle
          for the ordinary; we strive to create extraordinary website designs that stand out in the dynamic digital
          landscape. When you choose ResilienceSoft, you're not only selecting a partner for innovation but also for
          captivating website designs that leave a lasting impression.</p>
      </div>
    </div>
  </div>
  <div class="row content-one mt-0">
    <div class="col-sm-6 center-line-l">
      <img loading="lazy" src="./assets/img/our-business-21.png" alt="our-business-22"  class="imgflip" >
      <div class="s-no-l">
        <span>02</span>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="container float">
        <h3>Proven Track Record of Outstanding Website Designs</h3>
        <p class="one-p">
          Our portfolio stands as proof of our expertise. We've had the privilege of collaborating with a diverse range
          of clients, each with unique design requirements. Our proven track record of delivering outstanding website
          designs and driving tangible results underscores our unchanging commitment to excellence in the field of
          website design.
        </p>
      </div>
    </div>
  </div>
  <div class="row content-one mt-0">
    <div class="col-sm-6 center-line">
      <img loading="lazy" src="./assets/img/our-business-22.png" alt="our-business-3">
      <div class="s-no">
        <span>03</span>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="container float mb-3">
        <h3>Affordability and Value in Website Designing</h3>
        <p>
          Exceptional quality in website design doesn't have to come with an expensive price tag. ResilienceSoft stands
          apart by offering competitive pricing without compromising on the quality of our work. We ensure that you
          receive excellent value for your investment in top-notch website design.
        </p>
      </div>
    </div>


    <div class="row content-one mt-0">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="./assets/img/our-business-2.png" alt="our-business-3">
        <div class="s-no">
          <span>03</span>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="container float mb-3">
          <h3>User-Centric Approach to Website Designing</h3>
          <p>
            Website designing with a user-centric approach is at the heart of what we do. We craft designs with your
            target audience in mind, creating responsive and user-friendly interfaces that engage visitors and guide
            them seamlessly through your website. Our website designs are not just visually appealing; they're
            meticulously designed to enhance user interaction and elevate conversion rates.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- -------------------------our-business-mobile Section end----------------- -->