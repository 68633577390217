import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { capatelize } from 'src/app/SEO_Constant/routes.constant';
import { description, keyword } from 'src/app/SEO_Constant/seo';

@Component({
  selector: 'app-digital-media-marketing',
  templateUrl: './digital-media-marketing.component.html',
  styleUrls: ['./digital-media-marketing.component.css'],
})
export class DigitalMediaMarketingComponent implements OnInit {
  titles:any;
  bannerArray:any = {
    "bannerTXT" : [
      {
        title: 'Digital marketing realize your company`s growth potential.',
      },
      {
        title: 'Digital marketing includes email, social media, and web-based advertising etc.',
      },
      {
        title: 'Digital marketing promote products and services on digital channels.',
      },
      {
        title: 'More Visibility, More Leads, More Business.',
      },
    ],
    "bannerImg" : {
      "banner": "assets/img/Digital_Media.png",
      "backgroundImg": "assets/img/banner-background.jpg"
    }
  }
  constructor(private title: Title, private meta: Meta, private router:Router) {
    this.titles = router.url.split('/')[router.url.split('/').length-1].replace(/-/g,' ')
  }

  ngOnInit(): void {
    this.title.setTitle(
      capatelize(this.titles) +' | Resiliencesoft'
    );
    this.meta.removeTag('name=description');
    this.meta.removeTag('name=keywords');
    this.meta.addTags([
      {
        name: 'description',
        content: description.DigitalMediaMarketing,
      },
      {
        name: 'keywords',
        content: capatelize(keyword.globle) + capatelize(keyword.DigitalMediaMarketing),
      },
    ]);
  }
}
