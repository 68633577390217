import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { capatelize } from 'src/app/SEO_Constant/routes.constant';

@Component({
  selector: 'app-web-hosting-solutions',
  templateUrl: './web-hosting-solutions.component.html',
  styleUrls: ['./web-hosting-solutions.component.css']
})
export class WebHostingSolutionsComponent implements OnInit {
  titles:any;
  bannerArray:any = {
    "bannerTXT" : [
      {
        title: 'Web hosting is a service to make your website accessible on the internet.',
      },
      {
        title: ' provides a secure place to store online content.',
      },
      {
        title: 'Get high performance dedicated servers for reasonable price.',
      },
      {
        title: 'Web hosting and tips on choosing the best hosting plan.',
      },
    ],
    "bannerImg" : {
      "banner": "assets/img/web_hosting.png",
      "backgroundImg": "assets/img/banner-background.jpg"
    }
  }

  constructor( private title : Title, private router : Router ) {
    this.titles = router.url.split('/')[router.url.split('/').length-1].replace(/-/g,' ');
   }

  ngOnInit(): void {
    this.title.setTitle(capatelize(this.titles) +' | Resiliencesoft')
  }

}
