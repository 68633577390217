  <!-- ------------------Page Heading Start------------- -->

  <div class="page_heading">
    <div class="container">
      <!-- <h2>Bulk Sms And Email Maketing</h2> -->
      <h2>{{titles}}</h2>
    </div>
  </div>
  <app-banner [BannerData]="bannerArray"></app-banner>
  <!-- ------------------Page Heading End------------- -->

  <!-- ------------------Services web development Start-------------->
  <!-- <section class="why-choos-lg pad-tb deep-dark">
    <div class="container">
      <div class="row">
        <div class="content">
          <div class="common-heading text-l">
            <div class="col row-2">
              <div class="img">
                <div data-tilt="" data-tilt-max="5" data-tilt-speed="1000" class="single-image bg-shape-dez wow fadeIn" data-wow-duration="2s" style="visibility: visible; animation-duration: 2s; animation-name: fadeIn; will-change: transform; transform: perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1);">
                  <figure>
                    <img loading="lazy" src="assets/images/bulk-sms-marketing.png" alt="image" class="img-fluid">
                  </figure>
                </div>


              </div>
            </div>
            <h2 class="mb20">BEST <span class="text-second text-bold">Bulk Sms And Email Maketing Agency</span> Among The Leading Companies in India</h2>
            <p>Easily send powerful bulk SMS campaigns
              Effortlessly personalize your bulk SMS with names or custom fields, compose in 20+ languages, schedule/stagger your sends, add opt-out path and more. Get best-in-class SMS delivery.
              Create bulk SMS campaigns your customers love

              Convey more with SMS attachments
              Easily insert images, videos, PDFs, spreadsheets & more as shortlinks in your bulk SMS campaigns grab your customers’ attention. Convey more in 160 characters.
              Send files via bulk SMS

              Access real-time delivery and click reports
              Measure campaign outreach and effectiveness with real-time SMS delivery reports, and granular click metrics for all weblinks and attachments sent as Textlocal shortlinks.
              Track SMS campaign metrics
              5-minute SMS API integration
              Automate sending and receiving SMS from any website, CRM, or application. Access SMS APIs with sample codes in 10+ languages including PHP, Java, .NET & more.
              Know more about Textlocal SMS APIs

              Receive SMS online
              Let your customers instantly reach you by just texting into your long codes and keywords. Set auto-replies or take the conversation forward on other channels.
              Enable two-way SMS communication

              Manage and create emails with a single, integrated platform
              Create rich, personalized transactional and marketing emails, and send them via API or web portal, to capture your customers’ attention.


              Dashboard
              Easily view counters about the number of email lists, campaigns, subscribers, templates but also recent campaigns details and a recent activity report.
              API keys
              Create API keys to allow external application to connect to your account and run various actions like adding new subscribers, creating campaigns, etc.
              Surveys overview
              We can allow customers to add their own surveys and decide how many they are allowed to add. Then customers can manage them from here.
              Email Marketing Features

              Here the customer area is used by people that create email lists, campaigns, surveys and read reports and statistics.
              Email lists management
              Manage email lists, list subscribers, list segments, custom fields, list forms and list pages.
              Email lists tools
              Need to split a large list into multiple smaller ones? Or sync existing email lists? Use this section for these type of actions.
              Email templates
              Create and categorise as many email templates as you need. Or use one of the ones made available by system administrators.</p>

          </div>
        </div>
      </div>
    </div>
  </section> -->

  <!-- ------------------Services web development End---------------->


  <!-- new code -->

  <section class="our-business">
    <div class="row  our-business-heading mt-0">
      <div class="col-sm-12 pb-3">
        <h2> Bulk SMS & Email Marketing</h2>
        <span> Our Bulk SMS Marketing Solutions
        </span>
      </div>
    </div>
    <div class="row content-one mt-5">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="./assets/img/our-business-3.png" alt="our-business-3">
        <div class="s-no">
          <span>01</span>
        </div>
      </div>
      <div class="col-sm-6 googleRanking">
        <div class="float">
          <h3>Expertise and Experience in Bulk SMS and Email Marketing</h3>
          <p>
            With years of experience, we possess an unparalleled understanding of what works in Bulk SMS and Email Marketing.Our team of experts is dedicated to delivering results in Bulk SMS and Email Marketing that exceed your expectations.We offer a wealth of experience in crafting strategies for Bulk SMS and Email Marketing that drive engagement and conversions.
            
            

          </p>
        </div>
      </div>
    </div>
    <div class="row content-one mt-0">
      <div class="col-sm-6 localseoservice">
        <div class="float-l">
          <h3>Tailored Solutions for Your Business</h3>
          <p class="one-p">
            We understand that every business is unique, and so are your Bulk SMS and Email Marketing needs.Our services are fully customizable to suit your specific requirements and help you achieve your distinct goals in Bulk SMS and Email Marketing.Your success is our priority; our solutions in Bulk SMS and Email Marketing are designed to align seamlessly with your vision
            


          </p>
        </div>
      </div>
      <div class="col-sm-6 center-line-l">
        <img loading="lazy" src="./assets/img/our-business-2.png" alt="our-business-2">
        <div class="s-no-l">
          <span>02</span>
        </div>
      </div>
    </div>
    <div class="row content-one mt-0">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="./assets/img/our-business-22.png" alt="our-business-3">
        <div class="s-no">
          <span>03</span>
        </div>
      </div>
      <div class="col-sm-6 googlePenalty">
        <div class="float">
          <h3> Reliability and Security in Bulk SMS and Email Marketing
          </h3>
          <p>
            We prioritise reliability in delivering your Bulk SMS and Email Marketing messages promptly and consistently.Our robust infrastructure ensures the security of your messages and data in Bulk SMS and Email Marketing.You can trust us to safeguard your sensitive information and deliver your messages on time, every time, in Bulk SMS and Email Marketing.
            



          </p>
        </div>
      </div>
    </div>
    <div class="row content-one mt-0">
      <div class="col-sm-6 localseoservice">
        <div class="float-l">
          <h3>Cost-Effective Services in Bulk SMS and Email Marketing
          </h3>
          <p class="one-p">
            We understand the importance of a budget in Bulk SMS and Email Marketing and offer competitive pricing.Our commitment to cost-effective solutions means you get exceptional quality without breaking the bank in Bulk SMS and Email Marketing.We're dedicated to providing you with a strong return on investment through our services in Bulk SMS and Email Marketing.
            
        </div>
      </div>
      <div class="col-sm-6 center-line-l">
        <img loading="lazy" src="./assets/img/our-business-21.png" alt="our-business-2" class="imgflip" >
        <div class="s-no-l">
          <span>04</span>
        </div>
      </div>
    </div>

  </section>





  <!-- -------------------------our-business-mobile Section start----------------- -->
  <section class="our-business-mobile">
    <div class="row  our-business-heading mt-0">
      <div class="col-sm-12">
        <h2>Bulk SMS & Email Marketing</h2>
        <span>Our Bulk SMS Marketing Solutions</span>
      </div>
    </div>
    <div class="row content-one mt-5">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="./assets/img/our-business-3.png" alt="our-business">
        <div class="s-no">
          <span>01</span>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="container float">

          <h3>Expertise and Experience in Bulk SMS and Email Marketing</h3>
          <p>
            With years of experience, we possess an unparalleled understanding of what works in Bulk SMS and Email Marketing.Our team of experts is dedicated to delivering results in Bulk SMS and Email Marketing that exceed your expectations.We offer a wealth of experience in crafting strategies for Bulk SMS and Email Marketing that drive engagement and conversions.
            
            

          </p>
        </div>
      </div>
    </div>
    <div class="row content-one mt-0">
      <div class="col-sm-6 center-line-l">
        <img loading="lazy" src="./assets/img/our-business-2.png" alt="our-business-2">
        <div class="s-no-l">
          <span>02</span>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="container float">
          <h3>Tailored Solutions for Your Business</h3>
          <p class="one-p">
            We understand that every business is unique, and so are your Bulk SMS and Email Marketing needs.Our services are fully customizable to suit your specific requirements and help you achieve your distinct goals in Bulk SMS and Email Marketing.Your success is our priority; our solutions in Bulk SMS and Email Marketing are designed to align seamlessly with your vision</p>
        </div>
      </div>
    </div>
    <div class="row content-one mt-0">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="./assets/img/our-business-22.png" alt="our-business-3">
        <div class="s-no">
          <span>03</span>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="container float mb-3">
          <h3>Reliability and Security in Bulk SMS and Email Marketing</h3>
          <p>
            We prioritise reliability in delivering your Bulk SMS and Email Marketing messages promptly and consistently.Our robust infrastructure ensures the security of your messages and data in Bulk SMS and Email Marketing.You can trust us to safeguard your sensitive information and deliver your messages on time, every time, in Bulk SMS and Email Marketing.</p>
        </div>
      </div>
    </div>


    <div class="row content-one mt-0">
      <div class="col-sm-6 center-line">
        <img loading="lazy" src="./assets/img/our-business-21.png" alt="our-business-3" class="imgflip" >
        <div class="s-no">
          <span>03</span>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="container float mb-3">
          <h3>Cost-Effective Services in Bulk SMS and Email Marketing</h3>
          <p>  We understand the importance of a budget in Bulk SMS and Email Marketing and offer competitive pricing.Our commitment to cost-effective solutions means you get exceptional quality without breaking the bank in Bulk SMS and Email Marketing.We're dedicated to providing you with a strong return on investment through our services in Bulk SMS and Email Marketing.</p>
        </div>
      </div>
    </div>
  </section>
  <!-- -------------------------our-business-mobile Section end----------------- -->